import React, { useContext } from 'react'
import Back from '../../../components/Back/Back'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import useStyle from '../rigester.style'
import Typographys from '../../../components/Typography/Typography'
import { RigesterContext } from '../../../contexts/RigesterContext'
import GoldButton from '../../../components/Button/GoldButton'
import { SETINPUTVALUE } from '../../../constants/ActionTypes'
import Stepper from '../../../components/Stepper/Stepper'
export default function Gender(props) {
    const {state,dispatch}=useContext(RigesterContext)
    const [gender,setgender]=React.useState({boy:false,girl:false,trans:false})
    const classes=useStyle()
    const Backs=()=>{
        props.history.push({
            pathname: '/rigester/Birthdaydate',
            state: { phone:props.history.location.state.phone,code:props.history.location.state.code,username:props.history.location.state.username,lastname:props.history.location.state.lastname,name:props.history.location.state.name}
          })
    }
    const continues=()=>{
        
        props.history.push({
            pathname: '/rigester/Id',
            state: { phone:props.history.location.state.phone,code:props.history.location.state.code,lastname:props.history.location.state.lastname,name:props.history.location.state.name,username:props.history.location.state.username,birthday:props.history.location.state.birthday,gender:state.Gender}
          })
    }
    const Setgender=(gender)=>{
        switch(gender){
            case "trans":
                setgender({boy:false,girl:false,trans:true})
                dispatch({type:SETINPUTVALUE,payload:{"Gender":"s"}})
                break
            case "boy":
                setgender({boy:true,girl:false,trans:false})
                dispatch({type:SETINPUTVALUE,payload:{"Gender":"m"}})
                break
            case "girl":
                setgender({boy:false,girl:true,trans:false})
                dispatch({type:SETINPUTVALUE,payload:{"Gender":"f"}})
                break
            default:
                setgender({boy:false,girl:false,trans:false})
                break
        }
    }
    React.useEffect(() => {
        if(props.history.location.state===undefined){
            props.history.push('/')
        }
    }, [])
    return (
      <Desktopdiv>
        <Stepper complate={'60'} />

        <div>
          <Back onClick={() => Backs()} color="#FED33C" />
          <Typographys component="h1" text="جنسیت" className={classes.h1} />
        </div>
        <div className={classes.inputBox}>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <GoldButton
              onClick={() => Setgender('boy')}
              variant={gender.boy === false ? 'outlined' : 'contained'}
              className={classes.buttons}
              text="پسر"
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '35px 0px'
            }}
          >
            <GoldButton
              onClick={() => Setgender('girl')}
              variant={gender.girl === false ? 'outlined' : 'contained'}
              className={classes.buttons}
              text="دختر"
            />
          </div>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <GoldButton
              onClick={() => Setgender('trans')}
              variant={gender.trans === false ? 'outlined' : 'contained'}
              className={classes.buttons}
              text="سایر"
            />
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            disabled={state.Gender === '' ? true : false}
            onClick={() => continues()}
            variant={state.Gender === '' ? 'outlined' : 'contained'}
            className={classes.buttons}
            text="ادامه دهید"
          />
        </div>
      </Desktopdiv>
    )
}
