import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {},
  acceptBtn: {
    minWidth: '40px !important',
    padding: '0 5px',
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  rejectBtn: {
    minWidth: '50px !important',
    color: theme.palette.error.main
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    width: '80%',
    maxWidth: 350,
    borderRadius: 10
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px 10px 0 0',
    textAlign: 'justify',
    padding: 25,
    '& span': {
      lineHeight: 2,
      color: '#000 !important'
    }
  },

  btnContainer: {
    display: 'flex',
    width: '100%',
    borderRadius: '0 0 20px 20px'
  },
  btn: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    color: '#ffffe0'
  },
  accept: {
    background: '#1DB954',
    borderRadius: '0 0 0 10px'
  },
  reject: {
    borderRadius: '0 0 10px 0',

    color: '#000'
  }
}))
