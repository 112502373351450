import React from 'react'
import LightButton from '../../components/Button/LightButton'
import GoldButton from '../../components/Button/GoldButton'
import Logo from '../../components/Logo/Logo'
import useStyle from './start.style'
import Desktopdiv from '../../components/DesktopDiv/Desktopdiv'
import { getCookie } from '../../components/CookieManger/CookieManger'
import { Grid, Link, Typography } from '@material-ui/core'
import { AddBox } from '@material-ui/icons'
import Share from '../../assets/images/share.jpg'
export default function Start(props) {
    const [status, setstatus] = React.useState(false)
    const classes = useStyle()
    const rigester = () => {
        props.history.push('/rigester/phone')
    }
    const login = () => {

        // props.history.push('/home')
        props.history.push('/login')
    }
    React.useEffect(()=>{
        const token=getCookie('token')
        if(token){
         props.history.push('/home')
        }
     },[])
    const installios=()=>{
        var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && window["safari"].pushNotification)
        );
      if (isSafari) {
        setstatus(true)
      }
      else{
        alert("لطفا مرورگر سافاری را باز کنید")
      }
    }
    return (
      <div className={classes.root}>
        {status === false ? (
          <Desktopdiv>
            <Logo />
            <div>
              <div className={classes.buttondiv}>
                <LightButton
                  onClick={() => login()}
                  variant="contained"
                  className={classes.button}
                  text="ورود به سیستم"
                />
              </div>
              <div className={classes.buttondiv}>
                <GoldButton
                  onClick={() => rigester()}
                  variant="contained"
                  className={classes.button}
                  text="ثبت نام"
                />
              </div>
            </div>
          </Desktopdiv>
        ) : (
          <Desktopdiv>
            <Logo />
            <div className={classes.inputBox}>
              <div className={classes.divinput}>
                <Typography component="h2">
                  1-لطفا در تب پایین بر روی گزینه{' '}
                  <img src={Share} width="15%" /> کلیک کنید
                </Typography>
              </div>
              <div style={{ marginTop: '30px' }}>
                <Typography component="h2">
                  2-بر روی گزینه <AddBox /> Add to Home Screen کلیک کنید
                </Typography>
              </div>
              <div style={{ marginTop: '30px' }}>
                <Typography component="h2">
                  3-در آخر در بالای صفحه دکمه Add کلیک کنید
                </Typography>
              </div>
              <div style={{ marginTop: '30px' }}>
                <GoldButton
                  onClick={() => {
                    setstatus(false)
                  }}
                  variant="contained"
                  className={classes.button}
                  text="بازگشت به صفحه اصلی"
                />
              </div>
            </div>
          </Desktopdiv>
        )}
      </div>
    )
}
