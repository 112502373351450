import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 85,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    direction: 'rtl',
    boxShadow: '0 0.2rem 0.5rem rgba(182,182,182,0.4)',
    marginBottom: 3
  },
  avatar: {
    height: '75px',
    width: '75px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '4rem',
    position: 'relative'
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 407,
    flexDirection: 'column',
    paddingRight: '10%',
    marginRight: '20px',
    width: 'calc(100vw - 193px)'
  },
  avatarAndText: {
    display: 'flex'
    // paddingLeft: 20,
    // flexGrow: 1
  },
  option: {
    height: '100%',
    width: 106,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: 10
  }
}))
