import React, { useContext, useState } from 'react'
import { Grid, Box, Avatar, Typography } from '@material-ui/core'
import colorBorder from '../../assets/icons/colorBorder.svg'
import blueTikImg from '../../assets/images/blueTik.png'
import Diomond from '../../assets/icons/Diomond.svg'
import SuperLuxury from '../../assets/icons/superluxury.svg'
import Luxury from '../../assets/icons/Luxury.svg'
import useStyles from './avatar.style'
import DraverContext from '../../contexts/DraverContext'
import { SET_OPEN_DRAWER, REQEST_DRAWER } from '../../constants/ActionTypes'

export default function AvatarItem({ userInfo, text = true, blueTik }) {
  let sp = { statusUser: userInfo.statusUser }
  const classes = useStyles(sp)
  const { state, dispatch } = useContext(DraverContext)
  let key = userInfo.statusUser
  let border = ''

  switch (key) {
    case 0:
    case 1:
      border = colorBorder
      break
    case 2:
      border = Luxury
      break
    case 3:
      border = SuperLuxury
      break
    case 4:
      border = Diomond
      break

    default:
      break
  }

  const onClikeHandler = () => {
    !state.open &&
      dispatch({
        type: SET_OPEN_DRAWER,
        payload: {
          drawer: REQEST_DRAWER,
          open: true,
          showBtnRequestFriend: false,
          data: {
            userInfo,
            blueTik,
            friend: userInfo.tel_id || userInfo.insta_id ? true : false
          }
        }
      })
  }
  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="space-between"
      onClick={onClikeHandler}
    >
      <Box className={classes.avatar}>
        {blueTik === 1 ? (
          <Box component="img" src={blueTikImg} className={classes.blueTik} />
        ) : (
          <></>
        )}
        <Box component="img" src={border} className={classes.border} />
        <Avatar
          alt=""
          className={classes.avatarImg}
          src={userInfo.image ? 'https://iloc.myiloc.com' + userInfo.image : ''}
        />
      </Box>
      {text && (
        <Box className={classes.textDiv} clone>
          <>
            <Typography
              style={{direction:'ltr'}}
              className={classes.avatUserName}
              component="p"
              variant="subtitle2"
            >
              {userInfo.username}
            </Typography>
            <Typography component="p" variant="subtitle1">
              {userInfo.km !== undefined && userInfo.km}
            </Typography>
          </>
        </Box>
      )}
    </Grid>
  )
}
