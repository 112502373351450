import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {},
  discriptionContainer: {
    width: ' 100%',
    transition: 'all .4s',
    height: (ps) =>
      ps.selected.superLuxury || ps.selected.luxury || ps.selected.normal
        ? '220px'
        : 0
  },
  perimumPayment: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  itemContainer: {
    transition: 'all .4s',
    height: 290,
    padding: 20,
    paddingTop: 0,
    display: 'flex',
    alignItems: 'flex-end',
    '& > *': { color: theme.palette.secondary.main }
  },
  itemBuy: {
    width: 107,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    height: '100%'
  },
  itemBuyTop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  itemBuyBottom: {
    width: '100%',
    height: 112,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  itemBuyLogo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffe0'
  },
  superLuxuryLogo: {
    height: 35,
    background: theme.palette.superLuxury.logo
  },
  luxuryLogo: {
    height: 35,
    background: theme.palette.luxury.logo
  },
  NormalLogo: {
    height: 35,
    background: theme.palette.normal.logo
    // boxSizing: 'border-box',
    // marginTop: 12,
    // width: '100%',
    // height: 10,
    // borderTop: '3px solid transparent',
    // borderLeft: `90px solid ${theme.palette.primary.main}`,
    // borderBottom: '3px solid transparent'
  },
  select: {
    height: '100%',
    '& *': {
      transition: 'height .4s'
    },
    '&>div:last-child': {
      '& *': {
        color: '#ffffe0'
      },

      borderRadius: (ps) => {
        if (ps.selected.superLuxury) {
          return '0 0 50px 0'
        } else if (ps.selected.luxury) {
          return '0 0 20px 20px'
        } else if (ps.selected.normal) {
          return '0 0 0 50px'
        }
      },

      background: (ps) => {
        if (ps.selected.superLuxury) {
          return theme.palette.superLuxury.logo
        } else if (ps.selected.luxury) {
          return theme.palette.luxury.logo
        } else if (ps.selected.normal) {
          {
            /*this if add on 2nd edition on iloc */
          }
          return theme.palette.normal.logo
        }
      },

      border: (ps) =>
        ps.selected.normal ? `4px solid #FED33C` : `4px solid rgba(26,28,45,1)`,
      borderTop: 'none',
      position: 'relative',
      height: 132,
      '&::after': {
        content: '""',
        position: 'absolute',
        left: -4,
        top: -150,
        width: 0,
        height: '100%',
        borderRight: '2px solid transparent',
        borderBottom: (ps) =>
          ps.selected.normal ? `180px solid #FED33C` : `180px solid #000`,
        borderLeft: '2px solid transparent'
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        right: -4,
        top: -140,
        width: 0,
        height: '100%',
        borderRight: '2px solid transparent',
        borderBottom: (ps) =>
          ps.selected.normal ? `170px solid #FED33C` : `170px solid #000`,
        borderLeft: '2px solid transparent'
      },
      '&>div:first-child': {
        background: 'none'
      }
    }
  },
  cost: { fontSize: 36 },
  cover: {
    width: '100%',
    height: '100%',
    zIndex: 3,
    position: 'absolute'
  },
  empty: {
    width: 10,
    height: 30
  },
  goldLineBox: {
    width: '100%',
    marginBottom: 10
  },
  goldLine: {
    width: '100%'
  }
}))
