import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  rootBtn: {
    display: 'flex',
    width: 'inherit',
    height:'inherit',
    borderRadius: '100%'
  },
  btn: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    color: '#ffffe0',
    // borderBottomLeftRadius:'20px'
  },
  ml: {
    marginRight: 5
  },
  telegram: {
    background: '#4EA4F6'
  },
  instagram: {
    background:
      'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); '
  },
}));