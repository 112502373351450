import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    [theme.breakpoints.up('sm')]: {
      '&> div': {
        left: 'calc(50vw - 300px)'
      }
    },

    '& *': {
      maxWidth: 600
    }
  },
  paper: {
    borderRadius: '24px 24px 0 0',
    paddingTop: 20,
    background: theme.palette.background.main
  },
  radioItemRoot: {
    color: theme.palette.background.lightDark
  },
  radioWraper: {
    padding: '0 30px 20px'
  }
}))
