import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {},
  checkBoxRoot: {
    color: `${theme.palette.text.primary} !important`
  },
  checkList: {
    marginTop: 10,
    marginBottom:10,
    paddingLeft:10,
    '&>label>span': {
      padding: 3
    }
  }
}))
