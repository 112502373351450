import React, {useContext, useRef, useState,useEffect} from 'react'
import uniqid from 'uniqid'
import { Grid, Box } from '@material-ui/core'
import NavLink from '../../components/NavLink/NavLink'
import Menu from '../../components/Menu/Menu'
import Cart from '../../components/Cart/Cart'
import FilterBox from '../../components/Filter/FilterBox/FilterBox'
import useStyles from './MessageContainer.style'
import { MsgScreenContext } from '../../contexts/MsgScreenContext'
import FilterModal from '../../components/Filter/FilterModal/FilterModal'
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll'
import {
  SET_SEND_MSGLIST,
  SET_RECEIVED_MSGLIST
} from '../../constants/ActionTypes'
import { received, showsendBox } from '../../helpers/fetch'

import UnReadRequest from '../../contexts/UnReadRequest'
import {SocketContext} from "../../contexts/Socket";

export default function MessageContainer() {
  const socket = useRef(useContext(SocketContext))
  const { state, dispatch } = useContext(MsgScreenContext)
  const { unReadRequest, setUnReadRequest } = useContext(UnReadRequest)
  const classes = useStyles(state)
  const [nextPage, setNextPage] = useState(1)
  const [loading, setloading] = useState(false)
  useEffect(()=>{
    if (socket.current.connected){
      socket.current.disconnect();
    }
  })
  const fetchMoreData = () => {
    setNextPage(nextPage + 1)
    if (state.boxName === 'SEND') {
      showsendBox(nextPage + 1, state.filterList).then((response) => {
        if (response.data.length === 0) {
          setloading(false)
        } else {
          dispatch({
            type: SET_SEND_MSGLIST,
            payload: state.sendMsgList.concat(response.data)
          })
        }
      })
    } else {
      received(nextPage + 1, state.sortList).then((response) => {
        if (response.data.length === 0) {
          setloading(false)
        } else {
          dispatch({
            type: SET_RECEIVED_MSGLIST,
            payload: state.receivedMsgList.concat(response.data)
          })
        }
      })
    }
  }

  //if there are no request in waiting mode notification circle, disappear.
  const res = state.receivedMsgList.reduce((isReaded, item) => {
    isReaded = item.read && true
    return Boolean(isReaded)
  }, false)

  if (unReadRequest && res) {
    setUnReadRequest(false)
  }

  return (
    <Grid container item className={classes.root}>
      <NavLink />
      <FilterBox />

      <InfiniteScroll
        dataLength={
          state.boxName === 'SEND'
            ? state.sendMsgList.length
            : state.receivedMsgList.length
        }
        next={fetchMoreData}
        height="calc(100vh - 100px)"
        loading={loading}
      >
        {state.boxName === 'SEND'
          ? state.sendMsgList.map((item) => {
              return <Cart key={uniqid()} type={state.boxName} info={item} />
            })
          : state.receivedMsgList.map((item) => {
              return <Cart key={uniqid()} type={state.boxName} info={item} />
            })}
      </InfiniteScroll>

      <Menu activeIcon="message" />
      <FilterModal />
    </Grid>
  )
}
