import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 50
  },
  itemBox: {
    width: '100%'
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 50
  },
  icon: {
    color:"gray",
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
    marginRight: 10,
  },
  inputFile: {
    display: 'none'
  },
  cover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1
  }
}))

