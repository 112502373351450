import React, { createContext, useReducer } from 'react'
import { SET_OPEN_MODAL } from '../constants/ActionTypes'

//**********  createContext  ************
const ModalContext = createContext({
  state: {},
  dispatch: () => {}
})
export default ModalContext

//**********  ContextProvider  ************
function ModalContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    data: {}
  })

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  )
}
export { ModalContextProvider }

//**********  reducer  ************
function reducer(state, action) {
  switch (action.type) {
    case SET_OPEN_MODAL:
      return Object.assign({}, state, { ...action.payload })

    default:
      return Object.assign({}, state)
  }
}
