import { makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'


export default makeStyles((theme) => ({
  root: {
    height: '100vh'
  },

}))
