import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

export default function Tiltle({ height, title, onClick }) {
 
  return (
    <Box height={height ? height : '45px'}>
      <Typography variant="caption">
        {title}
      </Typography>
    </Box>
  )
}
