import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    marginTop: 25,
    height: '45px',
    borderBottom: `2px solid ${theme.palette.background.lightDark}`
  },
  iconBox: {
    width: '45px',
    height: '45px',
    display: 'flex',
    marginLeft: 10,
    paddingRight: 10
  },
  inpotBox: {
    direction: 'rtl',
    display: 'flex',
    flex: 1,
    height: '100%',
    border: 'none',
    color: theme.palette.background.lightDark,
    backgroundColor: 'transparent',
    '&:focus': {
      outline: 'none'
    }
  },
 
}))
