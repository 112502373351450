import {
  SET_SEND_MSGLIST,
  SET_RECEIVED_MSGLIST,
  SET_BOXNAME,
  SET_OPENMODAL,
  SET_FILTERSTATUS,
  SET_FILTERLIST,
  SET_SORTLIST,
  SET_TRIGGER,
  SET_NEXT_PAGE
} from '../constants/ActionTypes'

function reducer(state, action) {
  switch (action.type) {
    case SET_SEND_MSGLIST:
      return Object.assign({}, state, { sendMsgList: action.payload })
    case SET_RECEIVED_MSGLIST:
      return Object.assign({}, state, { receivedMsgList: action.payload })
    case SET_BOXNAME:
      return Object.assign({}, state, { boxName: action.payload })
    case SET_OPENMODAL:
      return Object.assign({}, state, { openModal: action.payload })
    case SET_FILTERSTATUS:
      return Object.assign({}, state, { filterStatus: action.payload })
    case SET_FILTERLIST:
      return Object.assign({}, state, { filterList: action.payload })
    case SET_SORTLIST:
      return Object.assign({}, state, { sortList: action.payload })
    case SET_TRIGGER:
      return Object.assign({}, state, { trigger: action.payload })

    default:
      return Object.assign({}, state)
  }
}
export default reducer
