import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import Username from './Username'

export default function userindex(props) {
    return (
       <RigesterContextProvider>
           <Username history={props.history}/>
       </RigesterContextProvider>
    )
}
