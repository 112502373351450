import React, { useState, useEffect, useContext } from 'react'
import { Grid, Modal, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import uniqid from 'uniqid'
import useStyles from './FavoriteListContainer.style'
import CartWithMenu from '../../components/CartWithMenu/CartWithMenu'
import ModalContext from '../../contexts/ModalContext'
import { SET_OPEN_MODAL } from '../../constants/ActionTypes'
import { favoritelist, unFavoritelist } from '../../helpers/fetch'
import MyInfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll'

export default function FavoriteListContainer(props) {
  const classes = useStyles()
  const history = useHistory()
  const { state, dispatch } = useContext(ModalContext)
  const [list, setList] = useState([])
  const [links, setlinks] = useState({})
  const [loading, setloading] = useState(false)
  const [page, setpage] = useState(1)
  useEffect(() => {
    favoritelist(page).then((response) => {
      setList(response.data)
      setlinks(response.links)
      setloading(true)
    })
  }, [])

  const closeModalHandler = () => {
    dispatch({ type: SET_OPEN_MODAL, payload: { open: false } })
  }
  const unFavoriteHandler = () => {
    unFavoritelist(state.data.userInfo.id)
    dispatch({ type: SET_OPEN_MODAL, payload: { open: false } })
  }
  const back = () => {
    history.push('/profile')
  }
  const fetchMoreData = () => {
    if (links.next === null) {
      setList(list.concat([]))
      setloading(true)
    } else {
      setpage(page + 1)
      favoritelist(page + 1).then((response) => {
        if (response.data.length === 0) {
          setList(list.concat([]))
        } else {
          setList(list.concat(response.data))
        }
      })
    }
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <Typography variant="subtitle1">
          در اینجا می‌توانید کاربران مورد علاقه‌ خود را مشاهده کنید.
        </Typography>
      </Grid>
      <Grid className={classes.content}>
        <MyInfiniteScroll
          dataLength={list.length}
          next={fetchMoreData}
          height={
            state.boxName === 'SEND'
              ? 'calc(100vh - 100px)'
              : 'calc(100vh - 50px)'
          }
          loading={loading}
        >
          {list.map((item) => {
            return <CartWithMenu key={uniqid()} info={item} brderBottom />
          })}
        </MyInfiniteScroll>
      </Grid>
      <Grid className={classes.btnBox}>
        <Button
          variant="contained"
          color="primary"
          onClick={back}
          className={classes.btnBack}
        >
          بازگشت
        </Button>
      </Grid>
      <Modal
        className={classes.modal}
        open={state.open}
        onClose={closeModalHandler}
      >
        <Grid className={classes.modalGrid} onClick={unFavoriteHandler}>
          <Typography variant="subtitle1" className={classes.modalText}>
            حذف
          </Typography>
        </Grid>
      </Modal>
    </Grid>
  )
}
