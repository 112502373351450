import React from 'react'
import Logo from '../../components/Logo/Logo'
import Input from '../../components/Input/Input'
import useStyle from './login.style'
import Desktopdiv from '../../components/DesktopDiv/Desktopdiv'
import GoldButton from '../../components/Button/GoldButton'
import { Grid, Link, Typography } from '@material-ui/core'
import { getCookie } from '../../components/CookieManger/CookieManger'
import { login } from '../../helpers/fetch'
export default function Login(props) {
  const classes = useStyle()
  const [state, setState] = React.useState({ username: '', password: '' })
  const onChangeUser = (e) => {
    setState({ username: e.target.value, password: state.password })
  }
  const onChangePass = (e) => {
    setState({ username: state.username, password: e.target.value })
  }
  const Logins = () => {
    login(state.username, state.password).then((response) => {
      if ( response !== undefined ) {
        localStorage.setItem('darkMode', '0')
        localStorage.setItem('reload', true)
        window.location.reload()
        props.history.push('/home')
      }
    })
  }
  React.useEffect(() => {
    const token = getCookie('token')
    if (token) {
      props.history.push('/home')
    }
  }, [])
  return (
    <Desktopdiv>
      <Logo />
      <div >
        <div className={classes.Input}>
          <Input
            className={classes.InputText}
            onChange={(e) => onChangeUser(e)}
            label="نام کاربری یا شماره تلفن"
          />
        </div>
        <div className={classes.Input}>
          <Input
            className={classes.InputText}
            type="password"
            onChange={(e) => onChangePass(e)}
            label="رمز"
          />
        </div>
        <div className={classes.ButtonDiv}>
          <GoldButton
            onClick={() => Logins()}
            variant="contained"
            className={classes.buttons}
            text="ورود"
          />
        </div>
        <Grid container style={{ textAlign: 'center' }}>
          <Grid item xs >
            <Link color="inherit" href="/reset/phone" variant="body2">
              <Typography variant="subtitle1">
                آیا رمز خود را فراموش کرده اید؟
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center">
            <Link color="inherit" href="/rigester/phone" variant="body2">
              <Typography variant="subtitle1">
                اگر حساب ندارید ثبت نام کنید
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Desktopdiv>
  )
}
