
import * as React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './CharCounterChat.style'


export const CharCounterChat = (props) => {

  const classes = useStyles();

  return (
    <Grid container   className={classes.Char_counter} alignItems={"center"} >
      <Grid item >
        <Typography variant={'body1'} className={classes.charTxt}>
          تعداد کاراکتر های باقی مانده:
        </Typography>
      </Grid>
      <Grid item >
        <Typography variant={'body1'} className={classes.charNum}>
          {props.totalChar}
        </Typography>
      </Grid>

    </Grid>
  )
}