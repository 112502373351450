import React, { useContext } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import Back from '../../../components/Back/Back'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../../components/Typography/Typography'
import { RigesterContext } from '../../../contexts/RigesterContext'
import useStyle from '../rigester.style'
import Input from '../../../components/Input/Input'
import GoldButton from '../../../components/Button/GoldButton'
import { SETINPUTVALUE } from '../../../constants/ActionTypes'
import Stepper from '../../../components/Stepper/Stepper'
import { UsernameFind } from '../../../helpers/fetch'

export default function Username(props) {
    const { state, dispatch } = useContext(RigesterContext)
    const classes = useStyle()
    const Backs = () => {
        props.history.push({
            pathname: '/rigester/Name',
            state: { phone: props.history.location.state.phone,code:props.history.location.state.code,lastname:props.history.location.state.lastname,name:props.history.location.state.name }
        })
    }
    const onChangeText = (e) => {
        
        dispatch({ type: SETINPUTVALUE, payload: { [e.target.name]: e.target.value } })

    }
    const continues = () => {
        if(state.username.length>=5){
            var english = /^[A-Za-z0-9._]*$/;
            if ( english.test( state.username ) ) {
                UsernameFind(state.username).then(response=>{
                    if(response.status===true){
                        NotificationManager.error('کاربری با این نام کاربری وجود دارد')
                    }else{
                        props.history.push({
                            pathname: '/rigester/Birthdaydate',
                            state: { phone: props.history.location.state.phone, code: props.history.location.state.code,lastname:props.history.location.state.lastname,name:props.history.location.state.name, username: state.username }
                        })
                    }
                })
        
    }else{
        NotificationManager.error('تمام حروف باید انگیلیسی باشد')
    }
}
    else{
        NotificationManager.error('نام کاربری باید ۵ کاراکتر باشد')
    }
    }
    React.useEffect(() => {
        if (props.history.location.state === undefined) {
            props.history.push('/')
        }
    }, [])
    return (
      <Desktopdiv>
        <Stepper complate={'40'} />
        <div>
          <Back onClick={() => Backs()} color="#FED33C" />
          <Typographys
            component="h1"
            text="نام خود را وارد کنید"
            className={classes.h1}
          />
        </div>
        <div className={classes.inputBox}>
          <div className={classes.divinput}>
            <Input
              className={classes.input}
              name="username"
              label="نام کاربری"
              onChange={(e) => onChangeText(e)}
            />
          </div>
          <div style={{ marginTop: '35px' }}>
            <div className={classes.fonts}>
              <Typographys variant="subtitle1" text="فقط حروف لاتین باشد" />
              <Typographys
                variant="subtitle1"
                style={{ margin: '10px 0px' }}
                text="حداقل ۵ کاراکتر را وارد کنید"
              />
              <Typographys
                variant="subtitle1"
                text="در استفاده از علایم اندرلاین و نقطه مجاز است"
              />
            </div>
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            onClick={() => continues()}
            disabled={state.username === '' ? true : false}
            variant={state.username === '' ? 'outlined' : 'contained'}
            className={classes.buttons}
            text="ادامه دهید"
          />
        </div>
      </Desktopdiv>
    )
}
