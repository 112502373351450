import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import palette from '../../../theme/palette'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    borderBottom: `1px solid #B0B0B0`,
    padding: '15px 0'
  },
  icon: {
    position: 'absolute',
    right: 0,
    color: theme.palette.primary.main
  }
}))

export default function MsgTempelate({ text, read }) {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {!read && <NotificationsActiveIcon className={classes.icon} />}
      <Box color={!read ? palette.primary.main : palette.text.message} clone>
        <Typography variant="subtitle1">{text}</Typography>
      </Box>
    </Box>
  )
}
