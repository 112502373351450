import { makeStyles } from '@material-ui/core'
const useStyle = makeStyles((theme) => ({
  container: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.main
  },
  menuIcon: {
    position: 'absolute',
    color: '#B0B0B0',
    right: 10,
    fontSize: 40
  },
  starsIcon: {
    color: (ps) =>
      ps.favourite
        ? theme.palette.primary.main
        : theme.palette.background.lightDark
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0'
  },

  rowBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px '
  },

  nameText: {
    fontSize: '22px'
  },

  statusText: {
    padding: '10px',
    fontSize: '20px',
    fontWeight: 'bold'
  },

  distanceText: {
    fontSize: '20px',
    direction: 'initial'
  },

  distanceTextBox: {
    display: 'flex',
    alignItems: 'baseline'
  },

  blueTik: {
    width: 20,
    marginLeft: 10
  },
  noticText:{
    padding:'0 30px',
    textAlign:'center'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalGrid: {
    width: '60%',
    maxWidth: 330,
    outline: 'none'
  },
  menuItem: {
    background: '#fff',
    color: 'black',
    padding: 15,
    width: '100%',
    textAlign: 'center',
    borderBottom: `2px solid #B0B0B0`
  }
}))
export default useStyle
