import React from 'react'
import IlocIcon from '../../assets/images/Group 572.png'
import Typographys from '../Typography/Typography'
import useStyle from './logo.style'
export default function Logo(props) {
  const classes = useStyle()
  return (
    <div>
      <div className={classes.root}>
        <img src={IlocIcon} className={classes.img} />
      </div>
      {!props.status && (
        <div style={{ textAlign: 'center', marginTop: '8px' }}>
          <Typographys component="h5" className={classes.h5} text="iLoc " />
          <Typographys
            component="h5"
            className={classes.h5}
            text="Closer, To Each Other"
          />
        </div>
      )}
    </div>
  )
}
