import * as React from 'react'
import {
  Grid,
  IconButton,
  TextareaAutosize,
  Typography
} from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import useStyles from './PvChat.style'
import SendIcon from '@material-ui/icons/Send'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import { useState, useEffect, useRef, useContext } from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import classNames from 'classnames'
import { HeaderChat } from '../../components/Chat/HeaderChat/HeaderChat'
import { StoreAdButton } from '../../components/Chat/FooterChat/Store&AdButton/Store&AdButton'
import { SocialBtnsChat } from '../../components/Chat/FooterChat/SocialBtnsChat/SocialBtnsChat'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import DoneIcon from '@material-ui/icons/Done'
import { getChat, tel_insta_id } from '../../helpers/fetch'
import { FirstBoxText } from '../../components/Chat/FirstBoxText/FirstBoxText'
import id from 'emoji-mart/dist/components/search'
import { DateChat } from '../../components/Chat/Date/DateChat'
import { NewModal } from '../../components/Chat/Modal/NewModal'
import { SocketContext } from '../../contexts/Socket'
import moment from 'moment'
import $ from 'jquery'
import { compareArraysAsSet } from '@testing-library/jest-dom/dist/utils'
import * as rdd from 'react-device-detect';

export const PvChat = () => {
  //new
  const classes = useStyles()
  const ref = useRef(null)
  const scrollable = useRef()
  const scrollInput = useRef()
  const location = useLocation()
  const [box, setBox] = useState(false)
  const [textBox, setTextBox] = useState()
  const [userInfo, setUserInfo] = useState({})
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [telInsta, setTelInsta] = useState({})
  const socket = useRef(useContext(SocketContext))
  const [showEmoji, setShowEmoji] = useState(false)
  const [themeEmoji, setThemeEmoji] = useState()
  const [numOfChar, setNumOfChar] = useState(0)
  const [totalChar, setTotalChar] = useState()
  const [showInput, setShowInput] = useState(true)
  const [showButtonStore, setShowButtonStore] = useState(false)
  const [showSocialBtns, setShowSocialBtns] = useState(false)
  const [open, setOpen] = useState(false)
  const [modalText, setModalText] = useState()
  const [isOnline, setIsOnline] = useState()
  //get id username status
  const darkMode = localStorage.getItem('darkMode')
  const status_user = localStorage.getItem('status_user')
  const id_sender = parseInt(localStorage.getItem('id'))
  const id_reciever = userInfo.id
  window.$ = window.jQuery = require('jquery');
  $.fn.mobileFix = function (options) {
    $(document).on('focus', options.inputElements, function(e) {
      setTimeout(function() {
        let header = $("#header-chat");
        // let scrollChat=$("#scrollChat");
        header.css({"position": 'absolute','top': $(window).scrollTop() + 'px'});
        // scrollChat.css({"margin-top":($(window).scrollTop() + 79) + 'px'});
        document.ontouchmove = function(event){
          event.preventDefault();
        }
      }, 500);
      _scroller = true
    }).on('blur', options.inputElements, function(e) {
      let header = $("#header-chat");
      // let scrollChat=$("#scrollChat");
      _scroller = false
      header.removeAttr('style');
      // scrollChat.removeAttr('style');
      document.ontouchmove = function(event){
      }
    });

    return this;
  };

  if (rdd.isMobileSafari) {
    $(document).ready(function(){
      window.scrollTo(0,document.body.scrollHeight);
    });
    var _scroller = false
    $("body").mobileFix({
      inputElements: "input,textarea,select",
    });
    $(window).scroll(function (event) {
      // let $header = $("#header-chat");
      // $("#text").val('wt' + $(window).scrollTop() + 'sh:' + document.body.scrollHeight + ' ofh:' + document.body.offsetHeight + ' ch:' + document.body.clientHeight + 'ht:' + $header.position().top )

      if (_scroller) {
        // $header.css({"position": 'absolute','top': $(window).scrollTop() + 'px'});
        // setTimeout(function() {
        //   document.ontouchmove = function(event){
        //     event.preventDefault();
        // }
        // }, 100);
        document.ontouchmove = function(event){
          event.preventDefault();
        }
      } else {
//             $header.css('top',0 );
      }
    });
  }

  if (rdd.isAndroid){
    let vh = window.innerHeight * 0.01;
    document.getElementById('root').style.setProperty('--vh', `${vh}px`);
    const r=()=> {
      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.getElementById('root').style.setProperty('--vh', `${vh}px`);
      });
    }
    r();
    $("#root").css({"height": 'calc(var(--vh, 1vh) * 100)'});
    $("#main").css({"height": 'calc((var(--vh, 1vh) * 100) - 78px)'});
    $("#scrollChat").css({"max-height": 'calc((var(--vh, 1vh) * 100)- 155px)'});

  }


  useEffect(() => {
    if (isOnline !== false) {
      const newArray = messages.map((obj) => ({
        ...obj,
        seen: 1
      }))
      setMessages(newArray)
    }
  }, [isOnline])

  // const online=useRef(socket.current.emit('is-online'))
  //socket message
  useEffect(() => {
    setUserInfo(location.state.userInfo)
    socket.current.connect()
    const join = socket.current.emit('join', {
      id_sender: id_sender,
      id_reciever: location.state.userInfo.id
    })
    //route getChat backend
    const chat = getChat(location.state.userInfo.id).then((response) => {
      if (response.chat) {
        if (response.chat.length === 0) {
          if (response.chats) {
            setBox(true)
            setTextBox(response.chats)
          }
          setMessages([])
        } else {
          const newarray = response.chat.map((obj) => ({
            ...obj,
            date: changeDate(obj.created_at).format('LL'),
            created_at:changeDate(obj.created_at),
             // moment(obj.created_at.replace(/-/g, '/')).format('LL')
          }))
          setMessages(newarray)
          if (response.chats) {
            setModalText(response.chats)
            openModal()
          }
          scroll()
        }
      } else {
        if (response.chats) {
          setModalText(response.chats)
          openModal()
        }
      }
      statusUser(status_user, response.numOfChar)

      if (response.acceptRequest === true) {
        setShowInput(false)
        setShowButtonStore(false)
        setShowSocialBtns(true)
      }
      if (response.closeChat === true && response.acceptRequest === false) {
        setShowInput(false)
        setShowButtonStore(true)
      }
      if (response.seeAds === true && response.acceptRequest === false) {
        setShowInput(false)
        setShowButtonStore(true)
      }
    })

    //emojitheme
    if ((status_user === '0' || status_user === '1') && darkMode === '0') {
      setThemeEmoji('light')
    } else {
      setThemeEmoji('dark')
    }

    //get message from backend
    // socket.on('is-online', (data) => {
    //     console.log(data.isOnline);
    // });

    socket.current.on('is-online', (data) => {
      if (data.isOnline === 'online') {
        const rand = Math.floor(Math.random() * 100)
        setIsOnline(rand)
      } else {
        setIsOnline(false)
      }
    })

    socket.current.on('accept-request', (data) => {
      setShowInput(false)
      setShowSocialBtns(true)
      setModalText('امکان چت برای شما بسته شد')
      openModal()
      if (data.countChar) {
        statusUser(status_user, data.countChar)
      }
    })
    socket.current.on('close-chat', (data) => {
      data.date = moment(data.created_at.replace(/-/g, '/')).format('LL')
      setMessages((messages) => messages.concat(data))
      if (data.id_sender === id_sender) {
        setShowInput(false)
        if (data.seeAds === true) {
          setShowButtonStore(true)
        }
        setTotalChar(0)
        setModalText('تعداد کاراکتر های شما به پایان رسید')
        openModal()
      }
    })
    socket.current.on('Unauthorized number of characters', (data) => {
      if (data.id_sender === id_sender) {
        if (data.countChar) {
          statusUser(status_user, data.countChar)
        }
        setModalText('تعداد کاراکتر های پیام شما بیش ازحد مجاز است')
        openModal()
      }
    })
    socket.current.on('new-message-event', (message) => {
      setBox(false)
      message.date = moment(message.created_at.replace(/-/g, '/')).format('LL')
      setMessages((messages) => messages.concat(message))
      if (message.id_sender === id_sender) {
        if (message.countChar) {
          statusUser(status_user, message.countChar)
        }
      }
      scroll()
    })

    //insta_id telegram_id
    tel_insta_id(location.state.userInfo.id).then((response) => {
      setTelInsta(response)
    })
    const interval = setInterval(() => {
      const online = socket.current.emit('is-online')
    }, 3000)
    return () => clearInterval(interval)
  }, [location])

  //status user
  const statusUser = (status, numOfChar) => {
    switch (status) {
      case '0':
        setTotalChar(500 - numOfChar)
        break
      case '1':
        setTotalChar(1000 - numOfChar)
        break
      case '2':
        setTotalChar(5000 - numOfChar)
        break
      default:
        setTotalChar('∞')
        break
    }
  }
  const changeDate=(date)=>{
    const s =date.split(' ');
    const n=(s[1])+' '+s[0];
    const newDate=moment(n.replace(/-/g, '/'));
    // const newDate = new Date(n).toLocaleString('en-us', {month: 'long'}) + ' ' +
    //     new Date(n).getDate() + ',' + new Date(n).getFullYear();
     return newDate;
  }

  //scroll
  const scroll = () => {
    if (scrollable.current !== null) {
      scrollable.current.scroll(0, scrollable.current.scrollHeight)
    }
  }

  //send message to backend
  const sendMessage = () => {
    if (!newMessage) return
    setBox(false)
    socket.current.emit('new-message-event', {
      id: '',
      message: newMessage,
      id_sender: id_sender,
      id_reciever: id_reciever,
      status: parseInt(status_user)
    })
    setBox(false)
    setNumOfChar(0)
    setNewMessage('')
    ref.current.focus()
  }

  //send message by enter
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  //get message from input
  const getMessage = (event) => {
    const txt = event.target.value
    setNewMessage(txt)
    const numTxt = parseInt(txt.length)
    const newNum = numTxt - numOfChar
    setNumOfChar(numTxt)
    if (totalChar !== '∞') {
      if (totalChar - newNum === 200) {
        setModalText('200 کاراکتر شما باقی مانده است')
        openModal()
      }
      setTotalChar(totalChar - newNum)
    }
  }

  const onEmojiClick = (emoji) => {
    const { selectionStart, selectionEnd } = ref.current
    const txt =
        newMessage.slice(selectionEnd) +
        newMessage.slice(0, selectionStart) +
        emoji.native
    setNewMessage(txt)
    const numTxt = parseInt(txt.length)
    const newNum = numTxt - numOfChar
    setNumOfChar(numTxt)
    if (totalChar !== '∞') {
      if (totalChar - newNum === 200) {
        setModalText('200 کاراکتر شما باقی مانده است')
        openModal()
      }
      setTotalChar(totalChar - newNum)
    }
  }

  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  return (
      <Grid
          container
          direction="column"
          id="root"
          className={classes.root}
          spacing={1}
      >
        <div className={classes.pos}>
          {/*header*/}
          <HeaderChat userInfo={userInfo} socket={socket} totalChar={totalChar} />

          {/*chat*/}
          <Grid
              container
              direction="column"
              className={classes.main}
              style={{ position: 'relative' }}
              id='main'
          >
            {box && <FirstBoxText text={textBox} />}
            <div className={classes.scrollChat} id='scrollChat' ref={scrollable}>
              {messages.map((item, index, array) => {
                const time = moment(item.created_at).format('H:mm');
                return (
                    <React.Fragment key={item.id}>
                      {index === 0 && <DateChat date={item.date} />}
                      {array[index - 1] && array[index - 1].date !== item.date && (
                          <DateChat date={item.date} />
                      )}
                      <Grid
                          item
                          container
                          className={classNames(
                              parseInt(item.id_sender) !== parseInt(id_sender)
                                  ? classes.yourMessage
                                  : classes.mineMessage
                          )}
                      >
                        <Grid
                            item
                            className={classNames(
                                classes.bubbleChat,
                                parseInt(item.id_sender) !== parseInt(id_sender)
                                    ? classes.yourBubbleChat
                                    : classes.mineBubbleChat
                            )}
                        >
                          <Typography className={classes.MessageText}>
                            {item.message}
                          </Typography>

                          <Grid
                              container
                              alignItems={'center'}
                              justify={'flex-start'}
                              className={classes.timeContainer}
                          >
                            <Grid item>
                              {item.id_sender === id_sender && item.seen === 0 && (
                                  <DoneIcon className={classes.mineDoneIcon} />
                              )}
                              {item.id_sender === id_sender && item.seen === 1 && (
                                  <DoneAllIcon className={classes.mineDoneIcon} />
                              )}
                            </Grid>
                            <Grid item>
                              <Typography
                                  className={`${
                                      item.id_sender !== id_sender
                                          ? classes.yourTime
                                          : classes.mineTime
                                  }`}
                              >
                                {time}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                )
              })}
              {showEmoji && (
                  <div style={{ position: 'absolute', bottom: 0 }}>
                    <Picker
                        style={{ width: '100%', height: '10%' }}
                        onClick={onEmojiClick}
                        theme={themeEmoji}
                        emojiSize={24}
                        color={'#109194'}
                        emoji={'Slightly_smiling_face'}
                    />
                  </div>
              )}
            </div>
            {showInput && (
                <Grid
                    container
                    className={classes.footer}
                    justify={'center'}
                    alignItems={'center'}
                    ref={scrollInput}
                >
                  <Grid item xs style={{ flexGrow: 1 }}>
                    <IconButton style={{ height: 30 }} onClick={sendMessage}>
                      <SendIcon className={classes.SendIcon} />
                    </IconButton>
                  </Grid>
                  <Grid item xs style={{ flexGrow: 8 }}>
                    <TextareaAutosize
                        id="text"
                        name="input"
                        value={newMessage}
                        placeholder={'پیام'}
                        className={classes.inputChat}
                        ref={ref}
                        onKeyDown={handleKeyDown}
                        onChange={getMessage}
                    />
                  </Grid>

                  <Grid
                      item
                      container
                      xs
                      style={{ flexGrow: 1 }}
                      justify={'center'}
                      alignItems={'center'}
                  >
                    <button
                        className={classes.emojiBtn}
                        onClick={() => {
                          setBox(false)
                          setShowEmoji(!showEmoji)
                        }}
                    >
                      <EmojiEmotionsIcon className={classes.EmojiEmotionsIcon} />
                    </button>
                  </Grid>
                </Grid>
            )}
            {!showInput && (
                <Grid
                    container
                    className={classes.footer}
                    justify={'center'}
                    alignItems={'center'}
                >
                  {/*socialBtns*/}
                  {showSocialBtns && <SocialBtnsChat telInsta={telInsta} />}
                  {/*add&store*/}
                  {showButtonStore && (
                      <StoreAdButton
                          socket={socket}
                          id={id_reciever}
                          totalChar={totalChar}
                      />
                  )}
                </Grid>
            )}
          </Grid>
          <NewModal
              open={open}
              closeModalAds={closeModal}
              text={modalText}
              closeModal={closeModal}
              btnText={'بستن'}
          />
        </div>
      </Grid>
  )
}
