import React, { useContext, useState } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  TextField,
  Modal
} from '@material-ui/core'
import BtnModal from '../../BtnModal/BtnModal'
import { SET_OPEN_DRAWER } from '../../../constants/ActionTypes'
import DraverContext from '../../../contexts/DraverContext'
import { requestfriend } from '../../../helpers/fetch'
import NotificationManager from 'react-notifications/lib/NotificationManager'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column ',
    alignItems: 'center'
  },
  text: {
    paddingTop: '25px',
    textAlign: 'center'
  },
  textField: {
    margin: 20,
    width: 300,
    '& .MuiOutlinedInput-root': {
      borderRadius: 15
    }
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    width: '80%',
    maxWidth: 350,
    borderRadius: 10
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px 10px 0 0',
    textAlign: 'justify',
    padding: 25,
    '& span': {
      lineHeight: 2,
      color: '#000 !important'
    }
  },

  btnContainer: {
    display: 'flex',
    width: '100%',
    borderRadius: '0 0 20px 20px'
  },
  btn: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    color: '#ffffe0',
    background: theme.palette.primary.main,
    borderRadius: '0 0 10px 10px'
  }
}))

export default function MessageDrawer() {
  const c = useStyles()
  const { state, dispatch } = useContext(DraverContext)
  const userInfo = state.data
  const [txt, settxt] = useState('میتونم پیج اینستاگرام یا تلگرامتونو ببینم؟')
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { open: false }
    })
    setOpen(false)
  }

  const Send = () => {
    requestfriend(txt, userInfo.id).then((response) => {
      if (response) {
        NotificationManager.success(response.data)
        openModal()
      }
    })
  }
  return (
    <Box className={c.root}>
      <Box className={c.text}>
        <Typography variant="h5">
          متن درخواست دوستی به {userInfo.username} را وارد کنید :
        </Typography>
      </Box>

      <TextField
        classes={{ root: c.textField }}
        variant="outlined"
        value={txt}
        placeholder="متن خود را بنویسید"
        inputProps={{ maxLength: 100 }}
        onChange={(e) => settxt(e.target.value)}
      />
      <BtnModal variant="contained" color="primary" onClick={Send}>
        ارسال
      </BtnModal>
      <Modal open={open} onClose={closeModal} className={c.modal}>
        <div className={c.modalContent}>
          <div className={c.textContent}>
            <Typography variant="caption">
              در خواست شما برای کاربر ارسال شد. در صورت موافقت کاربر شما قادر به
              دیدن پیج اینستاگرام و تلگرام کاربر خواهید بود. در بخش پیام‌هایتان
              می‌توانید درخواستتان را پیگیری کنید.
            </Typography>
          </div>
          <Box className={c.btnContainer}>
            <Box onClick={closeModal} className={c.btn}>
              <Typography variant="h5"> بستن</Typography>
            </Box>
          </Box>
        </div>
      </Modal>
    </Box>
  )
}
