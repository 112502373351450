import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 75px)',
    direction: 'rtl',

    padding: '0px 10px 0px',
    boxSizing: 'border-box',
    overflow: 'auto',
    display: 'flex',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    scrollbarHeight: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    },
    [theme.breakpoints.up('sm')]: {
      '&::-webkit-scrollbar': {
        width: 5,
        height: 5
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: 20
      }
    }
  },

  container: {
    paddingRight: 5,
    paddingTop: 5,
    width: '100%',
    height: '120px',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start'
  },
  addIcon: {
    width: 75,
    height: 75
  }
}))
