import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import BtnModal from '../../BtnModal/BtnModal'
import PersonIcon from '@material-ui/icons/Person'
import ZarinPal from '../../../assets/icons/ZarinPal.svg'
import { SET_OPEN_DRAWER, PAY_DRAWER } from '../../../constants/ActionTypes'
import DraverContext from '../../../contexts/DraverContext'

import { zarinpal } from '../../../helpers/fetch'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column ',
    alignItems: 'center'
  },
  payBtnBox: {
    width: '100%',
    padding: '40px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  payBtn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    width: '80%',
    height: 50,
    borderRadius: 30
  },
  icon: {
    width: 50,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
  },
  text: {
    width: 'calc(100% - 100px)',
    textAlign: 'center'
  },
  cover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    borderRadius: 30
  }
}))

export default function PayDrawer({ type, addUser }) {
  const c = useStyles()
  const { state, dispatch } = useContext(DraverContext)
  const history = useHistory()

  const onPay = (e) => {
    switch (e.target.id) {
      case 'member':
        history.push('/invitationLink')
        dispatch({ type: SET_OPEN_DRAWER, payload: { open: false } })
        break
      case 'zarinpal':
        dispatch({ type: SET_OPEN_DRAWER, payload: { open: false } })
        break
      default:
        break
    }
  }

  const close = () => {
    dispatch({ type: SET_OPEN_DRAWER, payload: { open: false } })
  }

  const Zarinpal = () => {
    localStorage.setItem('reload', true)
    dispatch({ type: SET_OPEN_DRAWER, payload: { open: false } })
    zarinpal(state.data.buyStatus).then((response) => {
      window.location.replace(response.url)
    })
  }

  //premium user only one time can add 3 user and next time it should pay 10 toman
  let premiumUser
  if (type !== 'vip') {
    premiumUser = addUser ? (
      <>
        <Box className={c.payBtn} mb="15px">
          <Box className={c.cover} id="member" />
          <Box className={c.icon}>
            <Typography variant="caption">x3</Typography>
            <PersonIcon />
          </Box>
          <Typography variant="caption" className={c.text}>
            اضافه کردن سه کاربر
          </Typography>
        </Box>
        <Box className={c.payBtn} onClick={Zarinpal}>
          <Box className={c.cover} id="zarinpal" />
          <Box className={c.icon}>
            <Box component="img" src={ZarinPal} />
          </Box>
          <Typography variant="caption" className={c.text}>
            پرداخت با زرین پال
          </Typography>
        </Box>
      </>
    ) : (
      <Box className={c.payBtn} onClick={Zarinpal}>
        <Box className={c.cover} id="zarinpal" />
        <Box className={c.icon}>
          <Box component="img" src={ZarinPal} />
        </Box>
        <Typography variant="caption" className={c.text}>
          پرداخت با زرین پال
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={c.root}>
      <Box className={c.payBtnBox} onClick={onPay}>
        {/* {type !== 'vip' && addUser && (
          <Box className={c.payBtn} mb="15px">
            <Box className={c.cover} id="member" />
            <Box className={c.icon}>
              <Typography variant="caption">x3</Typography>
              <PersonIcon />
            </Box>
            <Typography variant="caption" className={c.text}>
              اضافه کردن سه کاربر
            </Typography>
          </Box>
        )} */}

        {premiumUser}

        {type === 'vip' && (
          <Box className={c.payBtn} onClick={Zarinpal}>
            <Box className={c.cover} id="zarinpal" />
            <Box className={c.icon}>
              <Box component="img" src={ZarinPal} />
            </Box>
            <Typography variant="caption" className={c.text}>
              پرداخت با زرین پال
            </Typography>
          </Box>
        )}
      </Box>
      <BtnModal variant="contained" color="primary" onClick={close}>
        بستن
      </BtnModal>
    </Box>
  )
}
