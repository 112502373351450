import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import CodeSms from './CodeSms'

export default function CodeSmsContextProvider(props) {
    return (
       <RigesterContextProvider>
           <CodeSms history={props.history}/>
       </RigesterContextProvider>
    )
}
