const { makeStyles } = require('@material-ui/core')

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: '#fff'
  },
  buttondiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'row',
    marginTop: '30px'
  },
  button: {
    borderRadius: '50px',
    width: '70%'
  },
  inputBox: {
    [theme.breakpoints.up('xs')]: {
      height: 640,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',

      flexDirection: 'column'
    }
  },
  divinput: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      height: 215,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',

      flexDirection: 'row'
    }
  }
}))
export default useStyle
