import React from 'react'
import ProfileSettingContainer from '../../container/ProfileSettingContainer/ProfileSettingContainer'

export default function ProfileSettingScreen() {
  return (
    <>
      <ProfileSettingContainer />
    </>
  )
}
