import React, { useContext } from 'react'
import uniqid from 'uniqid'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Button, Typography } from '@material-ui/core'
import TitleBox from '../../../TitleBox/TitleBox'
import ListItemBox from '../../../ListItemBox/ListItemBox'
import useStyles from './KnowMoreDrawer.style'
import palette from '../../../../theme/palette'

import {
  SET_OPEN_DRAWER,
  KNOW_MORE_DRAWER
} from '../../../../constants/ActionTypes'
import DraverContext from '../../../../contexts/DraverContext'

export default function DiscriptionBox({ type }) {
  let ps = { type }
  const classes = useStyles(ps)
  const { dispatch } = useContext(DraverContext)
  let history = useHistory()
  let title, list, colorTitle, colorIcon, textBold=''

  switch (type) {
    case 'superLuxury':
      title = 'سوپرلاکچری'
      list = [
        'روزانه بی نهایت درخواست دوستی',
        'روزانه بینهایت چت',
        'در این اکانت،کاراکتر چت ها محدود نمیباشد و بینهایت کاراکتر در هر چت برای شما در نظر گرفته شده است',
        'بدون تبلیغ (ad)شدن برنامه',
        'اولویت پیدا شدن اکانت سوپر لاکچری در بازه سرچ هر استان (هر کاربر‌ که رفرش کند بدون در نظر گرفتن فاصله، اکانت سوپرلاکچری در ابتدای سرچ وی پیدا میشود)',
        'تایید سوپر لاکچری بودن کاربر',
        'پس از ۱ بار خرید این اکانت، پروفایل شما صاحب تیک ابی میشود و حتی اگر بعد از این مدت هیچگونه اکانتی خریداری نکنید تیک ابی شما به صورت همیشگی در پروفایل شما نمایش‌ داده میشود',
        'با خرید این اکانت اواتار پروفایل شما به سوپر لاکچری تغییر حالت میدهد برای فهم کاربران اطراف شما',
        'با خرید این اکانت تم اپلیکیشن شما تغییر رنگ میدهد به منظور جذابتر شدن و استفاده از قابلیت های بیشتر اپلیکیشن'
      ]
      colorTitle = palette.superLuxury.logo
      colorIcon = '#1462FF'
      textBold='این اکانت به مدت ۳۰ روز فعال میشود'
      break
    case 'luxury':
      title = 'لاکچری'
      list = [
        'روزانه ۲۰۰ درخواست دوستی',
        'روزانه بینهایت چت ۵۰۰ کاراکتری',
        'بدون تبلیغ(ad)شدن برنامه',
        'استفاده از فیلتر های خاص که در تنظیمات فعال میشود',
        'اولویت پیدا شدن اکانت لاکچری در بازه سرچ هر استان (هر کاربر‌ که رفرش کند بدون در نظر گرفتن فاصله، اکانت لاکچری در ابتدای سرچ وی پیدا میشود) ',
        'تایید لاکچری بودن کاربر',
        'پس از ۳ بار خرید این اکانت، پروفایل شما صاحب تیک ابی میشود و حتی اگر بعد از این مدت هیچگونه اکانتی خریداری نکنید تیک ابی شما به صورت همیشگی در پروفایل شما نمایش‌ داده میشود',
        'با خرید این اکانت اواتار پروفایل شما به لاکچری تغییر حالت میدهد برای فهم کاربران اطراف شما',
        'با خرید این اکانت تم اپلیکیشن شما تغییر رنگ میدهد به منظور جذابتر شدن و استفاده از قابلیت های بیشتر اپلیکیشن'
      ]
      colorTitle = palette.luxury.logo
      colorIcon = '#FED33C'
      textBold='این اکانت به مدت ۳۰ روز فعال میشود'
      break
    case 'normal':
      title = 'پریمیوم'
      list = [
          'کار با برنامه بدون نمایش تبلیغات',
        'روزانه ۱۰ درخواست دوستی',
      'روزانه ۲۰ چت با کاربران iloc\n' +
      '((توجه داشته باشید تفاوت چت های اکانت پریمیوم با حالت رایگان برنامه، افزایش محدودیت ۵۰۰ کاراکتر به ۱۰۰۰ کاراکتر در هر چت میباشد))',
      'بدون تبلیغات(ad) شدن برنامه',
          'شما میتوانید با افزودن ۳ کاربر بوسیله\n' +
          'کد دعوت خود،یک ماه اکانت پریمیوم خود را فعال نمایید',
      ]
      colorTitle = '#FED33C'
      colorIcon = '#FED33C'
      textBold='((مدت زمان اکانت ۳۰ روز میباشد))'
      break
    default:
      title = 'پریمیوم'
      list = []

      colorTitle = '#FED33C'
      colorIcon = '#FED33C'
      break
  }
  let clas
  switch (type) {
    case 'superLuxury':
      clas = 'select1'
      break
    case 'luxury':
      clas = 'select2'
      break
    case 'normal':
      clas = 'select3'
      break
    default:
      clas = ''
      break
  }

  const goToHome = () => {
    history.push('/home')
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { drawer: KNOW_MORE_DRAWER, open: false }
    })
  }
  return (
    <Box clone>
      <Grid container item className={classes.root} id="discriptionBox">
        <TitleBox bgcolor={colorTitle}>{title}</TitleBox>
        <Box className={classes.list}>
          {list.map((item) => {
            return (
              <ListItemBox key={uniqid()} colorIcon={colorIcon}>
                {item}
              </ListItemBox>
            )
          })}
          {textBold && (
            <Typography className={classes.textBold}>{textBold}</Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={goToHome}
        >
          برگشت به صفحه خانه
        </Button>
      </Grid>
    </Box>
  )
}
