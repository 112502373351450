import React from 'react'
import useStyle from './desktopdiv.style'
export default function Desktopdiv(props) {
    const classes=useStyle()
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}
