import React, { useState } from 'react'
import {NotificationManager} from 'react-notifications'
import Back from '../../../components/Back/Back'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../../components/Typography/Typography'

import useStyle from '../rigester.style'
import Input from '../../../components/Input/Input'
import GoldButton from '../../../components/Button/GoldButton'
import Stepper from '../../../components/Stepper/Stepper'

export default function Name(props) {
    const classes=useStyle()
    const [state,setState]=useState({lastname:'',firstname:''})
    const onChangeText=(e)=>{
       setState({...state,[e.target.name]:e.target.value})
    }
    const continues=()=>{
        if(state.lastname.length<=20&&state.firstname.length<=20){
            props.history.push({
                pathname: '/rigester/username',
                state: { phone:props.history.location.state.phone,code:props.history.location.state.code,lastname:state.lastname,name:state.firstname}
              })
        }else{
            NotificationManager.error('نام و نام خانوادگی باید حداکثر ۲۰ کاراکتر باشد')
        }
    }
    // props.history.push({
    //     pathname: '/rigester/Name',
    //     state: { phone: props.history.location.state.phone,code:props.history.location.state.code, username: props.history.location.state.username, birthday: props.history.location.state.birthday, gender: props.history.location.state.gender, tel_id: props.history.location.state.tel_id, insta_id: props.history.location.state.insta_id, password: props.history.location.state.password,password_confirmation:props.history.location.state.password_confirmation,image:img }
    // })
    const Backs=()=>{
        props.history.push({
            pathname: '/rigester/CodeSms',
            state: { phone:props.history.location.state.phone,code:props.history.location.state.code}
          })
    }
    React.useEffect(() => {
        if(props.history.location.state===undefined){
            props.history.push('/')
        }
    }, [])
    return (
      <Desktopdiv>
        <Stepper complate={'30'} />
        <div>
          <Back onClick={() => Backs()} color="#FED33C" />
          <Typographys
            component="h1"
            text="نام و نام خانوادگی خود را وارد کنید"
            className={classes.h1}
          />
        </div>
        <div className={classes.inputBox}>
          <div className={classes.divinput}>
            <Input
              style={{ direction: 'rtl' }}
              type="text"
              className={classes.input}
              name="firstname"
              label="نام"
              onChange={(e) => onChangeText(e)}
            />
          </div>

          <div style={{ marginTop: '35px', width: '100%' }}>
            <Input
              style={{ direction: 'rtl' }}
              type="text"
              className={classes.input}
              name="lastname"
              label="نام خانوادگی"
              onChange={(e) => onChangeText(e)}
            />
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            onClick={() => continues()}
            disabled={
              state.lastname === '' || state.firstname === '' ? true : false
            }
            variant={
              state.lastname === '' || state.firstname === ''
                ? 'outlined'
                : 'contained'
            }
            className={classes.buttons}
            text="ادامه دهید"
          />
        </div>
      </Desktopdiv>
    )
}
