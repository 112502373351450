import React from 'react'
 import { Box ,Typography} from '@material-ui/core'
// import useStyles from './TextOfCart.style'

export default function TextOfCart(props) {
// const classes = useStyles()

    return (
        <Box>
            <Typography variant='caption' color='secondary'>{props.title}</Typography>
            <Typography variant='subtitle1' color='secondary'>{props.subtitle}</Typography>
        </Box>
    )
}
