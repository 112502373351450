import React, { useContext } from 'react'

import Back from '../../../components/Back/Back'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../../components/Typography/Typography'
import { RigesterContext } from '../../../contexts/RigesterContext'
import useStyle from '../rigester.style'
import Input from '../../../components/Input/Input'
import GoldButton from '../../../components/Button/GoldButton'
import { SETINPUTVALUE } from '../../../constants/ActionTypes'
import Stepper from '../../../components/Stepper/Stepper'
export default function Id(props) {
  const { state, dispatch } = useContext(RigesterContext)
  const classes = useStyle()
  const Backs = () => {
    props.history.push({
      pathname: '/rigester/gender',
      state: {
        phone: props.history.location.state.phone,
        code: props.history.location.state.code,
        lastname: props.history.location.state.lastname,
        name: props.history.location.state.name,
        username: props.history.location.state.username,
        birthday: props.history.location.state.birthday
      }
    })
  }
  const onChangeText = (e) => {
    dispatch({
      type: SETINPUTVALUE,
      payload: { [e.target.name]: e.target.value }
    })
  }
  const continues = () => {
    if (state.TelegramID === '' && state.InstagramID === '') {
      alert('لطفا یکی از فیلد ها را پر کنید')
    } else {
      props.history.push({
        pathname: '/rigester/Password',
        state: {
          phone: props.history.location.state.phone,
          code: props.history.location.state.code,
          lastname: props.history.location.state.lastname,
          name: props.history.location.state.name,
          username: props.history.location.state.username,
          birthday: props.history.location.state.birthday,
          gender: props.history.location.state.gender,
          tel_id: state.TelegramID,
          insta_id: state.InstagramID
        }
      })
    }
  }
  React.useEffect(() => {
    if (props.history.location.state === undefined) {
      props.history.push('/')
    }
  }, [])
  return (
    <Desktopdiv>
      <Stepper complate={'70'} />

      <div>
        <Back onClick={() => Backs()} color="#FED33C" />
        <Typographys
          component="h1"
          text="آیدی اینستاگرام یا تلگرام"
          className={classes.h1}
        />
      </div>
      <div className={classes.inputBox}>
        <div className={classes.divinput}>
          <Input
            className={classes.input}
            name="TelegramID"
            onChange={(e) => onChangeText(e)}
            label="آیدی تلگرام"
          />
        </div>
        <div style={{ marginTop: '35px', width: '100%' }}>
          <Input
            className={classes.input}
            name="InstagramID"
            onChange={(e) => onChangeText(e)}
            label="آیدی اینستاگرام"
          />
        </div>
        <Typographys
          style={{ marginTop: '35px' }}
          className={classes.textHelper}
          variant="subtitle1"
          text="حداقل یکی از موارد بالا را وارد کنید."
        />
        <Typographys
          style={{ marginTop: '15px' }}
          className={classes.textHelper}
          variant="subtitle1"
          text="پیج اینستاگرام و تلگرام شما به هیچ عنوان برای کسی نمایش داده نمیشود و تنها برای کاربرانی که درخواست دوستیشان را بپذیرید قابل مشاهده است."
        />
      </div>
      <div className={classes.btncontinue}>
        <GoldButton
          onClick={() => continues()}
          disabled={
            state.InstagramID === '' && state.TelegramID === '' ? true : false
          }
          variant={
            state.InstagramID === '' && state.TelegramID === ''
              ? 'outlined'
              : 'contained'
          }
          className={classes.buttons}
          text="ادامه دهید"
        />
      </div>
    </Desktopdiv>
  )
}
