import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import Gender from './Gender'

export default function userindexgender(props) {
    return (
      <RigesterContextProvider>
          <Gender history={props.history}/>
      </RigesterContextProvider>
    )
}
