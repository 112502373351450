import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { DrawerContextProvider } from '../../contexts/DraverContext'
import { ModalContextProvider } from '../../contexts/ModalContext'
import UnReadRequest from '../../contexts/UnReadRequest'
import IndexContainer from '../../container/IndexContainer/IndexContainer'
import backDrupImage from '../../assets/images/backDrupImage.png'

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${backDrupImage}) repeat ${theme.palette.background.main}`
  }
}))

export default function IndexScreen() {
  const c = useStyles()
  const [unReadRequest, setUnReadRequest] = useState(false)
  const value = { unReadRequest, setUnReadRequest }
  return (
    <div className={c.root}>
      <UnReadRequest.Provider value={value}>
        <DrawerContextProvider>
          <ModalContextProvider>
            <IndexContainer />
          </ModalContextProvider>
        </DrawerContextProvider>
      </UnReadRequest.Provider>
    </div>
  )
}
