import React from 'react'
import { Box } from '@material-ui/core'
import useStyles from './styles'

export default function Label(props) {
  const c = useStyles(props)
  return (
    <Box className={ `${ c.root } ${ c.label }` }>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.348"
        height="19.536"
        viewBox="0 0 34.348 19.536"
      >
        <path
          id="path5280"
          d="M11.335,2.99l-.309.262c-3.508,3.033-6.834,5.9-9.965,8.695l-.914.817.919.809c3.65,3.207,7.4,6.129,9.906,8.636l.317.318H34.494V2.99Zm.809,2.167H32.327v15.2H12.139c-2.391-2.34-5.569-4.9-8.708-7.6C6.192,10.31,9.079,7.808,12.143,5.158ZM13.9,9.492a3.251,3.251,0,1,0,3.251,3.251A3.268,3.268,0,0,0,13.9,9.492Zm0,2.167a1.084,1.084,0,1,1-1.084,1.084A1.068,1.068,0,0,1,13.9,11.66Z"
          transform="translate(-0.146 -2.99)"
        />
      </svg>
    </Box>
  )
}
