const { makeStyles } = require('@material-ui/core')

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: 161,
    alignItems: 'flex-end',
    marginBottom: '10px'
  },
  img: {
    width: '100%',
    maxWidth: 150
  },
  h5: {
    fontFamily: 'fourHand !important',
    fontSize: 28,
    color: '#2f2f2f'
  }
}))
export default useStyle
