import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
//import useStyles from './ListItemBox.style'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
    marginBottom: 10,
    p: (ps) => ps.p || 3
  },
  icon: {
    position: 'absolute',
    top: -7,
    color: (ps) => ps.colorIcon
  },
  text: {
    marginLeft:28
  }
}))

export default function ListItemBox({ colorIcon, p, children }) {
  const classes = useStyles({ colorIcon, p })

  return (
    <Box className={classes.root}>
      <CheckIcon className={classes.icon} />

      <Typography
        color="secondary"
        variant="subtitle1"
        className={classes.text}
      >
        {children}
      </Typography>
    </Box>
  )
}
