import React from 'react'
import uniqid from 'uniqid'
import Cart from '../Cart/Cart'
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll'
export default function SearchList(props) {
  return (
    <InfiniteScroll
      dataLength={props.list.length}
      next={props.nextpage !== null && props.fetchMoreData}
      height="calc(100vh - 70px)"
    >
      {props.list.map((item) => {
        return <Cart key={uniqid()} info={item} />
      })}
    </InfiniteScroll>
  )
}
