import { TextField } from '@material-ui/core'
import React from 'react'
const Input = ({
  label,
  variant,
  className,
  type,
  onChange,
  name,
  style,
  inputMode
}) => (
  <TextField
    style={style}
    label={label}
    variant={variant}
    className={className}
    type={type}
    dir="ltr"
    onChange={onChange}
    name={name}
    inputMode={inputMode}
  />
)
export default Input