import React from 'react'

export default function Stepper(props) {
    return (
        <div style={{width:'100%',height:'10px',backgroundColor:'#868686',marginBottom:'10px',position:'absolute',left:0,top:30}}>
            <div style={{width:`${props.complate}%`,background:'#fed33c',height:'10px'}}>

            </div>

        </div>
    )
}
