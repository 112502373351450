import {SETINPUTERROR,SETINPUTVALUE} from '../constants/ActionTypes'
function reducer(state, action) {
 switch(action.type){
   
   case SETINPUTVALUE:return{...state,...action.payload}
   case SETINPUTERROR:return{...state,...action.payload}
   default:return state
 }
}
export default reducer
