import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column ',
    alignItems: 'center'
  },
  text: {
    paddingTop: '25px',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  boxText: {
    width: 'fit-content',
    padding: '15px 60px',
    borderRadius: 15,
    textAlign: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    margin: 20
  }
}))
