import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import Id from './Id'

export default function indexId(props) {
    return (
        <RigesterContextProvider>
            <Id history={props.history}/>
        </RigesterContextProvider>
    )
}
