import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  Char_counter:{
    height:20,
    backgroundColor:theme.palette.headerFooterChat.main,
  },
  charTxt:{
    fontSize:'9px',
    paddingRight:'15px',
    paddingLeft:'20px',
  },
  charNum:{
    fontSize:'11px',
    fontWeight: 'bold',
  }
}));