import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import useStyles from './ChatList.style'
import ChatCart from '../../components/ChatCart/ChatCart'
import { getChatList } from '../../helpers/fetch'
import { SocketContext } from '../../contexts/Socket'
const ChatList = () => {
  const socket = useRef(useContext(SocketContext))
  const history = useHistory()
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const [isChatToShow, setIsChatToShow] = useState(false)
  useEffect(() => {
    if (socket.current.connected){
      socket.current.disconnect();
    }
  })
  useEffect(() => {
    getChatList()
      .then((response) => {
        if (response) {
          const resp = Object.values(response)
         console.log(resp)
          if (resp[0]) {
            setUsers((prevUsers) => {
              return resp.sort((a, b) => {
                let c = new Date(changeDate(a.date))
                let d = new Date(changeDate(b.date))
                return d - c
              })
            })
            setIsChatToShow(true)
          }
          // console.log(resp[0])
        } else {
          setIsChatToShow(false)
          throw new Error('هیچ چتی برای نمایش یافت نشد')
        }
      })
      .catch((e) => console.log(e.message))
  }, [])

  const back = () => {
    history.push('/')
  }
  const changeDate=(date)=>{
    const s =date.split(' ');
    const n=s[1] +' '+ s[0];
    const d=n.replace(/-/g, '/')
    return d;
  }
  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.header}>
        <Grid item className={classes.headerTextBox}>
          <Typography className={classes.headerText} variant="h2">
            iLoc chat
          </Typography>
        </Grid>
        <Grid item className={classes.backArrow}>
          <ArrowBackIosIcon onClick={back} />
        </Grid>
      </Grid>
      {!isChatToShow && (
        <Grid item className={classes.noChat}>
          <Typography variant="h6">هیچ چتی برای نمایش یافت نشد</Typography>
        </Grid>
      )}
      {users.map((user) => (
        <ChatCart key={user.id} {...user} />
      ))}
    </Grid>
  )
}

export default ChatList
