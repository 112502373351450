import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    width: (ps) => ps.width || '100%',
    height: (ps) => ps.height || '40px',
    background: (ps) => ps.bgcolor || 'gray',
    borderRadius: (ps) => ps.borderRadius || '5px',
    fontSize: (ps) => ps.fontSize || '24px',
    color: (ps) => ps.color ||theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
