import React, { useContext, useState } from 'react'
import { Grid, Box, Typography, Modal } from '@material-ui/core'
import useStyle from './ReqestDrawer.style'
import WcIcon from '@material-ui/icons/Wc';
import StarsIcon from '@material-ui/icons/Stars'
import MenuIcon from '@material-ui/icons/MoreVert'
import blueTikImg from '../../../../assets/images/blueTik.png'
import Avatar from '../../../Avatar/Avatar'
import MoreIcon from '../../../Icons/Label'
import Distance from '../../../Icons/Distance'
import BtnModal from '../../../BtnModal/BtnModal'
import SocialBtnsBox from '../../../SocialBtnsBox/SocialBtnsBox'
import RequestBtnsBox from '../../../RequestBtnsBox/RequestBtnsBox'
import {
  SET_OPEN_DRAWER,
  MESSAGE_DRAWER,
  REQEST_DRAWER,
  SET_OPEN_MODAL
} from '../../../../constants/ActionTypes'
import DraverContext from '../../../../contexts/DraverContext'
import ModalContext from '../../../../contexts/ModalContext'
import {
  addfvoritelist, afterseeads,
  blockUsers,
  report, requestfriend,
  unFavoritelist
} from '../../../../helpers/fetch'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import FavoriteIcon from '@material-ui/icons/Favorite'
import {NewModal} from "../../../Chat/Modal/NewModal";

export default function ReqestDrawer({ userInfo, blueTik, friend }) {
  const { dispatch} = React.useContext(DraverContext);
  const ctx=React.useContext(DraverContext);
  const [open, setOpen] = useState(false);
  const { state, dispatch: dispatchModal } = useContext(ModalContext)
  const [favourite, setFavourite] = useState(userInfo.fav === 0 ? false : true)
  const [openReport, setOpenReport] = useState(false)
  const [showModal,setshowModal]=useState();
  const [openNewModal, setOpenNewModal] = useState(false);
  const showBtnRequestFriend=ctx.state.showBtnRequestFriend;

  const openModal = () => {

    setOpen(true)
  }
  const closeModal = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { open: false }
    })
    setOpen(false)
  }

  const openModalNew = () => {
    setOpenNewModal(true)
  }
  const closeNewModal = () => {
    setOpenNewModal(false);
    const code = Math.floor(Math.random() * 10000);
    afterseeads(code);
    setOpen(false)
  }
  const Send = () => {
    requestfriend('درخواست دوستی',userInfo.id).then((response) => {
      console.log(response);
      if (response) {
        if(response.status==true){
          NotificationManager.success(response.data)
          openModal();
        }else{
          if (response.data==='برای درخواست به افراد بیشتر باید تبلیغ ببینید'){
            setshowModal(true);
            openModalNew();
          }
          NotificationManager.error(response.data)
        }

      }
    })
  }

  const openMenu = () => {
    dispatchModal({ type: SET_OPEN_MODAL, payload: { open: true } })
  }
  const closeMenu = () => {
    dispatchModal({ type: SET_OPEN_MODAL, payload: { open: false } })
    openReport && setOpenReport(false)
  }
  let statusText = ''
  switch (userInfo.statusUser ? userInfo.statusUser:userInfo.status_user) {
    case 1:
      statusText = 'پرمیوم'
      break
    case 2:
      statusText = 'لاکچری'
      break
    case 3:
      statusText = 'سوپرلاکچری'
      break
    case 4:
      statusText = 'الماس'
      break

    default:
      break
  }
  let sex = ''
  switch (userInfo.sex) {
    case 'm':
      sex = 'پسر'
      break
    case 'f':
      sex = 'دختر'
      break
    case 's':
      sex = 'سایر'
      break
    default:
      sex=''
      break
  }
  const blockUser = () => {

    blockUsers(userInfo.id)
  }
  const removeFavaroit = () => {
   unFavoritelist( userInfo.id ).then((response)=>{
     if (response.data===true){
       setFavourite(false)
       window.location.reload();
     }
   });

    // window.location.reload();
  }
  const classes = useStyle({ favourite })
  const openMessageDrawer = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { drawer: REQEST_DRAWER, open: false }
    })
    setTimeout(() => {
      dispatch({
        type: SET_OPEN_DRAWER,
        payload: { drawer: MESSAGE_DRAWER, open: true, data: userInfo }
      })
    }, 200)
  }
  const addFavaroit = () => {
    addfvoritelist(userInfo.id).then((response)=>{
      if (response.data===true){
        setFavourite(true)
        window.location.reload();
      }
    });
    setFavourite(true)
    // window.location.reload();
  }
  const openReportHandler = () => {
    setOpenReport(true)
  }
  const Reports = (type) => {
    report(userInfo.id, type)
  }
  return (
      <React.Fragment>
    <Grid container item direction="column">
      <Box className={classes.container}>
        {userInfo.user !== 1 && (
          <MenuIcon className={classes.menuIcon} onClick={openMenu} />
        )}
        <Grid container item className={classes.avatarBox}>
          <Box mr="10px">
            <Avatar userInfo={{ ...userInfo }} text={false} />
          </Box>
          <Typography className={classes.nameText}>
            {userInfo.username}
          </Typography>
          {userInfo.blueTik === 1 ? (
            <Box component="img" src={blueTikImg} className={classes.blueTik} />
          ) : (
            <></>
          )}
        </Grid>
        <Typography className={classes.statusText}>{statusText}</Typography>
        <Grid container item className={classes.rowBox}>
          <Box width="30px" height="30px" mr="10px" clone>
            <WcIcon />
          </Box>
          <Typography variant="subtitle1">{sex}</Typography>
        </Grid>
        <Grid container item className={classes.rowBox}>
          <Box mr="10px">
            <MoreIcon x="30px" y="30px" />
          </Box>
          <Typography variant="subtitle1">{userInfo.bio}</Typography>
        </Grid>
        {userInfo.user !== 1 && (
          <Grid container item className={classes.rowBox}>
            <Box height="45px">
              <Distance/>
            </Box>
            <Box className={classes.distanceTextBox}>
              <Typography
                className={classes.distanceText}
              >{`${userInfo.km}`}</Typography>
            </Box>
          </Grid>
        )}
        {userInfo.user !== 1 && (
          <Grid container item className={classes.rowBox}>
            {userInfo.fav === 0 ? (
              <React.Fragment>
                <Box
                  width="30px"
                  height="30px"
                  mr="10px"
                  onClick={addFavaroit}
                  color=""
                  clone
                >
                  <StarsIcon className={classes.starsIcon} />
                </Box>
                <Typography variant="caption">اضافه کردن به دوستان</Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box
                  width="30px"
                  height="30px"
                  mr="10px"
                  color=""
                  clone
                  onClick={removeFavaroit}
                >
                  <StarsIcon className={classes.starsIcon} />
                </Box>
                <Typography variant="caption">حذف کردن از دوستان</Typography>
              </React.Fragment>
            )}
          </Grid>
        )}
      </Box>
      {userInfo.user !== 1 && (
        <div>
          {friend ? (
            <SocialBtnsBox
              userInfo={{
                tel_id: userInfo.tel_id,
                insta_id: userInfo.insta_id
              }}
            />
          ) : (
            <React.Fragment>
               <Grid container item className={classes.noticText}>
            
            <Typography
              style={{marginBottom:'10px'}}
            >برای دیدن پیج اینستاگرام یا تلگرام کاربر باید در خواست دوستی دهید.</Typography>

        </Grid>
            {showBtnRequestFriend ? <BtnModal
              variant="contained"
              color="primary"
              onClick={Send}
            >
              درخواست دوستی
            </BtnModal>
              :
              <RequestBtnsBox userInfo={userInfo}/>}

            </React.Fragment>
          )}
        </div>
      )}
      <Modal className={classes.modal} open={state.open} onClose={closeMenu}>
        {openReport ? (
          <Grid className={classes.modalGrid}>
            <Grid className={classes.menuItem}>
              <Typography variant="subtitle1" onClick={() => Reports(0)}>
                {' '}
                محتوای نامناسب
              </Typography>
            </Grid>
            <Grid className={classes.menuItem}>
              <Typography variant="subtitle1" onClick={() => Reports(1)}>
                پروفایل غیر اخلاقی
              </Typography>
            </Grid>
            <Grid className={classes.menuItem}>
              <Typography variant="subtitle1" onClick={() => Reports(2)}>
                ایجاد مزاحمت
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.modalGrid}>
            <Grid className={classes.menuItem} onClick={blockUser}>
              <Typography variant="subtitle1"> مسدودکردن</Typography>
            </Grid>
            <Grid className={classes.menuItem} onClick={openReportHandler}>
              <Typography variant="subtitle1">گزارش هرزنامه</Typography>
            </Grid>
            {/* <Grid className={classes.menuItem}>
              <Typography variant="subtitle1">آنفالو کردن</Typography>
            </Grid> */}
          </Grid>
        )}
      </Modal>
    </Grid>
        {showModal && <NewModal open={openNewModal} text={'برای دیدن تبلیغ کلیک کنید'} btnText={'تبلیغ'} closeModal={closeNewModal} closeModalAds={()=>{setshowModal(false)}}/>}
      </React.Fragment>
  )
}
