import React, { useEffect, useContext } from 'react'
import { Container, Box, makeStyles } from '@material-ui/core'

import { BrowserRouter as Router } from 'react-router-dom'
import Routes from '../../routes/index'
import Drawer from '../../components/Drawer'
import DraverContext from '../../contexts/DraverContext'
import ModalContext from '../../contexts/ModalContext'
import {
  SET_OPEN_DRAWER,
  ALERT_END_DRAWER,
  SET_OPEN_MODAL
} from '../../constants/ActionTypes'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { remain } from '../../helpers/fetch'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    background: theme.palette.background.main,
    padding: 0
  }
}))

export default function IndexScreen() {
  const c = useStyles()
  const { dispatch } = useContext(DraverContext)
  const { dispatch: dispatchModel } = useContext(ModalContext)
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      dispatch({
        type: SET_OPEN_DRAWER,
        payload: { open: false }
      })
      dispatchModel({
        type: SET_OPEN_MODAL,
        payload: { open: false }
      })
    })
    remain().then((response) => {
      response &&
        response.days < 6 &&
        dispatch({
          type: SET_OPEN_DRAWER,
          payload: {
            drawer: ALERT_END_DRAWER,
            open: true,
            type: 'account',
            data: response
          }
        })
    })

    return () => {
      window.removeEventListener('popstate', (event) => {
        dispatch({
          type: SET_OPEN_DRAWER,
          payload: { open: false }
        })
      })
    }
  }, [])

  return (
    <Container className={c.root} maxWidth="sm">
      <NotificationContainer />
      <Router>
        <Routes />
        <Drawer />
      </Router>
    </Container>
  )
}
