import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    padding: 40,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    marginTop: 90
  },
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 100,
    marginBottom: 100,
    justifyContent: 'space-between',
    height: 150
  },
  btn: {
    height: 50,
    borderRadius: 30,
    marginBottom: 20
  }
}))
