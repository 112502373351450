import React from 'react'
import { Box, Grid } from '@material-ui/core'
import useStyles from './SearchBox.style'
import palette, { dark } from '../../theme/palette'

import SearchIcon from '@material-ui/icons/Search'

export default function SearchBox(props) {
  const classes = useStyles()
  const onchangeHandler = (e) => {
    props.onChange(e.target.value)
  }
  return (
    <Box className={classes.root} clone>
      <Grid container item>
        <Box
          component="input"
          type="text"
          placeholder="آیدی مورد نظر را وارد نمایید"
          onChange={onchangeHandler}
          className={classes.inpotBox}
        />
        <Box className={classes.iconBox}>
          <SearchIcon color="secondary" style={{fontSize:40}}/>
        </Box>
      </Grid>
    </Box>
  )
}
