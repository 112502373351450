import React, { useState } from 'react'
import { Box, Modal, Typography } from '@material-ui/core'
import ForumIcon from '@material-ui/icons/Forum'
import useStyles from './RequestBtnsBox.style'
import FavoriteIcon from '@material-ui/icons/Favorite'
import DraverContext from '../../contexts/DraverContext'
import {
  REQEST_DRAWER,
  SET_OPEN_DRAWER,
  SET_TRIGGER
} from '../../constants/ActionTypes'
import { afterseeads, requestfriend } from '../../helpers/fetch'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import { useHistory } from 'react-router-dom'
import { NewModal } from '../Chat/Modal/NewModal'

export default function RequestBtnsBox({ userInfo }) {
  const c = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [openNewModal, setOpenNewModal] = useState(false)
  const { dispatch } = React.useContext(DraverContext)
  const [showModal, setshowModal] = useState()
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { open: false }
    })
    setOpen(false)
  }
  const openModalNew = () => {
    setOpenNewModal(true)
  }
  const closeNewModal = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { open: false }
    })
    setOpenNewModal(false)
    const code = Math.floor(Math.random() * 10000)
    afterseeads(code)
    setOpen(false)
  }
  const Send = () => {
    requestfriend('درخواست دوستی', userInfo.id).then((response) => {
      if (response) {
        if (response.status == true) {
          if (response.isSendRequestBefore == true) {
            openModal()
          }
          NotificationManager.success(response.data, '', 5000)
        } else {
          if (
            response.data === 'برای درخواست به افراد بیشتر باید تبلیغ ببینید'
          ) {
            setshowModal(true)
            openModalNew()
          }
          NotificationManager.error(response.data, '', 5000)
        }
      }
    })
  }
  /*  const openMessageDrawer = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { drawer: REQEST_DRAWER, open: false }
    })
    setTimeout(() => {
      dispatch({
        type: SET_OPEN_DRAWER,
        payload: { drawer: MESSAGE_DRAWER, open: true, data: userInfo }
      })
    }, 200)
  }*/
  return (
    <React.Fragment>
      <Box className={c.root}>
        <Box onClick={Send} className={`${c.btn} ${c.friendsRequest}`}>
          <FavoriteIcon style={{ fontSize: 30 }} />
          <Typography className={c.ml} variant="caption">
            درخواست دوستی
          </Typography>
        </Box>
        <Box
          className={`${c.btn} ${c.chat}`}
          onClick={() => {
            dispatch({
              type: SET_OPEN_DRAWER,
              payload: { drawer: REQEST_DRAWER, open: false }
            })
            history.push({
              pathname: '/chat',
              state: { userInfo: userInfo }
            })
          }}
        >
          <ForumIcon style={{ fontSize: 30 }} />
          <Typography className={c.ml} variant="caption">
            چت
          </Typography>
        </Box>
        <Modal open={open} onClose={closeModal} className={c.modal}>
          <div className={c.modalContent}>
            <div className={c.textContent}>
              <Typography variant="caption">
                در صورت پذیرفتن کاربر مورد نظر، شما می‌تونی اکانت تلگرام یا
                اینستاشو ببینی و در بخش
                <FavoriteIcon style={{ fontSize: 20 }} />
                در صفحه اصلی میتونی درخواست‌های ارسالی و دریافتیتو پیگیری کنی
              </Typography>
            </div>
            <Box className={c.btnContainer}>
              <Box onClick={closeModal} className={c.btnModal}>
                <Typography variant="h5"> بستن</Typography>
              </Box>
            </Box>
          </div>
        </Modal>
      </Box>
      {showModal && (
        <NewModal
          open={openNewModal}
          text={'اکانت شما در حالت پایه میباشد.شما میتوانید روزانه 3 درخواست دوستی ارسال کنید برای ارسال بیشتر درخواست دوستی تبلیغ ببینید'}
          btnText={'تبلیغ'}
          closeModal={closeNewModal}
          closeModalAds={() => {
            setshowModal(false)
          }}
        />
      )}
    </React.Fragment>
  )
}
