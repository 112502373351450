import React, { useEffect } from 'react'
import { makeStyles, CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import backDrupImage from '../../assets/images/backDrupImage.png'
import { informationUser } from '../../helpers/fetch'

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${backDrupImage}) repeat ${theme.palette.background.main}`,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h5': {
      color: theme.palette.text.main,
      marginTop: 10
    }
  }
}))

export default function AfterPayContainer(props) {
  const c = useStyles()
  const history = useHistory()
  const redirect = () => {
    history.push('/home')
  }
  if (!localStorage.getItem('reload')) {
    window.addEventListener('load', redirect)
  }
  useEffect(() => {
    informationUser().then((res) => {
      localStorage.setItem('status_user', res.user.status_user)

      if (localStorage.getItem('reload')) {
        localStorage.setItem('reload', '')
        window.location.reload()
      }
    })
    return () => {
      window.removeEventListener('load', function () {
        history.push('/home')
      })
    }
  }, [])
  return (
    <div className={c.root}>
      <div className={c.content}>
        <CircularProgress color="primary" />
        <Typography variant="h5">در حال بروزرسانی </Typography>
        <Typography variant="h5">اطلاعات </Typography>
      </div>
    </div>
  )
}
