import React, { useState, useContext, useRef } from 'react'
import { Box, Grid } from '@material-ui/core'
import Menu from '../../components/Menu/Menu'
import HeaderHome from '../../components/HeaderHome/HeaderHome'
import HomeContent from '../../components/HomeContent/HomeContent'
import AvatarList from '../../components/AvatarList/AvatarList'
import IntroTour from '../../components/IntroTour/IntroTour'
import useStyles from './homeScreen.style'
import { location, locationsave } from '../../helpers/fetch'
import { NotificationManager } from 'react-notifications'

import UnReadRequest from '../../contexts/UnReadRequest'
import UnSeenNumberContext from '../../contexts/UnSeenNumberContext'
import { SocketContext } from '../../contexts/Socket'

export default function HomeScreen() {
  const socket = useRef(useContext(SocketContext))
  const classes = useStyles()
  const [state, setState] = useState([])
  const [link, setLink] = useState({})
  const { unReadRequest, setUnReadRequest } = useContext(UnReadRequest)
  const [unSeenNum, setUnSeenNum] = useState(0)
  const [loca, setLoca] = useState(false);
  const [long, setLong] = useState()
  const value = { unSeenNum, setUnSeenNum }

  React.useEffect(()=>{
    console.log(loca,'1')
    if (socket.current.connected){
      socket.current.disconnect();
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
          function (position) {
            var lat = position.coords.latitude
            var lng = position.coords.longitude
            locationsave(String(lat), String(lng)).then((response)=>{
              if(response.data==='ok'){
                setLoca(true);
              }
            })

          },
          function error() {
            setLoca(true);
            NotificationManager.error(
                'دستگاه شما لوکیشن خود را ارسال نمیکند',
                '',
                1000
            )
          }
      )
    } else {
      setLoca(true);
      NotificationManager.error('لطفا مکان یاب خود را فعال کنید')

    }


  },[])



  React.useEffect(() => {
    if (loca===true){
      console.log(loca,'2')
      location().then((response) => {
        // console.log(response);
        if (response) {
          setState((state) => state.concat(response.data))
          setLink(response)
          if (response.data[0].unReadRequest > 0) {
            setUnReadRequest(true)
          }

          setUnSeenNum(response.data[0].unseennumbers)
          // console.log(response.data)
        }
      })
    }
  }, [loca])


  const next = () => {
    var next = link.links.next
    if (next !== null) {
      location('?' + next.split('?')[1]).then((response) => {
        setState((state) => state.concat(response.data))
        setLink(response)
      })
    }
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <UnSeenNumberContext.Provider value={value}>
        <HeaderHome />
      </UnSeenNumberContext.Provider>
      <AvatarList state={state} next={next} />
      <HomeContent />
      <Menu activeIcon="home" unReadRequest={unReadRequest} />
    </Grid>
  )
}
