import {makeStyles} from '@material-ui/core'
import pathImage from '../../assets/images/backChat.jpg';
import palette from "../../theme/palette";
export default makeStyles((theme) => ({
    root: {
        // height: 'calc(var(--vh, 1vh) * 100)',
        height: '100vh',
        minHeight: '-webkit-fill-available',
        width: '100%',
        maxWidth: '600px',
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    },
    pos: {
        // position:'fixed',
        // height: '100%',
        // width:'inherit',
        // maxWidth:'inherit',
        // overflow:'hidden',
    },
    main: {
        // height:'calc((var(--vh, 1vh) * 100) - 76px)',
        height: 'calc(100vh - 78px)',
        top: '78px',
        maxWidth: '600px',
        backgroundImage: theme.palette.backGroundChatImage.backgroundImage,
        background: theme.palette.backGroundColorChat.main,
        overflow: 'auto',
        overflowStyle: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }

    },
    scrollChat: {
        maxHeight: 'calc(100vh- 155px)',
        marginBottom: '48px',
        overflow: 'auto',
        overflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },

    yourMessage: {
        flexDirection: 'row-reverse',
        overflow: 'hidden',

    },
    mineMessage: {
        overflow: 'hidden',
    },
    bubbleChat: {
        position: 'relative',
        borderRadius: '.3em',
        display: ' inline-block',
        height: 'auto',
        background: 'white',
        margin: '5px',
        maxWidth: '50%',
        wordBreak: 'break-word',
        marginLeft: '15px',
        marginRight: '15px',
        color: 'black'
    },
    // mineBackground:{
    //   background:theme.palette.backGroundBubbleChat.mine
    // },
    // yourBackground:{
    //   background:theme.palette.backGroundBubbleChat.your
    // },
    mineBubbleChat: {
        background: theme.palette.backGroundBubbleChat.mine,
        "&:before": {
            position: "absolute",
            content: "''",
            height: 0,
            width: 0,
            left: '-7px',
            bottom: '0px',
            borderBottom: `10px solid ${theme.palette.backGroundBubbleChat.mine}`,
            borderLeft: '12px solid transparent',
        }

    },

    yourBubbleChat: {
        background: theme.palette.backGroundBubbleChat.your,
        "&:after": {
            position: "absolute",
            content: "''",
            height: 0,
            width: 0,
            right: '-7px',
            bottom: 0,
            borderBottom: `10px solid ${theme.palette.backGroundBubbleChat.your}`,
            borderRight: '12px solid transparent',
        }
    },
    MessageText: {
        padding: '5px',
        paddingBottom: '0px',
        color: theme.palette.MessageText.main
    },
    footer: {
        // height:'45px',
        position: 'absolute',
        bottom: 0,
        maxWidth: '600px',
        backgroundColor: theme.palette.headerFooterChat.main,
        marginTop: 'auto',
    },
    timeContainer: {
        maxHeight: '20px',
        marginRight: '4px',
        marginLeft: '4px',
        marginBottom: '1.5px',
        width: 'inherit'
    },
    mineDoneIcon: {
        fontSize: "15px",
        color: theme.palette.doneIconClock.mine
    },
    mineTime: {
        paddingRight: '5px',
        marginLeft: '3px',
        paddingTop: '0px',
        fontSize: '10px',
        color: theme.palette.doneIconClock.mine
    },
    yourTime: {
        paddingRight: '5px',
        marginLeft: '3px',
        paddingTop: '0px',
        fontSize: '10px',
        color: theme.palette.doneIconClock.your
    },

    inputChat: {
        minHeight: '42px',
        width: '100%',
        wordBreak: 'break-word',
        fontSize: '15px',
        padding: '10px',
        outline: 'none',
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'block',
        background: 'inherit',
        color: theme.palette.text.primary,
        border: 'none',
        boxShadow: 'none',
        resize: 'none',
    },
    rootBtn: {
        display: 'flex',
        width: 'inherit',
        height: 39,
        borderRadius: '100%'
    },
    btn: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        color: '#ffffe0',
    },
    EmojiEmotionsIcon: {
        color: theme.palette.emojiButton.main

    },
    emojiBtn: {
        border: 'white',
        background: 'transparent',
        cursor: 'pointer'
    },
    SendIcon: {
        color: theme.palette.ButtonColorChat.main
    },

    ml: {marginRight: 5},

}))

// .makeStyles-scrollChat-1219 .MuiGrid-root:last-child .makeStyles-mineBubbleChat-1224:before
