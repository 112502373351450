import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 50,
    display: 'flex',
    cursor:'pointer',
  },

  send: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderBottom: (ps) =>
      ps.boxName === 'SEND' ? `3px solid ${theme.palette.primary.main}` : ''
  },
  received: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderBottom: (ps) =>
      ps.boxName === 'RECEIVED'
        ? `3px solid ${theme.palette.primary.main}`
        : ''
  }
}))
