import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, IconButton, Tooltip } from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
// import { IconButton, Tooltip } from "@material-ui/core";
const UserAvatar = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      position:'relative',
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    cutting: props.cutting !== false && {
      borderRadius: "49%",
      borderBottomRightRadius: "0px",
    },
    input: {
      display: 'none'
    },
    logo: {
      position: 'absolute',
      bottom: 0,
      background: 'white',
      borderRadius: '50% 50% 50% 0',
      border:'1px solid #bdbdbd'
    },
    size:{
      width:'151px',
      height:'151px',
      borderRadius:'10px',
      border:'1px solid #bdbdbd'
    }
  }));
  const imageUploader = useRef(null);
  const uploadedImage = useRef(null);
const classes=useStyles()
  
  return (
    <div className={classes.root}>
      <Avatar
        src={props.src}
        ref={uploadedImage}
        className={classes.size}
        variant="square"
      />
      <input
        accept="image/*"
        className={classes.input}
        id="icon-button-file"
        type="file"
        onChange={props.onChange}
        ref={imageUploader}
      />
      <label htmlFor="icon-button-file" className={classes.logo}>
  
          <IconButton aria-label="upload picture" component="span">
            <AddAPhoto/>
          </IconButton>
      </label>
    </div>
  );
};

export default UserAvatar;