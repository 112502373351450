import React, { useState, useContext } from 'react'
import uniqid from 'uniqid'
import palette from '../../../theme/palette'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import GoldButton from '../../../components/Button/GoldButton'
import useStyle from '../rigester.style'
import { NotificationManager } from 'react-notifications'
import Typographys from '../../../components/Typography/Typography'
import PaymentModal from '../../PaymentModal/PaymentModal'
import Back from '../../../components/Back/Back'
import { rigesters } from '../../../helpers/fetch'
import { setCookie } from '../../../components/CookieManger/CookieManger'
import Stepper from '../../../components/Stepper/Stepper'
import {
  Typography,
  Checkbox,
  Modal,
  CircularProgress
} from '@material-ui/core'
import { SET_OPEN_MODAL } from '../../../constants/ActionTypes'
import ModalContext from '../../../contexts/ModalContext'

export default function Loc(props) {
  const classes = useStyle()
  const { state: stateModal, dispatch } = useContext(ModalContext)
  const [checked, setChecked] = useState(false)

  let rules = [
    '	عضویت در این اپلیکیشن، خرید و دیگر اقدامات به منزله مطالعه و پذیرش شرایط عضویت و قوانین زیر می باشد.',
    '	مسئولیت محتوای پروفایل کاربران( نام کاربری، عکس پروفایل و...) برعهده خود کاربر می باشد و iLoc مسئولیتی در قبال محتوای عکس و متن بیوی کاربران ندارد.',
    'اطلاعات کاربری در این اپ کاملاً محرمانه است، بنابراین مسئولیت حفاظت از نام کاربری و رمز ورود کاربر برعهده خود کاربر است و درصورت هرگونه سوء استفاده از حساب کاربری، مسئولیتی بر عهده iLoc نخواهد بود.',
    '	در خرید اکانت ها (فعالسازی – لاکچری – سوپرلاکچری) کاربر کاملاً مخیر به انتخاب و خرید اکانت خود بوده و بدیهی ست پس از خرید مبلغ پرداختی به هیچ عنوان قابل استرداد نمی باشد.',
    '	از آنجا که تنها راه تشخیص لاکچری بودن و نبودن کاربران فقط از طریق خرید اکانت امکانپذیر است، تیک آبی رنگ تایید لاکچری بودن و سوپر لاکچری بودن نیز تنها با خرید اکانت های لاکچری و سوپر لاکچری امکان پذیر است و این تاییدیه به این معناست که کاربر توسط اپلیکیشن iLoc از لحاظ مالی بعنوان یک شخص لاکچری و یا سوپرلاکچری شناخته شده است.',
    '	قیمت گذاری اکانت ها بر اساس اعطای ارزش به اکانت های مختلف کاربران تعیین گردیده و کاربر با تمایل خود می تواند هرکدام از اکانت ها را انتخاب و خریداری کند.',
    '	یکی از شروط عضویت در این اپلیکیشن سن بالای 18 سال است و iLoc مسئولیتی در قبال وارد کردن سن غیرواقعی توسط کاربر ندارد.',
    'اگر کاربری توسط توسط 5 کاربر یا بیشتر ریپورت شود، اکانت وی به مدت یک هفته بن خواهد شد، به طوریکه امکان ارسال درخواست دوستی برای اکانت بن شده به مدت یک هفته غیرفعال می شود.',
    'اپلیکیشن iLoc یک برنامه بر کاربر محور و برپایه اعتماد به کاربر است و تنها توانایی تایید لاکچری بودن کاربران را آنهم بر اساس اکانت خریداری شده شان دارا می باشد. بنابراین iLoc هیچگونه مسئولیتی در قبال وارد کردن اطلاعات غیرواقعی توسط کاربران ندارد.',
    'اپلیکیشن iLoc هیچگونه مسئولیتی درقبال عملکرد کاربران در اپ و فعالیت های آنها ندارد. ',
    'اکانت های لاکچری و سوپرلاکچری به مرور قابلیت ها و امکانات جدید را نسبت به اکانت فعال خواهند داشت و این امکانات در صورت اعمال به کاربر خریدار اعلام خواهد شد.',
    'احراز هویت کاربران تنها با شماره تلفن وارد شده است و باقیمانده مشخصات اختیاری است و اجباری در پرکردن آن برای کاربر وجود ندارد. ',
    '	در برنامه نویسی اپ تا جایی که امکان پذیر بوده امکان ساخت پروفایل کاربری با نامهای غیراخلاقی و مضامین مبتذل وجود ندارد. '
  ]

  const submit = () => {
    
    dispatch({
      type: SET_OPEN_MODAL,
      payload: { open: true }
    })
    const state = props.history.location.state
    var fd = new FormData()
    fd.append('phone', state.phone)
    fd.append('code', state.code)
    fd.append('username', state.username)
    fd.append('birthday', state.birthday)
    fd.append('gender', state.gender)
    fd.append('tel_id', state.tel_id)
    fd.append('insta_id', state.insta_id)
    fd.append('password', state.password)
    fd.append('password_confirmation', state.password_confirmation)
    if (state.image) {
      fd.append('image', state.image)
    }
    fd.append('lastname', state.lastname)
    fd.append('name', state.name)
    localStorage.getItem('referrer_token') &&
      fd.append('referrer_token', localStorage.getItem('referrer_token'))
    rigesters( fd ).then( ( response ) => {
      setCookie( 'token', response.user.api_token, 1 )
      localStorage.setItem( 'status_user', response.user.status_user )
      localStorage.setItem( 'id', response.user.id )
      localStorage.setItem('darkMode', '0')
      localStorage.setItem( 'reload', true )
      NotificationManager.success( response.data )
      props.history.push('/tour')
      dispatch({
        type: SET_OPEN_MODAL,
        payload: { open: false }
      })
    })
  }
 
  const Backs = () => {
    props.history.push({
      pathname: '/rigester/Image',
      state: {
        phone: props.history.location.state.phone,
        code: props.history.location.state.code,
        username: props.history.location.state.username,
        birthday: props.history.location.state.birthday,
        gender: props.history.location.state.gender,
        tel_id: props.history.location.state.tel_id,
        insta_id: props.history.location.state.insta_id,
        password: props.history.location.state.password,
        password_confirmation:
          props.history.location.state.password_confirmation,
        image: props.history.location.state.image
      }
    })
  }
  React.useEffect(() => {
    if (props.history.location.state === undefined) {
      props.history.push('/')
    }
  }, [])
  const onCheckedHandler = () => {
    setChecked((perState) => !perState)
  }
  return (
    <div>
      <Desktopdiv>
        <Stepper complate={'100'} />

        <div>
          <Back onClick={() => Backs()} color="#FED33C" />
          <Typographys
            component="h1"
            text="قوانین و مقررات"
            className={classes.h1}
          />
        </div>
        <div className={classes.ruleBox}>
          <div className={classes.ulBox}>
            <ul>
              {rules.map((item) => (
                <li key={uniqid()}>
                  <Typography variant="body1">{item}</Typography>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.checkBoxDiv}>
            <Checkbox
              className={classes.checkBox}
              checked={checked}
              onChange={onCheckedHandler}
            />
            <Typography variant="body1">
              قوانین iLoc را بطور دقیق مطالعه و آنها را پذیرفته‌ام.
            </Typography>
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            disabled={!checked}
            onClick={() => submit()}
            variant="contained"
            className={classes.buttons}
            text="میپذیرم"
          />
        </div>
      </Desktopdiv>
      <Modal open={stateModal.open} className={classes.modal}>
        <div className={classes.modalContent}>
          <CircularProgress color="primary" />
          <Typography variant="h5">در حال ارسال اطلاعات </Typography>
        </div>
      </Modal>
    </div>
  )
}
