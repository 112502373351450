import React, { useState, useEffect, useContext } from 'react'
import { Grid, Modal, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import uniqid from 'uniqid'
import useStyles from './BlackListContainer.style'
import CartWithMenu from '../../components/CartWithMenu/CartWithMenu'
import ModalContext from '../../contexts/ModalContext'
import { SET_OPEN_MODAL } from '../../constants/ActionTypes'
import {blocklist, unblocklist} from '../../helpers/fetch'
export default function BlackListContainer(props) {
  const classes = useStyles()
  const history = useHistory()
  const { state, dispatch } = useContext(ModalContext)
  const [list, setList] = useState([])
  useEffect(() => {
    blocklist().then(response=>{
      setList(response)
    })
    
  }, [])

  const closeModalHandler = () => {
    dispatch({ type: SET_OPEN_MODAL, payload: { open: false } })
  }
  const unblockHandler = () => {
    unblocklist(state.data.userInfo.id)
    dispatch({ type: SET_OPEN_MODAL, payload: { open: false } })
  }
  const back = () => {
    history.push('/profile/setting')
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <Typography variant="subtitle1">
          کاربران مسدود قادر به پیدا کردن حساب کاربری شما در صفحه جستجو
          نمی‌باشند.
        </Typography>
      </Grid>
      <Grid className={classes.content}>
        {list.map((item) => {
          return <CartWithMenu key={uniqid()} info={item} brderBottom />
        })}
      </Grid>
      <Grid className={classes.btnBox}>
        <Button
          color="primary"
          variant="contained"
          onClick={back}
          className={classes.btnBack}
        >
          بازگشت
        </Button>
      </Grid>
      <Modal
        className={classes.modal}
        open={state.open}
        onClose={closeModalHandler}
      >
        <Grid className={classes.modalGrid} onClick={unblockHandler}>
          <Typography variant="subtitle1" className={classes.modalText}>
            رفع مسدودیت
          </Typography>
        </Grid>
      </Modal>
    </Grid>
  )
}
