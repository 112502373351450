import React, { useContext } from 'react'
import { Box, Avatar } from '@material-ui/core'
import TextOfCart from './TextOfCart/TextOfCart'
import useStyles from './CartWithMenu.style'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ModalContext from '../../contexts/ModalContext'
import DraverContext from '../../contexts/DraverContext'
import { SET_OPEN_MODAL ,SET_OPEN_DRAWER,REQEST_DRAWER} from '../../constants/ActionTypes'

export default function CartWithMenu({ info, onClick, brderBottom }) {
  const ps = { brderBottom }
  const classes = useStyles(ps)
  const { dispatch } = useContext(ModalContext)
  const { dispatch: dispatchDrawer } = useContext(DraverContext)


  const showDrawer = () => {
       dispatchDrawer({
         type: SET_OPEN_DRAWER,
         payload: {
           open: true,
           drawer: REQEST_DRAWER,
           data: {
             friend: info.tel_id || info.insta_id ? true : false,
             userInfo: info,
             blueTik: info.score
           }
         }
       })
     
   }
  return (
    <Box className={classes.root}>
      <Box className={classes.avatar} onClick={showDrawer}>
        <Box width="60px" height="60px" clone>
          <Avatar
            alt=""
            src={info.image ? 'https://iloc.myiloc.com' + info.image : ''}
          />
        </Box>
      </Box>
      <Box className={classes.text} onClick={showDrawer}>
        <TextOfCart
          title={info.username}
          subtitle={info.name + ' ' + info.lastname}
        />
      </Box>
      <Box className={classes.option}>
        <MoreVertIcon
          className={classes.menu}
          onClick={() => {
            dispatch({
              type: SET_OPEN_MODAL,
              payload: { open: true, data: { userInfo: info } }
            })
          }}
        />
      </Box>
    </Box>
  )
}
