import React, { useContext } from 'react'
import { NotificationManager } from 'react-notifications'
import Back from '../../../components/Back/Back'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../../components/Typography/Typography'
import { RigesterContext } from '../../../contexts/RigesterContext'
import useStyle from '../rigester.style'
import Input from '../../../components/Input/Input'
import GoldButton from '../../../components/Button/GoldButton'
import { SETINPUTVALUE } from '../../../constants/ActionTypes'
import Stepper from '../../../components/Stepper/Stepper'
export default function Password(props) {
  const classes = useStyle()
  const { state, dispatch } = useContext(RigesterContext)
  const onChangeText = (e) => {
    dispatch({
      type: SETINPUTVALUE,
      payload: { [e.target.name]: e.target.value }
    })
  }
  const continues = () => {
    if (state.Password === state.ConfirmPassword) {
      if (state.Password.length >= 8 && state.ConfirmPassword.length >= 8) {
        props.history.push({
          pathname: '/rigester/Image',
          state: {
            phone: props.history.location.state.phone,
            code: props.history.location.state.code,
            lastname: props.history.location.state.lastname,
            name: props.history.location.state.name,
            username: props.history.location.state.username,
            birthday: props.history.location.state.birthday,
            gender: props.history.location.state.gender,
            tel_id: props.history.location.state.tel_id,
            insta_id: props.history.location.state.insta_id,
            password: state.Password,
            password_confirmation: state.ConfirmPassword
          }
        })
      } else {
        NotificationManager.error('رمز عبور باید ۸ کاراکتر باشد')
      }
    } else {
      NotificationManager.error('رمز عبور با تکرار ان یکسان نیست')
    }
  }
  const Backs = () => {
    props.history.push({
      pathname: '/rigester/Id',
      state: {
        phone: props.history.location.state.phone,
        code: props.history.location.state.code,
        lastname: props.history.location.state.lastname,
        name: props.history.location.state.name,
        username: props.history.location.state.username,
        birthday: props.history.location.state.birthday,
        gender: props.history.location.state.gender,
        tel_id: props.history.location.state.tel_id,
        insta_id: props.history.location.state.insta_id
      }
    })
  }
  React.useEffect(() => {
    if (props.history.location.state === undefined) {
      props.history.push('/')
    }
  }, [])
  return (
    <Desktopdiv>
      <Stepper complate={'80'} />
      <div>
        <Back onClick={() => Backs()} color="#FED33C" />
        <Typographys
          component="h1"
          text="رمز خود را وارد کنید"
          className={classes.h1}
        />
      </div>
      <div className={classes.inputBox}>
        <div className={classes.divinput}>
          <Input
            type="password"
            className={classes.input}
            name="Password"
            label="رمز عبور"
            onChange={(e) => onChangeText(e)}
          />
        </div>

        <div style={{ marginTop: '35px', width: '100%' }}>
          <Input
            type="password"
            className={classes.input}
            name="ConfirmPassword"
            label="تکرار رمز عبور"
            onChange={(e) => onChangeText(e)}
          />
        </div>
      </div>
      <div className={classes.btncontinue}>
        <GoldButton
          onClick={() => continues()}
          disabled={
            state.Password === '' || state.ConfirmPassword === '' ? true : false
          }
          variant={
            state.Password === '' || state.ConfirmPassword === ''
              ? 'outlined'
              : 'contained'
          }
          className={classes.buttons}
          text="ادامه دهید"
        />
      </div>
    </Desktopdiv>
  )
}
