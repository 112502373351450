import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import Birthdaydate from './Birthdaydate'

export default function birthdayindex(props) {
    return (
        <RigesterContextProvider>
            <Birthdaydate history={props.history}/>
        </RigesterContextProvider>
    )
}
