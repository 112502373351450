import React, { useContext, useState } from 'react'
import { Grid, Box, Avatar, Typography } from '@material-ui/core'
import colorBorder from '../../../assets/icons/colorBorder.svg'
import blueTikImg from '../../../assets/images/blueTik.png'
import Diomond from '../../../assets/icons/Diomond.svg'
import SuperLuxury from '../../../assets/icons/superluxury.svg'
import Luxury from '../../../assets/icons/Luxury.svg'
import useStyles from './AvatartChat.style.js'


export default function AvatartChat({ userInfo, blueTik }) {
    const classes = useStyles()
    let key = userInfo.statusUser
    let border = ''
    let size='55px';

    switch (key) {
        case 0:
        case 1:
            border = colorBorder
            size='60px'
            break
        case 2:
            border = Luxury
            size='50px'
            break
        case 3:
            border = SuperLuxury
            size='50px'
            break
        case 4:
            border = Diomond
            size='51px'
            break

        default:
            break
    }

    return (
        <Grid
            container
            className={classes.root}
            style={{width:size}}
            direction="column"
            justify="space-between"
        >
            <Box className={classes.avatar} style={{width:size,height:size}}>
                {blueTik === 1 ? (
                    <Box component="img" src={blueTikImg} className={classes.blueTik} />
                ) : (
                    <></>
                )}
                <Box component="img" src={border} className={classes.border} />
                <Avatar
                    alt=""
                    className={classes.avatarImg}
                    src={userInfo.image ? 'https://iloc.myiloc.com' + userInfo.image : ''}
                />
            </Box>

        </Grid>
    )
}
