import React from 'react'
import { Box, Typography } from '@material-ui/core'

export default function StatusOfCart(props) {
  let statusColor, text
  switch (props.status) {
    case 2:
      statusColor = 'success.main'
      text = 'پذیرفته‌ شده'
      break
    case 0:
      statusColor = 'error.main'
      text = ' رد شده'
      break
    case 1:
      statusColor = 'warning.main'
      text = 'در‌انتظار پاسخ'
      break

    default:
      break
  }

  return (
    <Box color={statusColor} clone>
      <Typography>{text}</Typography>
    </Box>
  )
}
