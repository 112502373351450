import { makeStyles } from '@material-ui/core'
import theme from '../../../../../theme/theme'

export default makeStyles((theme) => ({
  root: {
    display: (ps) => (ps.type ? 'flex' : 'none'),
    padding: 20,
    paddingBottom: 0,
    flexDirection: 'column'
  },
  knowMore: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& >*': {
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  },
  list: {
    marginTop: 10,
    height: 125,
    overflow: 'hidden'
  }
}))
