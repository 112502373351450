import React from 'react'
import { Box, Typography } from '@material-ui/core'
import useStyles from './TitleBoxWithArrow.style'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

export default function TitleBoxWithArrow({ icon, title, onClick }) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.cover} onClick={onClick} />
      <Box className={classes.iconAndText}>
        <Box>
          {icon}
        </Box>
        <Typography variant='body1'>{title}</Typography>
      </Box>
      <Box className={classes.arrow}>
        <ChevronLeftIcon style={{fill:"gray"}}/>
      </Box>
    </Box>
  )
}
