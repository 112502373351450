import React, { useState, useContext, useEffect } from 'react'
import { Box } from '@material-ui/core'
import useStyles from './NavLink.style'
import { MsgScreenContext } from '../../contexts/MsgScreenContext'
import {
  SET_BOXNAME,
  SET_SEND_MSGLIST,
  SET_RECEIVED_MSGLIST,
  SEND,
  RECEIVED
} from '../../constants/ActionTypes'
import { received, showsendBox } from '../../helpers/fetch'
import palette from '../../theme/palette'


export default function NavLink () {

  const { state, dispatch } = useContext(MsgScreenContext)
  const classes = useStyles(state)
  let payload = []
  useEffect(() => {
    showsendBox(1,state.filterList).then( ( response ) => {
      if (typeof response.data==="string") {
        dispatch({ type: SET_SEND_MSGLIST, payload: [] })
      }else{
        dispatch({ type: SET_SEND_MSGLIST, payload: response.data })
      }
    })
  }, [] )
  useEffect( () => {
    
    received(1, state.filterList).then((response) => {
      if (typeof response.data === 'string') {
        dispatch({ type: SET_RECEIVED_MSGLIST, payload: [] })
      } else {
        dispatch({ type: SET_RECEIVED_MSGLIST, payload: response.data })
      }
    })
  }, [state.trigger])
  const onClickHandler = (e) => {
    let target = e.target.getAttribute('name')
    switch (target) {
      case 'sendLink':
        showsendBox(1,state.filterList).then((response) => {
           if (typeof response.data === 'string') {
             dispatch({ type: SET_SEND_MSGLIST, payload: [] })
           } else {
             dispatch({ type: SET_SEND_MSGLIST, payload: response.data })
           }
        })
        dispatch({ type: SET_BOXNAME, payload: SEND })
        break
      case 'receivedLink':
        dispatch({ type: SET_BOXNAME, payload: RECEIVED })
        received(1,state.sortList).then((response) => {
           if (typeof response.data === 'string') {
             dispatch({ type: SET_RECEIVED_MSGLIST, payload: [] })
           } else {
             dispatch({ type: SET_RECEIVED_MSGLIST, payload: response.data })
           }
        })

        break

      default:
        break
    }
  }
  return (
    <Box
      className={classes.root}
      onClick={(e) => {
        onClickHandler(e)
      }}
    >
      <Box
        name="sendLink"
        className={classes.send}
       
      >
        ارسالی
      </Box>
      <Box
        name="receivedLink"
        className={classes.received}
      >
        دریافتی
      </Box>
    </Box>
  )
}
