import React, { useContext } from 'react'
import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import SortIcon from '@material-ui/icons/Sort'
import { MsgScreenContext } from '../../../contexts/MsgScreenContext'
import { SET_OPENMODAL } from '../../../constants/ActionTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '40px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  filterIcon:{
    color:theme.palette.icon.primary,
    marginTop:10,
  }
}))

export default function FilterBox(props) {
  const c = useStyles()
  const { state, dispatch } = useContext(MsgScreenContext)
  const openModal = () => {
    dispatch({ type: SET_OPENMODAL, payload: true })
  }
  return (
    <Grid container item className={c.root} onClick={openModal}>
      {state.boxName === 'SEND' ? (
        <React.Fragment>
          <Box mx="10px">
            <span className={`material-icons ${c.filterIcon}`}>filter_alt</span>
          </Box>
          <Typography> فیلتر کردن &nbsp;</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box mx="10px" clone>
            <SortIcon style={{color:"gray"}}/>
          </Box>
          <Typography> مرتب کردن &nbsp;</Typography>
        </React.Fragment>
      )}
    </Grid>
  )
}
