import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    borderRadius: '0 0 20px 20px'
  },
  btn: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    color: '#ffffe0'
  },
  telegtam: {
    background: '#4EA4F6'
  },
  instagram: {
    background:
      'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); '
  },
  ml: { marginRight: 5 }
}))
