import { createContext } from 'react'

const UnReadRequest = createContext({
  unReadReq: false,
  setUnReadReq: () => {}
})

export default UnReadRequest

/* ********* ContextProvider *********** */
// function UnReadRequestProvider({ children }) {
//   const [unRead, setUnRead] = useState(false)
//   return (
//     <UnReadRequest.Provider value={{ unRead, setUnRead }}>
//       {children}
//     </UnReadRequest.Provider>
//   )
// }

// export { UnReadRequestProvider }
