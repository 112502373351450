import pathImage from '../assets/images/backChat.jpg'
export default {
  primary: {
    main:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '4'
        ? '#1462FF'
        : '#FED33C'
  },
  secondary: {
    main:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '2' ||
      localStorage.getItem('status_user') === '4'
        ? '#ffffe0'
        : '#868686'
  },
  MessageText: {
    main:
      localStorage.getItem('status_user') === '0' ||
      localStorage.getItem('status_user') === '1'
        ? '#2F2F2F'
        : '#FFFFFF'
  },
  doneIconClock: {
    mine:
      (localStorage.getItem('status_user') === '0' && '#2F2F2F') ||
      (localStorage.getItem('status_user') === '1' && '#2F2F2F') ||
      (localStorage.getItem('status_user') === '2' && '#FFFFC2') ||
      (localStorage.getItem('status_user') === '3' && '#C5E0FF') ||
      (localStorage.getItem('status_user') === '4' && '#C5E0FF'),
    your:
      (localStorage.getItem('status_user') === '0' && '#2F2F2F') ||
      (localStorage.getItem('status_user') === '1' && '#2F2F2F')
        ? '#2F2F2F'
        : '#C8C8C8'
  },
  backGroundBubbleChat: {
    mine:
      (localStorage.getItem('status_user') === '0' && '#E2FFC7') ||
      (localStorage.getItem('status_user') === '1' && '#E2FFC7') ||
      (localStorage.getItem('status_user') === '2' && '#B2B237') ||
      (localStorage.getItem('status_user') === '3' && '#3E6189') ||
      (localStorage.getItem('status_user') === '4' && '#3E6189'),

    your:
      (localStorage.getItem('status_user') === '0' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '1' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '2' && '#3D3D3D') ||
      (localStorage.getItem('status_user') === '3' && '#535353') ||
      (localStorage.getItem('status_user') === '4' && '#535353')
  },
  backGroundChatImage: {
    backgroundImage:
      localStorage.getItem('status_user') === '0' ||
      localStorage.getItem('status_user') === '1'
        ? `url(${pathImage})`
        : ''
  },
  backGroundColorChat: {
    main:
      (localStorage.getItem('status_user') === '0' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '1' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '2' && '#2F2F2F') ||
      (localStorage.getItem('status_user') === '3' && '#1A1C2D') ||
      (localStorage.getItem('status_user') === '4' && '#1A1C2D')
  },
  headerFooterChat: {
    main:
      (localStorage.getItem('status_user') === '0' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '1' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '2' && '#434343') ||
      (localStorage.getItem('status_user') === '3' && '#2C2D3B') ||
      (localStorage.getItem('status_user') === '4' && '#2C2D3B')
  },
  ButtonColorChat: {
    main:
      (localStorage.getItem('status_user') === '0' && '#00241B') ||
      (localStorage.getItem('status_user') === '1' && '#00241B') ||
      (localStorage.getItem('status_user') === '2' && '#FED33C') ||
      (localStorage.getItem('status_user') === '3' && '#1462FF') ||
      (localStorage.getItem('status_user') === '4' && '#1462FF')
  },
  emojiButton: {
    main:
      localStorage.getItem('status_user') === '0' ||
      localStorage.getItem('status_user') === '1'
        ? `#000000`
        : '#FFFFFF'
  },
  backgroundBox: {
    main:
      (localStorage.getItem('status_user') === '0' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '1' && '#FFFFFF') ||
      (localStorage.getItem('status_user') === '2' && '#3D3D3D') ||
      (localStorage.getItem('status_user') === '3' && '#535353') ||
      (localStorage.getItem('status_user') === '4' && '#535353')
  },
  text: {
    primary:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '2' ||
      localStorage.getItem('status_user') === '4'
        ? '#ffffe0'
        : '#2f2f2f',
    secondary: '#ffffe0',
    button: '#ffffe0',
    main:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '2' ||
      localStorage.getItem('status_user') === '4'
        ? '#ffffe0'
        : '#000',
    dark: '#000',
    light: '#ffffe0'
  },

  success: {
    main: '#1DB954'
  },
  warning: {
    main: '#FED33C'
  },
  error: {
    main: '#FF1E1E'
  },
  icon: {
    primary: '#868686'
  },
  button: {
    active:
      'linear-gradient(90deg, rgba(253,181,38,1) 0%, rgba(255,244,84,1) 100%)'
  },
  luxury: {
    main: 'radial-gradient( rgba(255,211,60,1) 0%, rgba(255,186,73,1) 100%)',
    logo: 'radial-gradient( rgba(255,211,60,1) 0%, rgba(26,28,45,1) 70%)'
  },

  superLuxury: {
    main: 'radial-gradient( rgba(20,98,255,1) 0%, rgba(26,28,45,1) 70%);',
    logo: 'radial-gradient( rgba(20,98,255,1) 0%, rgba(26,28,45,1) 70%)'
  },

  normal: {
    main: '#FED33C',
    logo: '#FED33C'
  },

  background: {
    main:
      (localStorage.getItem('status_user') === '0' && '#fff') ||
      (localStorage.getItem('status_user') === '1' && '#fff') ||
      (localStorage.getItem('status_user') === '2' && '#2f2f2f') ||
      (localStorage.getItem('status_user') === '3' && '#1A1C2D') ||
      (localStorage.getItem('status_user') === '4' && '#1A1C2D'),
    lightDark:
      localStorage.getItem('status_user') === '0' ||
      localStorage.getItem('status_user') === '1'
        ? '#000'
        : '#ffffe0'
  },
  codeInput:
    localStorage.getItem('status_user') === '0' ||
    localStorage.getItem('status_user') === '1'
      ? '#000'
      : '#ffffe0',
  socialNetworkLinks:
    localStorage.getItem('status_user') === '0' ||
    localStorage.getItem('status_user') === '1'
      ? '#000'
      : '#ffffe0'
}

const light = {
  primary: {
    main:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '4'
        ? '#1462FF'
        : '#FED33C'
  },
  secondary: {
    main:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '2' ||
      localStorage.getItem('status_user') === '4'
        ? '#ffffe0'
        : '#868686'
  },
  text: {
    primary:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '2' ||
      localStorage.getItem('status_user') === '4'
        ? '#ffffe0'
        : '#2f2f2f',
    secondary: '#ffffe0',
    button: '#ffffe0',
    main:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '2' ||
      localStorage.getItem('status_user') === '4'
        ? '#ffffe0'
        : '#000',
    dark: '#000',
    light: '#ffffe0'
  },

  success: {
    main: '#1DB954'
  },
  warning: {
    main: '#FED33C'
  },
  error: {
    main: '#FF1E1E'
  },
  icon: {
    primary: '#868686'
  },
  button: {
    active:
      'linear-gradient(90deg, rgba(253,181,38,1) 0%, rgba(255,244,84,1) 100%)'
  },
  luxury: {
    main: 'radial-gradient( rgba(255,211,60,1) 0%, rgba(255,186,73,1) 100%)',
    logo: 'radial-gradient( rgba(255,211,60,1) 0%, rgba(26,28,45,1) 70%)'
  },

  superLuxury: {
    main: 'radial-gradient( rgba(20,98,255,1) 0%, rgba(26,28,45,1) 70%);',
    logo: 'radial-gradient( rgba(20,98,255,1) 0%, rgba(26,28,45,1) 70%)'
  },

  normal: {
    main: '#FED33C',
    logo: '#FED33C'
  },

  background: {
    main:
      (localStorage.getItem('status_user') === '0' && '#fff') ||
      (localStorage.getItem('status_user') === '1' && '#fff') ||
      (localStorage.getItem('status_user') === '2' && '#2f2f2f') ||
      (localStorage.getItem('status_user') === '3' && '#1A1C2D') ||
      (localStorage.getItem('status_user') === '4' && '#1A1C2D'),
    lightDark:
      localStorage.getItem('status_user') === '0' ||
      localStorage.getItem('status_user') === '1'
        ? '#000'
        : '#ffffe0'
  },
  codeInput:
    localStorage.getItem('status_user') === '0' ||
    localStorage.getItem('status_user') === '1'
      ? '#000'
      : '#ffffe0',
  socialNetworkLinks:
    localStorage.getItem('status_user') === '0' ||
    localStorage.getItem('status_user') === '1'
      ? '#000'
      : '#ffffe0'
}

const dark = {
  primary: {
    main: '#FED33C'
  },
  secondary: {
    main: '#ffffe0'
  },
  text: {
    primary: '#ffffe0',
    secondary: '#ffffe0',
    button: '#ffffe0',
    main: '#ffffe0',
    dark: '#000',
    light: '#ffffe0'
  },

  success: {
    main: '#1DB954'
  },
  warning: {
    main: '#FED33C'
  },
  error: {
    main: '#FF1E1E'
  },
  icon: {
    primary: '#868686'
  },
  button: {
    active:
      'linear-gradient(90deg, rgba(253,181,38,1) 0%, rgba(255,244,84,1) 100%)'
  },
  luxury: {
    main: 'radial-gradient( rgba(255,211,60,1) 0%, rgba(255,186,73,1) 100%)',
    logo: 'radial-gradient( rgba(255,211,60,1) 0%, rgba(26,28,45,1) 70%)'
  },

  superLuxury: {
    main: 'radial-gradient( rgba(20,98,255,1) 0%, rgba(26,28,45,1) 70%);',
    logo: 'radial-gradient( rgba(20,98,255,1) 0%, rgba(26,28,45,1) 70%)'
  },

  normal: {
    main: '#FED33C',
    logo: '#FED33C'
  },

  background: {
    main: '#021212',

    lightDark: '#ffffe0'
  },
  backGroundColorChat: {
    main: '#021212'
  },
  backGroundChatImage: {
    backgroundImage: ''
  },
  backGroundBubbleChat: {
    mine: '#B2B237',
    your: '#535353'
  },
  MessageText: {
    main: '#FFFFFF'
  },
  doneIconClock: {
    mine: '#FFFFC2',
    your: '#C8C8C8'
  },
  headerFooterChat: {
    main: '#192525'
  },
  ButtonColorChat: {
    main: '#FED33C'
  },
  emojiButton: {
    main: '#FFFFFF'
  },
  backgroundBox: {
    main: '#535353'
  },
  codeInput: '#ffffe0',
  socialNetworkLinks: '#ffffe0'
}

export { light, dark }
