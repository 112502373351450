import * as React from 'react'
import {Box, Fade, Grid, Modal, Typography} from '@material-ui/core'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import useStyles from './Store&AdButton.style'
import {BUY_DRAWER, SET_OPEN_DRAWER} from '../../../../constants/ActionTypes'
import {useContext, useEffect} from 'react'
import DraverContext from '../../../../contexts/DraverContext'
import {afterseeads} from "../../../../helpers/fetch";
import {useLocation,useHistory} from "react-router-dom";

export const StoreAdButton = (props) => {
    const classes = useStyles();
    const {state, dispatch} = useContext(DraverContext);
    const [text, setText] = React.useState(false);
    const history = useHistory();

    useEffect(()=>{
        if (props.totalChar===0){
            setText('اضافه شدن 50 کاراکتر با دیدن ویدیو تبلیغات')
        }else{
            setText('دیدن تبلیغ')
        }

    },[])

    const buy = () => {
        dispatch({
            type: SET_OPEN_DRAWER,
            payload: {drawer: BUY_DRAWER, open: true}
        })

    }

    const showAdd = (e) => {
        var a = Math.floor(Math.random() * 10000);
        afterseeads(a, props.id).then((response)=>{
            if (response.data===true){
                props.socket.current.disconnect();
                history.go(0)
            }
        });

    }

        return (
            <React.Fragment>
                <Box className={classes.rootBtn}>
                    <Grid container direction='row' wrap={'nowrap'} alignItems='center' justify='center' onClick={buy}
                          spacing={2}
                          className={`${classes.btn} ${classes.store}`}
                    >
                        <Grid item>
                            <Typography variant='body1' className={classes.storeTxt}>
                                اکانت خود را ارتقا دهید
                            </Typography>
                        </Grid>

                        <Grid item style={{marginLeft: '7px'}}>
                            <LocalMallIcon className={classes.LocalMallIcon}/>
                        </Grid>

                    </Grid>
                    <Box className={`${classes.btn} ${classes.add}`} onClick={showAdd}>
                        <Typography className={`${classes.ml} ${classes.addTxt}`} variant='body1'>
                            {text}
                        </Typography>

                    </Box>
                </Box>
            </React.Fragment>

        )
    }