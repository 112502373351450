import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import Checkbox from '../../../CheckBox/CheckBox'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
// import useStyles from './ItemFilter.style'

export default function ItemFilter({id, text, color, checked, onChange }) {
  // const classes = useStyles()

  return (
    <Box px="40px" py="10px" clone>
      <Grid container item alignItems="center">
        <Checkbox
          id={id}
          size={40}
          color={color}
          checked={checked}
          onChange={onChange}
        />
        <Box ml="10px">
          <Typography>{text}</Typography>
        </Box>
      </Grid>
    </Box>
  )
}
