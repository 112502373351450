import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Modal,
  CircularProgress
} from '@material-ui/core'
import TelegramIcon from '../../components/Icons/TelegramIcon'
import InstagramIcon from '@material-ui/icons/Instagram'
import Tiltle from '../../components/Tiltle/Tiltle'
import CheckBoxList from '../../components/CheckBoxList/CheckBoxList'
import SocialNetworkLinks from '../../components/SocialNetworkLinks/SocialNetworkLinks'
import InputBox from '../../components/InputBox/InputBox'
import SwitchBox from '../../components/SwitchBox/SwitchBox'
import Back from '../../components/Back/Back'
import useStyles from './ProfileSettingContainer.style'
import DraverContext from '../../contexts/DraverContext'
import ModalContext from '../../contexts/ModalContext'
import { ThemeContext } from '../../components/ThemeProvaider'
import {
  SET_OPEN_MODAL,
  SET_OPEN_DRAWER,
  CHANGE_PROFILE_PHOTO_DRAWER
} from '../../constants/ActionTypes'
import { informationUser, profilesub } from '../../helpers/fetch'
import { delCookie } from '../../components/CookieManger/CookieManger'
import Logo from '../../components/Logo/Logo'
import NotificationManager from 'react-notifications/lib/NotificationManager'

export default function ProfileSettingContainer(props) {
  const classes = useStyles()
  const history = useHistory()
  const { dispatch } = useContext(DraverContext)
  const { state } = useContext(ModalContext)
  const { changeThemeTo } = useContext(ThemeContext)
  const show = {
    normal: false,
    luxury: false,
    superLuxury: false,
    almas: false
  }
  const see = {
    normal: false,
    luxury: false,
    superLuxury: false,
    almas: false
  }
  localStorage
    .getItem('show')
    .split(',')
    .map((item) => {
      switch (item) {
        case '1':
        case '0':
          show.normal = true
          break
        case '2':
          show.luxury = true
          break
        case '3':
          show.superLuxury = true
          break
        case '4':
          show.almas = true
          break
      }
    })
  localStorage
    .getItem('see')
    .split(',')
    .map((item) => {
      switch (item) {
        case '1':
        case '0':
          see.normal = true
          break
        case '2':
          see.luxury = true
          break
        case '3':
          see.superLuxury = true
          break
        case '4':
          see.almas = true
          break
      }
    })

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [bio, setBio] = useState('')
  const [telegram, setTelegram] = useState('')
  const [instagram, setInstagram] = useState('')
  const [img, setimg] = useState('')
  const [showToMe, setShowToMe] = useState(see)
  const [showMe, setShowMe] = useState(show)
  const [programnotifications, setProgramnotifications] = useState(true)
  const [resultnotifications, setResultnotifications] = useState(true)
  const [friendnotifications, setFriendnotifications] = useState(true)
  const [managementnotifications, setManagementnotifications] = useState(true)
  const [darkMode, setDarkMode] = useState(false)
  const showToMeCheckList = [
    { name: 'normal', label: 'پریمیوم را ببین' },
    { name: 'luxury', label: 'لاکچری را ببین' },
    { name: 'superLuxury', label: 'سوپر لاکچری را ببین' },
    { name: 'almas', label: 'الماس  را ببین' }
  ]
  const showMeCheckList = [
    { name: 'normal', label: ' پریمیوم' },
    { name: 'luxury', label: 'لاکچری ' },
    { name: 'superLuxury', label: ' سوپر لاکچری' },
    { name: 'almas', label: ' الماس ها' }
  ]

  useEffect(() => {
    informationUser().then((response) => {
      setName(response.user.name)
      setLastName(response.user.lastname)
      setUsername(response.user.username)
      setBio(response.user.bio)
      setTelegram(response.user.tel_id)
      setInstagram(response.user.insta_id)
      setProgramnotifications(response.user.programnotifications)
      setResultnotifications(response.user.resultnotifications)
      setFriendnotifications(response.user.friendnotifications)
      setManagementnotifications( response.user.managementnotifications )
      if (localStorage.getItem('darkMode')==='0') setDarkMode(false)
      if (localStorage.getItem('darkMode')==='1') setDarkMode(true)
        setimg('https://iloc.myiloc.com/' + response.user.image)
    })
  }, [])
  useEffect(() => {
    informationUser().then((response) => {
      setimg('https://iloc.myiloc.com/' + response.user.image)
    })
  }, [state.open])
  const changePhoto = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { drawer: CHANGE_PROFILE_PHOTO_DRAWER, open: true }
    })
  }
  const showToMeChange = (item) => {
    setShowToMe((perState) => {
      return { ...perState, ...item }
    })
  }
  const showMeChange = (item) => {
    setShowMe((perState) => {
      return { ...perState, ...item }
    })
  }
  const back = () => {
    history.push('/profile')
  }
  const goToPath = (path) => {
    history.push(path)
  }
  const submit = () => {
    var show = []
    var see = []
    if (showToMe.normal) {
      show.push('0', '1')
    }
    if (showToMe.luxury) {
      show.push('2')
    }
    if (showToMe.superLuxury) {
      show.push('3')
    }
    if (showToMe.almas) {
      show.push('4')
    }
    if (showMe.normal) {
      see.push('0', '1')
    }
    if (showMe.luxury) {
      see.push('2')
    }
    if (showMe.superLuxury) {
      see.push('3')
    }
    if (showMe.almas) {
      see.push('4')
    }
    if (bio.length <= 70) {
      const data = {
        name: name,
        lastname: lastName,
        username: username,
        tel_id: telegram,
        insta_id: instagram,
        bio: bio,
        show: see,
        see: show,
        programnotifications: programnotifications,
        resultnotifications: resultnotifications,
        friendnotifications: friendnotifications,
        managementnotifications: managementnotifications
      }
      profilesub(data).then((response) => {
        history.push('/')
      })
    } else {
      NotificationManager.error('بیوگرافی باید حداکثر ۷۰ کاراکتر باشد')
    }
  }
  const changeDarkMode = () => {
    if (!darkMode) {
      changeThemeTo('darkMode')
    } else {
      changeThemeTo('active')
    }
    setDarkMode((perState) => !perState)
  }
  return (
    <Grid container item className={classes.root}>
      <Grid container item className={classes.header}>
        <Back />
        <Typography variant="h1" className={classes.headerText} onClick={back}>
          تنظیمات
        </Typography>
      </Grid>
      <Box className={classes.logo}>
        <Logo status={1} />
      </Box>
      <Tiltle title="تنظیمات یابنده" height="25px" />
      <Box className={classes.backdroup}>
        <CheckBoxList
          title="به من نشان بده"
          checked={showToMe}
          checkList={showToMeCheckList}
          onChange={showToMeChange}
        />

        <CheckBoxList
          title="مرا نشان بده"
          checked={showMe}
          checkList={showMeCheckList}
          onChange={showMeChange}
        />
      </Box>

      <Grid className={classes.avatarBox} onClick={changePhoto}>
        <Avatar src={img || ''} className={classes.avatar} />
        <Typography variant="subtitle1">ویرایش عکس</Typography>
      </Grid>
      <Tiltle title="تنظیمات کاربر" height="25px" />
      <Box className={classes.backdroup}>
        <InputBox label="نام " value={name} onChange={setName} />
        <InputBox
          label="نام خانوادگی"
          value={lastName}
          onChange={setLastName}
        />
        <InputBox label="نام کاربری" value={username} onChange={setUsername} />
        <InputBox label="بیوگرافی" value={bio} onChange={setBio} />
        <InputBox
          label="آیدی تلگرام"
          value={telegram}
          onChange={setTelegram}
          icon={<TelegramIcon/>}
        />
        <InputBox
          label="آیدی اینستاگرام"
          value={instagram}
          onChange={setInstagram}
          icon={<InstagramIcon color="secondary" />}
        />
      </Box>
      <Tiltle title="اعلان(notification)" height="25px" />
      <Box className={classes.backdroup}>
        <SwitchBox
          checked={programnotifications}
          onChange={setProgramnotifications}
          label="اعلان برنامه"
        />
        <SwitchBox
          checked={resultnotifications}
          onChange={setResultnotifications}
          label="اعلان نتیجه درخواست ارسالی"
        />
        <SwitchBox
          checked={friendnotifications}
          onChange={setFriendnotifications}
          label="اعلان آمدن درخواست دوستی"
        />
        <SwitchBox
          checked={managementnotifications}
          onChange={setManagementnotifications}
          label="اعلان های مدیریت"
        />
      </Box>
      {localStorage.getItem('status_user') === '0' ||
      localStorage.getItem('status_user') === '1' ? (
        <React.Fragment>
          <Tiltle title="تم" height="25px" />
          <Box className={classes.backdroup}>
            <SwitchBox
              checked={darkMode}
              onChange={changeDarkMode}
              label="حالت تاریک"
            />
          </Box>
        </React.Fragment>
      ) : (
        ''
      )}

      <Grid container item direction="column" alignItems="flex-start">
        <Button
          onClick={() => {
            goToPath('/blackList')
          }}
        >
          کاربران مسدود
        </Button>
        <Button
          onClick={() => {
            goToPath('/changePassword')
          }}
        >
          تغییر رمز ورود
        </Button>
        <Button
          onClick={() => {
            goToPath('/tour')
          }}
        >
          راهنمای برنامه
        </Button>
      </Grid>

      <Grid container item className={classes.btnBox}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnRecord}
          onClick={submit}
        >
          ثبت
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnExit}
          onClick={() => {
            delCookie('token', 1)
            localStorage.removeItem('status_user')
            window.location.reload()
            history.push('/')
          }}
        >
          خروج از حساب
        </Button>
      </Grid>
      <SocialNetworkLinks />

      <Modal open={state.open} className={classes.modal}>
        <div className={classes.modalContent}>
          <CircularProgress color="primary" />
          <Typography variant="h5">در حال ارسال اطلاعات </Typography>
        </div>
      </Modal>
    </Grid>
  )
}
