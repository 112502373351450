import React, { useContext, useEffect } from 'react'
import {Typography} from '@material-ui/core'
import Back from '../../../components/Back/Back'
import Input from '../../../components/Input/Input'
import Typographys from '../../../components/Typography/Typography'
import GoldButton from '../../../components/Button/GoldButton'
import useStyle from '../rigester.style'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import { RigesterContext } from '../../../contexts/RigesterContext'
import { SETINPUTVALUE } from '../../../constants/ActionTypes'
import { NotificationManager } from 'react-notifications';
import { rigester } from '../../../helpers/fetch'
import Stepper from '../../../components/Stepper/Stepper'
// import 'react-phone-number-input/style.css'
export default function Phone(props) {
    let { state, dispatch } = useContext(RigesterContext)
    const classes = useStyle()
    useEffect( () => {
        if ( props.history.location.search !== "" ) {
            localStorage.setItem(
                'referrer_token',
                props.history.location.search.split( '=' )[ 1 ]
            )
        }
        
    }, [])
    const Backs = () => {
        props.history.push('/')
    }
    const continues = () => {
        if (state.phone !== "") {
            if (state.phone.length === 11) {
                rigester(state.phone).then(response => {
                  if(response.data.data == "شماره وارد شده حساب آی‌لوک دارد"){
                    NotificationManager.error(response.data.data)
                  }else{
                    props.history.push({
                        pathname: '/rigester/CodeSms',
                        state: { phone: state.phone }
                    })
                }})

            }
            else {
                NotificationManager.error('شماره تلفن اشتباه است')
            }
        }
        else {
            NotificationManager.error('شماره تلفن را وارد کنید')
        }
    }
    const onChangeText = (e) => {

        dispatch({ type: SETINPUTVALUE, payload: { [e.target.name]: e.target.value } })
    }

    return (
      <Desktopdiv>
        <Stepper complate={'10'} />

        <div>
          <Back onClick={() => Backs()} color="#FED33C" />
          <Typographys
            component="h1"
            text="شماره خود را وارد کنید"
            className={classes.h1}
          />
        </div>
        <div className={classes.inputBox}>
          <div className={classes.divinput}>
            <Input
              className={classes.input}
              name="phone"
              inputMode="tel"
              onChange={(e) => onChangeText(e)}
            />
          </div>
          <div style={{ marginTop: '35px' }}>
            <Typography variant="subtitle1" className={classes.textHelper}>
              به هیچ وجه شماره تلفن شما به کسی نمایش داده نمیشود
            </Typography>
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            disabled={state.phone === '' ? true : false}
            onClick={() => continues()}
            variant={state.phone === '' ? 'outlined' : 'contained'}
            className={classes.buttons}
            text="ادامه دهید"
          />
        </div>
      </Desktopdiv>
    )
}
