import React, { useState } from 'react'
import { Box, Button, Typography, Modal } from '@material-ui/core'
import useStyles from './btnsOfCart.style'

export default function BtnsOfCart(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }

  const accept = () => {
    props.acceptReq()
  }

  const reject = () => {
    closeModal()
  }
  return (
    <Box>
      <Button
        variant="outlined"
        onClick={openModal}
        className={classes.acceptBtn}
      >
        <Typography variant="h5"> تایید</Typography>
      </Button>
      <Button onClick={props.rejectReq} className={classes.rejectBtn}>
        <Typography variant="h5"> رد</Typography>
      </Button>
      <Modal open={open} onClose={closeModal} className={classes.modal}>
        <div className={classes.modalContent}>
          <div className={classes.textContent}>
            <Typography variant="caption">
              با پذیرش در خواست دوستی این کاربر، او می تواند پروفایل اینستاگرام
              یا تلگرام شما را مشاهده کند.
              <br />
              تایید می‌کنید؟
            </Typography>
          </div>
          <Box className={classes.btnContainer}>
            <Box
              onClick={accept}
              className={`${classes.btn} ${classes.accept}`}
            >
              <Typography variant="h5"> تایید</Typography>
            </Box>
            <Box
              onClick={reject}
              className={`${classes.btn} ${classes.reject}`}
            >
              <Typography variant="h5"> بستن</Typography>
            </Box>
          </Box>
        </div>
      </Modal>
    </Box>
  )
}
