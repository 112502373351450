import React, { useState, useContext } from 'react'
import {
  Box,
  Drawer,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core'
import palette from '../../../theme/palette'
import { MsgScreenContext } from '../../../contexts/MsgScreenContext'
import {
  SET_OPENMODAL,
  SET_FILTERSTATUS,
  SET_FILTERLIST,
  SET_SORTLIST,
  SET_SEND_MSGLIST,
  SET_RECEIVED_MSGLIST
} from '../../../constants/ActionTypes'
import BtnModal from '../../BtnModal/BtnModal'
import ItemFilter from './ItemFilter/ItemFilter'
import useStyles from './FilterModal.style'
import { showsendBox, received } from '../../../helpers/fetch'
export default function FilterModal(props) {
  const classes = useStyles()

  const [checkboxs, setCheckboxs] = useState({
    accept: true,
    waiting: true,
    reject: true
  })
  const [radioValue, setRadioValue] = useState(2)

  const { state, dispatch } = useContext(MsgScreenContext)
  const closeModal = () => {
    dispatch({ type: SET_OPENMODAL, payload: false })
  }

  // const filter = (filter) => {
  //   let filterList
  //   dispatch({ type: SET_OPENMODAL, payload: false })
  //   dispatch({ type: SET_FILTERSTATUS, payload: state.filterItem[filter] })
  //   if (filter === 'all') {
  //     dispatch({ type: SET_FILTERLIST, payload: state.sendMsgList })
  //   } else {
  //     filterList = state.sendMsgList.filter((item) => {
  //       return item.statusMsg === filter
  //     })
  //     dispatch({ type: SET_FILTERLIST, payload: filterList })
  //   }
  // }
  const filterHandler = () => {
    dispatch({ type: SET_OPENMODAL, payload: false })
    let filter = []
    if (checkboxs.accept) {
      filter.push('2')
    }
    if (checkboxs.waiting) {
      filter.push('1')
    }
    if (checkboxs.reject) {
      filter.push('0')
    }
    console.log(filter)
    dispatch( { type: SET_FILTERLIST, payload: filter } )

   showsendBox(1, filter).then((response) => {
     if (typeof response.data === 'string') {
       dispatch({ type: SET_SEND_MSGLIST, payload: [] })
     } else {
       dispatch({ type: SET_SEND_MSGLIST, payload: response.data })
     }
   })
  }
  const sortHandler = () => {
    dispatch({ type: SET_OPENMODAL, payload: false })
    let filter = radioValue
     console.log('filter : ', filter)
    dispatch( { type: SET_SORTLIST, payload: filter } )
 
    received(1, filter).then((response) => {
      if (typeof response.data === 'string') {
        dispatch({ type: SET_RECEIVED_MSGLIST, payload: [] })
      } else {
        dispatch({ type: SET_RECEIVED_MSGLIST, payload: response.data })
      }
    })
  }
  const handleChange = (e) => {
    let target = e.target.id
    setCheckboxs((perState) => {
      return { ...perState, [target]: !perState[target] }
    })
  }
  const handleChangeRadio = (e) => {
    setRadioValue(+e.target.value)
  }
  return (
    <Box clone>
      <Drawer
        anchor="bottom"
        open={state.openModal}
        onClose={closeModal}
        classes={{ root: classes.root, paper: classes.paper }}
      >
        {state.boxName === 'SEND' ? (
          <React.Fragment>
            <ItemFilter
              id="accept"
              color={palette.success.main}
              text="سبز (درخواست‌های پذیرفته شده)"
              checked={checkboxs.accept}
              onChange={handleChange}
            />
            <ItemFilter
              id="waiting"
              color={palette.warning.main}
              text="زرد (در انتظار پاسخ)"
              checked={checkboxs.waiting}
              onChange={handleChange}
            />
            <ItemFilter
              id="reject"
              color={palette.error.main}
              text="قرمز ( درخواست‌های رد شده)"
              checked={checkboxs.reject}
              onChange={handleChange}
            />
            <BtnModal
              variant="contained"
              color="primary"
              onClick={filterHandler}
            >
              اعمال فیلتر
            </BtnModal>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classes.radioWraper}>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={radioValue}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value={2}
                  control={
                    <Radio
                      color="primary"
                      classes={{ root: classes.radioItemRoot }}
                    />
                  }
                  label="بر اساس زمان"
                />

                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      color="primary"
                      classes={{ root: classes.radioItemRoot }}
                    />
                  }
                  label="بر اساس خوانده شده‌ها"
                />
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      color="primary"
                      classes={{ root: classes.radioItemRoot }}
                    />
                  }
                  label="‌بر اساس خوانده نشده‌ها"
                />
              </RadioGroup>
            </div>

            <BtnModal variant="contained" color="primary" onClick={sortHandler}>
              اعمال
            </BtnModal>
          </React.Fragment>
        )}
      </Drawer>
    </Box>
  )
}
