import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({

    main:{
        height:'calc(100vh - 110px)',
        backgroundImage:theme.palette.backGroundChatImage.backgroundImage,
        background:theme.palette.backGroundColorChat.main,
    },
    box:{
        width:'40%',
        position:'absolute',
        top:'30%',
        right:'30%',
        background:theme.palette.backgroundBox.main,
        padding:'15px',
        textAlign: 'center',
        borderRadius:'10px'
    },
    textBox:{
        color:theme.palette.MessageText.main,
        fontSize:'11px',
    }

}));