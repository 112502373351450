import React, { useContext } from 'react'
import { Box, Grid } from '@material-ui/core'

import useStyles from './itemMenu.style'
import theme from '../../../theme/theme'
import './messageNotification.css'

export default function ItemMenu({
  icon,
  active,
  name,
  id,
  unReadRequest,
  isNewRequest
}) {
  let argStyle = { active }
  const classes = useStyles(argStyle)
  return (
    <Box className={classes.root} id={id}>
      <Box className={classes.cover} name={name} />
      <Box className={classes.back} />
      <Box className={classes.backdrup} />
      <Box className={classes.circl} bgcolor={active ? 'primary.main' : null} />

      <Box
        height="40px"
        width="40px"
        color={active ? 'primary.main' : theme.palette.icon.primary}
        clone
      >
        <span
          className={`material-icons ${classes.icon} ${
            isNewRequest && name === 'message' ? 'notif' : null
          }`}
        >
          {icon}
        </span>
      </Box>
    </Box>
  )
}
