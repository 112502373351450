import React, { useState } from 'react'
import {NotificationManager} from 'react-notifications'
import Back from '../../components/Back/Back'
import Desktopdiv from '../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../components/Typography/Typography'
import useStyle from '../Rigester/rigester.style'
import Input from '../../components/Input/Input'
import GoldButton from '../../components/Button/GoldButton'
import { reset } from '../../helpers/fetch'

export default function Password(props) {
    const classes=useStyle()
    const [state,setState]=useState({
        Password:'',
        ConfirmPassword:''
    })
    const onChangeText=(e)=>{
      setState({...state,[e.target.name]:e.target.value})
    }
    const continues=()=>{
        if(state.Password===state.ConfirmPassword){
          if(state.Password.length>=8){
            reset(props.history.location.state.phone,props.history.location.state.token,state.Password,state.ConfirmPassword).then(response=>{
                props.history.push('/login')
            })
          }else{
            NotificationManager.error('رمز عبور باید ۸ رقم باشد')
          }
        }else{
            NotificationManager.error('رمز عبور با تکرار ان یکسان نیست')
        }
    }
    const Backs=()=>{
        props.history.push({
            pathname: '/reset/Code',
            state: { phone: props.history.location.state.phone }
       })
    }
    // React.useEffect(() => {
    //     if(props.history.location.state===undefined){
    //         props.history.push('/')
    //     }
    // }, [])
    return (
      <Desktopdiv>
        <div>
          <Back onClick={() => Backs()} />
          <Typographys
            component="h1"
            text="رمز خود را وارد کنید"
            className={classes.h1}
          />
        </div>
        <div className={classes.inputBox}>
          <div className={classes.divinput}>
            <Input
              type="password"
              className={classes.inputResetPass}
              name="Password"
              label="رمز عبور"
              onChange={(e) => onChangeText(e)}
            />
          </div>

          <div style={{ marginTop: '35px', width: '100%' }}>
            <Input
              type="password"
              className={classes.inputResetPass}
              name="ConfirmPassword"
              label="تکرار رمز عبور"
              onChange={(e) => onChangeText(e)}
            />
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            onClick={() => continues()}
            disabled={
              state.Password === '' || state.ConfirmPassword === ''
                ? true
                : false
            }
            variant={
              state.Password === '' || state.ConfirmPassword === ''
                ? 'outlined'
                : 'contained'
            }
            className={classes.buttons}
            text="ادامه دهید"
          />
        </div>
      </Desktopdiv>
    )
}
