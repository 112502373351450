import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalContent: {
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        width: '80%',
        maxWidth: 350,
        borderRadius: 10
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px 10px 0 0',
        textAlign: 'justify',
        padding: 25,
        '& span': {
            lineHeight: 2,
            color: '#000 !important'
        }
    },
    btnContainer: {
        display: 'flex',
        width: '100%',
        borderRadius: '0 0 20px 20px'
    },
    btnModal: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        color: '#ffffe0',
        background: theme.palette.primary.main,
        borderRadius: '0 0 10px 10px'
    },
}));