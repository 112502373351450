import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    borderRadius: '24px',
    height: '100%',
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  circl: {
    display: (sp) => (sp.active ? 'inline-block' : 'none'),
    boxShadow: `0px -1px 3px 0 ${theme.palette.primary.main}`,
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    position: 'absolute',
    top: '-5px',
    zIndex: '-2'
  },
  backdrup: {
    height: '97%',
    width: '97%',
    position: 'absolute',
    zIndex: '-1',
    borderRadius: '24px',
    backgroundColor: theme.palette.background.primary || '#fff'
  },

  cover: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: '2'
  },

  back: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    position: 'absolute',
    borderRadius: '24px',
    zIndex: '-1'
  },

  icon: {
    fontSize: '2.5rem',
    marginTop: '-1rem',
    textAlign: 'center',
  }
}))
