import React, { useContext, useState } from 'react'
import { Box, Grid, Typography, Avatar } from '@material-ui/core'
import Menu from '../../components/Menu/Menu'
import useStyles from './profileScreen.style'
import TitleBoxWithArrow from '../../components/TitleBoxWithArrow/TitleBoxWithArrow'
import SemicircularBlue from '../../assets/images/SemicircularBlue.png'
import SemicircularGold from '../../assets/images/SemicircularGold.png'

import StarIcon from '@material-ui/icons/Star'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SettingsIcon from '@material-ui/icons/Settings'

import { useHistory } from 'react-router-dom'
import { informationUser } from '../../helpers/fetch'

export default function ProfileScreen() {
  const classes = useStyles()
  const history = useHistory()

  let statusUse = localStorage.getItem('status_user')
  let hederImage =
    +statusUse === 3 || +statusUse === 4 ? SemicircularBlue : SemicircularGold
  const directTo = (path) => {
    history.push(path)
  }
  const [state, setState] = useState({
    username: localStorage.getItem('username'),
    image: localStorage.getItem('image'),
    phone: localStorage.getItem('phone'),
    status_user: localStorage.getItem('status_user'),
    lastname: localStorage.getItem('lastname'),
    name: localStorage.getItem('name')
  })
  const [count, setcount] = useState(localStorage.getItem('count'))
  React.useEffect(() => {}, [])
  return (
    <Box minHeight="100vh" width="100%" clone>
      <Grid container className={classes.root}>
        <Box
          component="img"
          src={hederImage}
          className={classes.Semicircular}
        />
        <Grid container item className={classes.contentDiv}>
          {state.name && (
            <Typography className={classes.userName}>
              {state.username || ''}
            </Typography>
          )}
          <Avatar
            className={classes.profileImg}
            src={'https://iloc.myiloc.com' + state.image}
          />

          {state.name && (
            <Grid container item className={classes.textDiv}>
              <Typography variant="h5">{state.phone || ''}</Typography>
              <Typography variant="h5">
                {state.name + ' ' + state.lastname || ''}
              </Typography>
              <Typography variant="caption">
                {(state.status_user === '0' && 'غیر فعال') ||
                  (state.status_user === '1' && 'پریمیوم') ||
                  (state.status_user === '2' && 'لاکچری') ||
                  (state.status_user === '3' && 'سوپر لاکچری') ||
                  (state.status_user === '4' && 'الماس') ||
                  ''}
              </Typography>
            </Grid>
          )}
          <Grid container item className={classes.userInventDiv}>
            <Typography variant="subtitle1" color="secondary">
              تعداد کاربران دعوت شده
            </Typography>
            <Typography variant="h5" color="primary">
              3 / {count}
            </Typography>
          </Grid>
          <Grid container item className={classes.items}>
            <TitleBoxWithArrow
              icon={<StarIcon style={{ fill: 'gray' }} />}
              title="لیست دوستان"
              onClick={() => {
                directTo('/favoriteList')
              }}
            />
            <TitleBoxWithArrow
              icon={<AccountCircleIcon style={{ fill: 'gray' }} />}
              title="اعلان‌های مدیریت"
              onClick={() => {
                directTo('/agentMessages')
              }}
            />
            <TitleBoxWithArrow
              icon={<SettingsIcon style={{ fill: 'gray' }} />}
              title="تنظیمات"
              onClick={() => {
                directTo('/profile/setting')
              }}
            />
          </Grid>
        </Grid>
        <Menu activeIcon="profile" />
      </Grid>
    </Box>
  )
}
