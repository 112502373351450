// @flow
import * as React from 'react'
import useStyles from './SocialBtnsChat.style';
import { Box, Typography } from '@material-ui/core'
import TelegramIcon from '../../../Icons/TelegramIcon'
import InstagramIcon from '@material-ui/icons/Instagram'
export const SocialBtnsChat = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.rootBtn}>
    <Box
      style={{borderBottomRightRadius:'15px'}}
      onClick={() => {
        if (props.telInsta.tel_id)
          window.location.href = 'https://t.me/' + props.telInsta.tel_id
      }}
      className={`${classes.btn} ${classes.telegram}`}
    >
      <Typography className={classes.ml} variant="caption">
        {props.telInsta.tel_id}
      </Typography>
      <TelegramIcon color="#ffffe0" />
    </Box>
    <Box
      style={{borderBottomLeftRadius:'15px'}}
      onClick={() => {
        if (props.telInsta.insta_id)
          window.location.href =
            'https://www.instagram.com/' + props.telInsta.insta_id
      }}
      className={`${classes.btn} ${classes.instagram}`}
    >
      <Typography className={classes.ml} variant="caption">
        {props.telInsta.insta_id}
      </Typography>
      <InstagramIcon />
    </Box>
  </Box>
  )
}