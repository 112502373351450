import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fill: (props) =>
      props.active ? theme.palette.primary.main : theme.palette.icon.primary,
    '& svg': {
      height: (props) => (props.y ? props.y : '35px'),
      width: (props) => (props.x ? props.x : '35px')
    },
    '& path': {
      fill: (props) =>
        props.path && props.active
          ? theme.palette.primary.main
          : props.path
          ? theme.palette.icon.primary
          : ''
    }
  },
  distance: {
    height: (props) => (props.y ? props.y : '50px'),
    width: (props) => (props.x ? props.x : '50px'),
    marginLeft: '-10px',
    '& path': {
      fill: theme.palette.background.lightDark
      // fill: (props) => (props.active ? theme.palette.primary.main : '')
    }
  },
  telegramIcon: {
    height: (props) => (props.y ? props.y : 20),
    width: (props) => (props.x ? props.x : 20),
    '& svg': {
      height: (props) => (props.y ? props.y : 20),
      width: (props) => (props.x ? props.x : 20)
    },
    '& path': {
      fill: (props) =>
        props.color ? props.color : theme.palette.background.lightDark
    }
  },
  filterIcon: {
    '& path': {
      fill: theme.palette.background.lightDark
    }
  },
  label: {
    '& path': {
      fill: theme.palette.background.lightDark
    }
  },
  search: {
    height: (props) => (props.size ? props.size : 35),
    width: (props) => (props.size ? props.size : 35),
    '& svg': {
      height: (props) => (props.size ? props.size : 35),
      width: (props) => (props.size ? props.size : 35)
    },
    '& path:last-child': {
      fill: (props) => props.color && theme.palette.background[props.color]
    }
  },
  profile: {
    height: (props) => (props.size ? props.size : 35),
    width: (props) => (props.size ? props.size : 35),
    '& svg': {
      height: (props) => (props.size ? props.size : 35),
      width: (props) => (props.size ? props.size : 35)
    }
  },
  infinit: {
    height: (props) => (props.size ? props.size : 35),
    width: (props) => (props.size ? props.size : 35),
    '& svg': {
      height: (props) => (props.size ? props.size : 35),
      width: (props) => (props.size ? props.size : 35)
    },
    '& path:last-child': {
      fill: (props) =>
        props.color ? props.color : theme.palette.secondary.main
    }
  }
}))
