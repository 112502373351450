import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomSize: 3,
    borderBottomStyle: 'solid',
    minHeight: 60
  },
  headerTextBox: {
    flex: 1,
    textAlign: 'center',
    marginRight: '-10%'
  },
  headerText: {
    paddingTop: 10,
    fontSize: 30,
    fontFamily: 'fourHand !important',
    fontWeight: 'bold'
  },
  backArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    marginRight: 15
  },
  noChat: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
    width: '100%',
    marginTop: '5rem',
    color: 'gray'
  }
}))
