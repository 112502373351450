import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
    header:{
        height:76,
        position:'fixed',
        top:0,
        maxWidth:'600px',
        zIndex:999,
        backgroundColor:theme.palette.headerFooterChat.main,
        borderBottom:'1px solid transparent',
    },
    username:{
        fontSize:'15px',
        fontWeight: 'bold',
    },
    status:{
        fontSize:'11px',
    },
    ArrowBackIosIcon:{
        marginRight:'20px',
        marginTop:'10px',
        color:theme.palette.primary.main
    },

}));