import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 20px)',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    position: 'relative'
  },
  iconAndText: {
    display: 'flex',
    flex: 1,
    '& p': {
      marginLeft: 10
    }
  },
  cover: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1
  }
}))
