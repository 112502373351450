import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 85,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    direction: 'rtl',
    paddingLeft: 20,
    paddingRight: 10,
    boxShadow: '0 0.4rem 0.5rem -7px rgba(182,182,182,0.5)',
    marginBottom: 3
  },
  avatar: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0'
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 407,
    flexDirection: 'column',
    width: 'calc(100vw - 193px)',
    fontWeight: 'normal',
    paddingRight: '5%'
  },
  avatarAndText: {
    display: 'flex'
  },
  unseenNumNotif: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    marginRight: 20,
    color: 'black'
  }
}))
