import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import Image from './Image'

export default function indexImg(props) {
    return (
        <RigesterContextProvider>
            <Image history={props.history}/>
        </RigesterContextProvider>
    )
}
