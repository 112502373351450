import React, { useState, useEffect, useContext } from 'react'
import { Box, Modal, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import uniqid from 'uniqid'
import useStyles from './AgentMessagesContainer.style'
import agentDark from '../../assets/icons/agentDark.svg'
import MsgTempelate from './MsgTempelate/MsgTempelate'
import ModalContext from '../../contexts/ModalContext'
import { SET_OPEN_MODAL } from '../../constants/ActionTypes'
import { admin } from '../../helpers/fetch'

export default function AgentMessagesContainer(props) {
  const classes = useStyles()
  const history = useHistory()
  const { state, dispatch } = useContext(ModalContext)
  const [list, setList] = useState([])
  useEffect(() => {
    admin().then((response) => {
      setList([...response.data])
    })
  }, [])

  const closeModalHandler = () => {
    dispatch({ type: SET_OPEN_MODAL, payload: { open: false } })
  }
  const unblockHandler = () => {
    console.log('state.data : ', state.data)
  }
  const back = () => {
    history.push('/profile')
  }
  
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box component="img" src={agentDark} mr="5px" />
        <Typography variant="caption">اعلان های مدیریت</Typography>
      </Box>
      <Box className={classes.content}>
        {list &&
          list.map((item) => {
            return <MsgTempelate key={uniqid()} text={item.text} read={true} />
          })}
      </Box>
      <Box className={classes.btnBox}>
        <Button
          color="primary"
          variant="contained"
          onClick={back}
          className={classes.btnBack}
        >
          بازگشت
        </Button>
      </Box>
      <Modal
        className={classes.modal}
        open={state.open}
        onClose={closeModalHandler}
      >
        <Box className={classes.modalBox} onClick={unblockHandler}>
          <Typography variant="subtitle1">رفع مسدودیت</Typography>
        </Box>
      </Modal>
    </Box>
  )
}
