import React from 'react'
import { Box } from '@material-ui/core'
import useStyles from './styles'

export default function Camera(props) {
  const classes = useStyles(props)
  return (
    <Box className={classes.distance} clone>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="104.000000pt"
        height="112.000000pt"
        viewBox="0 0 104.000000 112.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,112.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M269 923 c-52 -32 -71 -90 -48 -150 10 -28 99 -152 109 -153 8 0 85
102 104 137 45 88 -11 184 -106 183 -18 0 -45 -8 -59 -17z m106 -7 c22 -10 41
-27 52 -50 21 -46 9 -90 -49 -169 l-43 -58 -33 38 c-19 21 -45 60 -59 86 -29
58 -25 92 15 129 43 41 65 45 117 24z"
          />
          <path
            d="M269 881 c-19 -19 -29 -40 -29 -59 0 -35 75 -155 93 -149 6 2 29 28
50 57 27 39 37 64 37 92 0 83 -91 119 -151 59z m103 -18 c50 -45 -11 -126 -68
-89 -31 21 -39 52 -20 81 19 29 60 33 88 8z"
          />
          <path
            d="M648 704 c-54 -29 -73 -105 -42 -167 19 -36 96 -137 104 -137 7 0 71
84 99 130 67 111 -48 236 -161 174z m134 -31 c54 -52 42 -118 -39 -211 l-38
-43 -43 58 c-58 79 -69 120 -47 166 32 68 111 82 167 30z"
          />
          <path
            d="M646 664 c-36 -37 -35 -84 5 -145 47 -74 57 -79 86 -44 67 83 79 146
35 188 -38 37 -89 37 -126 1z m108 -26 c22 -31 20 -51 -9 -78 -21 -20 -30 -22
-52 -14 -16 5 -32 21 -37 36 -22 61 62 108 98 56z"
          />
          <path
            d="M320 527 c0 -98 10 -107 114 -107 89 0 116 -10 116 -45 0 -41 33 -65
88 -65 51 0 82 20 82 53 0 24 -21 21 -27 -3 -6 -24 -46 -36 -81 -24 -18 6 -28
20 -35 44 -12 52 -30 60 -123 60 -105 0 -114 8 -114 96 0 36 -4 64 -10 64 -6
0 -10 -31 -10 -73z"
          />
        </g>
      </svg>
    </Box>
  )
}
