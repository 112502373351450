import { makeStyles } from '@material-ui/core'
export default makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    marginTop: 20
  },
  input: {
    backgroundColor: 'transparent',
    color: theme.palette.background.lightDark,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    flex: 1,
    flexWrap: 'nowrap',
    overflow: 'overlay',
    direction: 'rtl',
    border: 'none',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    scrollbarWidth: 'none',
    scrollbarHeight: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    },
    '&:focus': {
      outline: 'none'
    }
  }
}))
