import React, { useContext } from 'react'
import Back from '../../../components/Back/Back'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../../components/Typography/Typography'
import { RigesterContext } from '../../../contexts/RigesterContext'
import useStyle from '../rigester.style'
import GoldButton from '../../../components/Button/GoldButton'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { SETINPUTVALUE } from '../../../constants/ActionTypes'
import Stepper from '../../../components/Stepper/Stepper'
export default function Birthdaydate(props) {
    const classes = useStyle()
    const { state, dispatch } = useContext(RigesterContext)
    const Backs = () => {
        props.history.push({
            pathname: '/rigester/username',
            state: { phone: props.history.location.state.phone,code:props.history.location.state.code,lastname:props.history.location.state.lastname,name:props.history.location.state.name }
        })
    }
    const onChangeText = (val) => {
        dispatch({ type: SETINPUTVALUE, payload: { "birthdaydate": val } })
        
    }
    const continues = () => {
        const birthday = String(state.birthdaydate.year + '/' + state.birthdaydate.month + '/' + state.birthdaydate.day)
        console.log(birthday)
        props.history.push({
            pathname: '/rigester/gender',
            state: { phone: props.history.location.state.phone,code:props.history.location.state.code,lastname:props.history.location.state.lastname,name:props.history.location.state.name, username: props.history.location.state.username, birthday: birthday }
        })
    }
    React.useEffect(() => {
        if(props.history.location.state===undefined){
            props.history.push('/')
        }
    }, [])
    return (
      <Desktopdiv>
        <Stepper complate={'50'} />

        <div>
          <Back onClick={() => Backs()} color="#FED33C" />
          <Typographys
            component="h1"
            text="تاریخ تولد"
            className={classes.h1}
          />
        </div>
        <div className={classes.inputBox}>
          <div className={classes.divinput} style={{ margin: '0px 0px 30px' }}>
            <DatePicker
              onChange={(val) => onChangeText(val)}
              value={state.birthdaydate}
              locale="fa"
              inputPlaceholder="تاریخ تولد"
              shouldHighlightWeekends
            />
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            onClick={() => continues()}
            disabled={state.birthdaydate === '' ? true : false}
            variant={state.birthdaydate === '' ? 'outlined' : 'contained'}
            className={classes.buttons}
            text="ادامه دهید"
          />
        </div>
      </Desktopdiv>
    )
}
