import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import Switch from './Switch/Switch'


export default function InputBox({ icon, label, checked, onChange }) {
 

  const toggleChecked = () => {
    onChange((prev) => !prev)
  }
  return (
    <Box mt="10px!important" mb="10px!important" clone>
      <Grid container item justify="space-between">
        <Box mr="20px" display="flex">
          {icon ? (
            <Box mr="5px" clone>
              {icon}
            </Box>
          ) : null}
          <Typography variant="body1">{label}</Typography>
        </Box>
        <Switch checked={checked} onChange={toggleChecked} color="primary" />
      </Grid>
    </Box>
  )
}
