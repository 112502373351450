import React from 'react'
import { makeStyles, CircularProgress, Box } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 80,
    overflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  loading: {
    width: '100%',
    textAlign: 'center'
  }
}))

export default function MyInfiniteScroll(props) {
  const classes = useStyles()

  return (
    <InfiniteScroll
      className={classes.root}
      dataLength={props.dataLength}
      next={props.next}
      hasMore={true}
      height={props.height}
      loader={
        props.next && props.dataLength&&props.loading ? (
          <Box className={classes.loading}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          ''
        )
      }
    >
      {props.children}
    </InfiniteScroll>
  )
}
