import React, { createContext, useReducer } from 'react'
import reducer from '../redusers/RigesterReducer'


const RigesterContext = createContext({
    state: {
    },
    dispatch: () => { }
})
export { RigesterContext }
export default function RigesterContextProvider(props) {
    const [state, dispatch] = useReducer(reducer, {
       phone:'',
       username:'',
     
       birthdaydate:'',
      
       Gender:'',
       TelegramID:'',
       InstagramID:'',
       Password:'',
       ConfirmPassword:'',

    })

    return (
        <RigesterContext.Provider value={{ state, dispatch }}>
            {props.children}
        </RigesterContext.Provider>
    )
}
