const { makeStyles } = require('@material-ui/core')

const useStyle = makeStyles((theme) => ({
  root: {
  
    [theme.breakpoints.up('xs')]: {
      height: 456,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flexDirection: 'column'
    }
  },
  buttons: {
    borderRadius: '50px',
    width: '70%'
  },
  Input: {
    [theme.breakpoints.up('xs')]: {
      marginTop: '10px',
      width: '100%',
      height: 65
      ,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  },
  InputText: {
    marginLeft: 50,
    width: '75%',
    '& label': {
      color: 'black'
    }
  },
  ButtonDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '0 auto',
      width: '100%',
    marginBottom:10,
    marginTop:50,
  },
  button: {
    borderRadius: '50px',
    width: '70%'
  }
}))
export default useStyle
