import React, { useState } from 'react'
import Back from '../../components/Back/Back'
import Desktopdiv from '../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../components/Typography/Typography'

import GoldButton from '../../components/Button/GoldButton'
import useStyle from '../Rigester/rigester.style'

import { NotificationManager } from 'react-notifications'
import ReactCodeInput from 'react-code-input'
import { resetCode } from '../../helpers/fetch'

export default function CodeSms(props) {
  const classes = useStyle()
  const [code, setcode] = React.useState('')
  const Backs = () => {
    props.history.push('/reset/phone')
  }
  React.useEffect(() => {
    if (props.history.location.state === undefined) {
      props.history.push('/')
    }
  }, [])
  const onChangeText = (e) => {
    setcode(e)
  }
  const continues = () => {
    resetCode(props.history.location.state.phone, code).then((response) => {
      if (response.data === true) {
        props.history.push({
          pathname: '/reset/confirmation',
          state: { phone: props.history.location.state.phone, token: code }
        })
      }
    })
  }

  const inputCodeStyle = {
    margin: '4px',
    MozAppearance: 'textfield',
    width: '30px',
    borderRadius: '3px',
    fontSize: '20px',
    height: '30px',
    padding: '7px',
    textAlign: 'center',
    border: 'none',
    borderBottom: '3px solid #FED33C'
  }

  return (
    <Desktopdiv>
      <div>
        <Back onClick={() => Backs()} color="#FED33C" />
        <Typographys
          component="h1"
          text="کد تایید را وارد کنید"
          className={classes.h1}
        />
      </div>
      <div className={classes.inputBox}>
        <div className={classes.divinput}>
          <ReactCodeInput
            type="text"
            value={code}
            fields={6}
            onChange={(e) => onChangeText(e)}
            className={classes.inputCode}
            inputStyle={inputCodeStyle}
            inputMode="numeric"
          />
        </div>
      </div>
      <div className={classes.btncontinue}>
        <GoldButton
          disabled={code.length !== 6 ? true : false}
          onClick={() => continues()}
          variant={code.length !== 6 ? 'outlined' : 'contained'}
          className={classes.buttons}
          text="ادامه دهید"
        />
      </div>
    </Desktopdiv>
  )
}
