import React, { createContext, useState } from 'react'
import { ThemeProvider } from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import lightTheme from '../../theme/theme'
import darkTheme from '../../theme/theme_dark'

const ThemeContext = createContext({
  changeThemeTo: () => {}
})
export { ThemeContext }

export default function Index({ children }) {
  let initialNewTheme =
    (localStorage.getItem('status_user') === '0' ||
      localStorage.getItem('status_user') === '1') &&
    localStorage.getItem('darkMode') === '1'
      ? darkTheme
      : lightTheme
  const [ newTheme, setNewTheme ] = useState( initialNewTheme )

  const changeThemeTo = (theme) => {
    
    switch (theme) {
      case 'active':
        setNewTheme(lightTheme)
        localStorage.setItem('darkMode', '0')
        break
      case 'luxury':
        break
      case 'superLuxury':
        break
      case 'darkMode':
        setNewTheme(darkTheme)
        localStorage.setItem('darkMode', '1')
        break
      default:
        break
    }
  }
  let theme = createMuiTheme()
  theme = createMuiTheme(newTheme)
  theme = responsiveFontSizes(theme)
  return (
    <ThemeContext.Provider value={{ changeThemeTo }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}
