import React from 'react'
import { Box, Typography } from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram'
import TelegramIcon from '../Icons/TelegramIcon'
import useStyles from './SocialBtnsBox.style'

export default function SocialBtnsBox({ userInfo }) {
  const c = useStyles()

  return (
    <Box className={c.root}>
      <Box
        onClick={() => {
          if (userInfo.tel_id)
            window.location.href = 'https://t.me/' + userInfo.tel_id
        }}
        className={`${c.btn} ${c.telegtam}`}
      >
        <Typography className={c.ml} variant="caption">
          {userInfo.tel_id}
        </Typography>
        <TelegramIcon color="#ffffe0" />
      </Box>
      <Box
        onClick={() => {
          if (userInfo.insta_id)
            window.location.href =
              'https://www.instagram.com/' + userInfo.insta_id
        }}
        className={`${c.btn} ${c.instagram}`}
      >
        <Typography className={c.ml} variant="caption">
          {userInfo.insta_id}
        </Typography>
        <InstagramIcon />
      </Box>
    </Box>
  )
}
