import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'radial-gradient(rgba(176,176,176,1) 0%, rgba(88,88,88,1) 70%);',
    padding: 15
  },

  content: {
    padding: '0 30px ',
    height: 'calc(100vh - 155px)',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    overflow: 'auto',
    paddingBottom: 80,
    overflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '&>div:last-child': {
      borderBottom: 'none'
    }
  },
  btnBox: {
    width: '100%',
    padding: '15px 30px 70px'
  },
  btnBack: {
    background: theme.palette.button.primary,
    color: theme.palette.text.secondary,
    height: 50,
    borderRadius: 30,
    width: '100%'
  },

  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalGrid: {
    background: '#fff',
    borderRadius: 10,
    padding: 15,
    width: '60%',
    textAlign: 'center',
    outline: 'none'
  }
}))
