import React, { useState, useContext } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import DraverContext from '../../contexts/DraverContext'
import UnReadRequest from '../../contexts/UnReadRequest'
import { SET_OPEN_DRAWER, BUY_DRAWER } from '../../constants/ActionTypes'
import ItemMenu from './ItemMenu/ItemMenu'
import useStyles from './menu.style'
import PersonIcon from '@material-ui/icons/Person'
import FavoriteIcon from '@material-ui/icons/Favorite'
import SearchIcon from '@material-ui/icons/Search'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import HomeIcon from '@material-ui/icons/Home'
import { isNewFriendRequest } from '../../helpers/fetch'

export default function Menu({ activeIcon, position }) {
  const classes = useStyles({ position })
  const history = useHistory()
  const { dispatch } = useContext(DraverContext)
  const { unReadRequest } = useContext(UnReadRequest)
  const [open, setOpen] = useState(false)
  const [isNewRequest, setIsNewRequest] = useState(false)

  isNewFriendRequest().then((data) => {
    setIsNewRequest(data.data)
  })
  const activeItem = {
    profile: activeIcon === 'profile' ? true : false,
    message: activeIcon === 'message' ? true : false,
    cart: activeIcon === 'cart' ? true : false,
    search: activeIcon === 'search' ? true : false,
    home: activeIcon === 'home' ? true : false
  }

  const close = () => {
    setOpen(false)
  }
  const onClicHandler = (e) => {
    let target = e.target.getAttribute('name')
    switch (target) {
      case 'profile':
        history.push('/profile')
        break

      case 'message':
        history.push('/message')
        break

      case 'cart':
        // setOpen(true)
        dispatch({
          type: SET_OPEN_DRAWER,
          payload: { drawer: BUY_DRAWER, open: true }
        })
        break

      case 'search':
        history.push('/search')
        break

      case 'home':
        history.push('/home')
        break

      // default:
      //   history.push('/home')
      //   break
    }
  }

  return (
    <Grid
      container
      item
      className={classes.root}
      onClick={(e) => {
        onClicHandler(e)
      }}
    >
      <ItemMenu
        id="profile"
        name="profile"
        icon={
          <PersonIcon
            active={activeItem.profile}
            style={{ fontSize: '45px' }}
          />
        }
        active={activeItem.profile}
      />
      <ItemMenu
        id="message"
        name="message"
        icon={
          <FavoriteIcon
            active={activeItem.message}
            style={{ fontSize: '40px' }}
          />
        }
        active={activeItem.message}
        unReadRequest={unReadRequest}
        isNewRequest={isNewRequest}
      />
      <ItemMenu
        id="cart"
        name="cart"
        icon={
          <LocalMallIcon
            active={activeItem.cart}
            style={{ fontSize: '40px' }}
          />
        }
        active={activeItem.cart}
      />
      <ItemMenu
        id="search"
        name="search"
        icon={
          <SearchIcon active={activeItem.search} style={{ fontSize: '40px' }} />
        }
        active={activeItem.search}
      />
      <ItemMenu
        id="home"
        name="home"
        icon={
          <HomeIcon active={activeItem.home} style={{ fontSize: '40px' }} />
        }
        active={activeItem.home}
      />
    </Grid>
  )
}
