import React, { useState } from 'react'
import Back from '../../components/Back/Back'
import Input from '../../components/Input/Input'
import Typographys from '../../components/Typography/Typography'
import GoldButton from '../../components/Button/GoldButton'
import useStyle from '../Rigester/rigester.style'
import Desktopdiv from '../../components/DesktopDiv/Desktopdiv'
import {NotificationManager} from 'react-notifications';
import { resetpassphone } from '../../helpers/fetch'
// import 'react-phone-number-input/style.css'
export default function Phone(props) {
    const [phone,setphone]=useState('')
    const classes = useStyle()
    const Backs = () => {
        props.history.push('/')
    }
    const continues = () => {
        if (phone !== "") {
            if (phone.length === 11) {
               resetpassphone(phone).then(response=>{
                props.history.push({
                    pathname: '/reset/Code',
                    state: { phone: phone }
                  })
               })
                
            }
            else {
                NotificationManager.error('شماره تلفن اشتباه است')
            }
        }
        else {
            NotificationManager.error('شماره تلفن را وارد کنید')
        }
    }
    const onChangeText = (e) => {
        setphone(e.target.value)
    }

    return (
      <Desktopdiv>
        <div>
          <Back onClick={() => Backs()} />

          <Typographys
            component="h1"
            text="شماره خود را وارد کنید"
            className={classes.h1}
          />
        </div>
        <div className={classes.inputBox}>
          <div className={classes.divinput}>
            <Input
              className={classes.input}
              name="phone"
              onChange={(e) => onChangeText(e)}
            />
          </div>
        </div>
        <div className={classes.btncontinue}>
          <GoldButton
            disabled={phone === '' ? true : false}
            onClick={() => continues()}
            variant={phone === '' ? 'outlined' : 'contained'}
            className={classes.buttons}
            text="ادامه دهید"
          />
        </div>
      </Desktopdiv>
    )
}
