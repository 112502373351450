import axios from 'axios'
import { getCookie, setCookie } from '../components/CookieManger/CookieManger'
import config from '../config'
import { NotificationManager } from 'react-notifications'

const client = axios.create({
  baseURL: config.app.baseURL
})
client.interceptors.request.use(function (config) {
  const token = getCookie('token')
  config.headers.Authorization = `Bearer ${token}`
  return config
})
const login = async (username, password) => {
  try {
    const data = await client.post('/login', {
      username: username,
      password: password
    })
    setCookie('token', data.data.data.user.api_token, 1)
    localStorage.setItem('status_user', data.data.data.user.status_user)
    localStorage.setItem('id', data.data.data.user.id)
    return data.data
  } catch (e) {
    NotificationManager.error('نام کاربری یا رمز عبور اشتباه است')
  }
}
const location = async (pages) => {
  try {
    var data
    if (pages !== undefined) {
      data = await client.get('/location' + pages)
    } else {
      data = await client.get('/location')
    }
    return data.data
  } catch (E) {
    NotificationManager.error('در ارتباط با سرور خطایی رخ داده است')
  }
}
const rigester = async (phone) => {
  const phonesend = await client.post('/register', {
    phone: phone
  })
  return phonesend
}
const rigesterCode = async (code, phone) => {
  const data = await client.post('/register/code', {
    phone: code,
    code: phone
  })
  return data.data
}
const informationUser = async () => {
  const data = await client.get('/curentuser')
  return data.data
}
const requestfriend = async (txt, id) => {
  const data = await client.post('/requests/friendrequest', {
    request_text: txt,
    id: id
  })

  return data.data
}
const search = async (search, page) => {
  const data = await client.get(`/search?search=${search}&page=${page || '1'}`)
  return data.data
}
const locationsave = async (lat, lng) => {
  const data=await client.put('/location/save', {
    latitude: lat,
    longitude: lng
  })
  return data.data
}

const showsendBox = async (page, filter) => {
  const data = await client.post('/requests/filter?page=' + page, {
    filter: filter
  })
  return data.data
}

const received = async (page, filter) => {
  const data = await client.post('/requests/received?page=' + page, {
    filter: filter
  })
  return data.data
}
const readMessage = async (id) => {
  const data = await client.post('/requests/read', {
    id
  })
  return data.data
}
const accept = async (id) => {
  await client.put('/requests/accept', {
    id: id
  })
}
const reject = async (id) => {
  await client.put('/requests/reject', {
    id: id
  })
}
const isNewFriendRequest = async (id) => {
  const data = await client.post('/requests/newfriendrequest', {
    id
  })
  return data.data
}
const rigesters = async (obj) => {
  const data = await client.post('/register/create', obj)
  return data.data
}
const changePassword = async (obj) => {
  const data = await client.post('/changepassword', obj)
  return data.data
}
const profilesub = async (fd) => {
  const data = await client.post('/editprofile', fd)
  return data.data
}

const blocklist = async () => {
  const data = await client.get('/blocklist')
  return data.data.data
}
const unblocklist = async (id) => {
  const data = await client.delete('/unblockuser', {
    id: id
  })
  NotificationManager.success(data.data.data)
}
const favoritelist = async (page) => {
  const data = await client.get('/favoritelist?page=' + page)
  return data.data
}
const unFavoritelist = async (id) => {
  console.log('userInfo.idr : ', id)
  const data = await client.post('/unfavoriteuser', {
    id: id
  })
  return data.data
}
const deleteimg = async () => {
  const data = await client.post('/image/delete')
  NotificationManager.success(data.data.data)
}
const editimg = async (fd) => {
  const data = await client.post('/editprofile/image', fd)
  NotificationManager.success(data.data.data)
  return true
}
const resetpassphone = async (phone) => {
  const data = await client.post('/password/create', {
    phone: phone
  })
  return data.data
}
const resetCode = async (phone, token) => {
  const data = await client.post('/password/find/token', {
    phone: phone,
    token: token
  })
  return data.data
}
const reset = async (phone, token, password, password_confirmation) => {
  await client.post('/password/reset', {
    phone: phone,
    token: token,
    password: password,
    password_confirmation: password_confirmation
  })
}
const admin = async () => {
  const data = await client.get('/admin/message')
  return data.data
}
const addfvoritelist = async (id) => {
  const data = await client.post('/favoriteuser', {
    id: id
  })
  return data.data;
}
const usernamecheck = async (fd) => {
  const data = await client.post('/username', fd)
  return data.data
}
const blockUsers = async (id) => {
  const data = await client.post('/blockuser', {
    id: id
  })
  NotificationManager.success(data.data.data)
}
const UsernameFind = async (username) => {
  const data = await client.post('/register/username', {
    username: username
  })
  return data.data
}
const report = async (id, type) => {
  const data = await client.post('/report', {
    type: type,
    id: id
  })
  NotificationManager.success(data.data.data)
}
const zarinpal = async (id) => {
  const data = await client.post('/payment', {
    id: id
  })
  return data.data
}
const remain = async () => {
  const data = await client.get('/remain')
  return data.data
}
const getChat = async (id) => {
  const data = await client.post('/get_chats', {
    id: id
  })
  return data.data
}

const tel_insta_id = async (id) => {
  const data = await client.post('/tel_insta_id', {
    id: id
  })
  return data.data
}

// const getUnReadRequest = async (id) => {
//   const data = await client.get('/', {
//     unReadRequest: unReadRequest,
//   })
// }

const getChatList = async () => {
  var data = await client.get('/get_chatlist')
  return data.data
}

const afterseeads = async (adsid, id) => {
  const data = await client.post('/afterseeads', {
    adsid: adsid,
    id: id
  })
  NotificationManager.success('تبلیغ دیده شد')
  return data.data
}

const inviteCode = async () => {
  const data = await client.post('/invitecode')
  return data
}

export {
  readMessage,
  remain,
  zarinpal,
  report,
  UsernameFind,
  blockUsers,
  usernamecheck,
  addfvoritelist,
  admin,
  reset,
  resetCode,
  resetpassphone,
  editimg,
  deleteimg,
  unblocklist,
  unFavoritelist,
  favoritelist,
  blocklist,
  rigesters,
  login,
  location,
  rigester,
  rigesterCode,
  informationUser,
  search,
  requestfriend,
  locationsave,
  showsendBox,
  received,
  accept,
  reject,
  changePassword,
  profilesub,
  tel_insta_id,
  getChat,
  getChatList,
  afterseeads,
  inviteCode,
  isNewFriendRequest
}
