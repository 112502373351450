import { dark } from './palette'

export default {
  direction: 'rtl',
  palette: dark,
  spacing: 2,
  typography: {
    allVariants: {
      fontFamily: 'iranyekan'
    },
    h1: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: dark.text.primary
    },
    h5: {
      fontSize: 16
    },
    body1: {
      fontSize: '14px'
    },
    body2: {
      fontSize: '22px'
    },

    subtitle1: {
      fontSize: '10px',
      fontWeight: 'bold'
    },
    caption: {
      fontSize: '14px',
      fontWeight: 'bold'
    },
    button: {
      fontSize: '14px'
    },
    overline: {
      fontSize: 13,
      fontWeight: 500,
      textDecoration: 'line-through',
      color: 'red'
    }
  }
}
