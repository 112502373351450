import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: (ps) => ps && 800,
    // fontWeight: (ps) => !ps.read && 800,
    overflow: 'auto',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    maxWidth: 407,
    width: 'calc(100vw - 193px)',
    scrollbarHeight: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    }
  },
  userName: {
    fontSize: 16
  },
  subTitle: {
    fontSize: 13
  }
}))

export default function TextOfCart(props) {
  const fontWeightStyle = Boolean(props.read === 0 || props.unseennumbers)
  const c = useStyles(fontWeightStyle)
  return (
    <React.Fragment>
      <Typography
        className={`${c.text} ${c.userName}`}
        variant="caption"
        color="secondary"
      >
        {props.title}
      </Typography>
      <Typography
        className={`${c.text} ${c.subTitle}`}
        variant="subtitle1"
        color="secondary"
      >
        {props.subtitle}
      </Typography>
    </React.Fragment>
  )
}
