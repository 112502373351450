import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    // width: '100vw',
    height: '100vh',
    flexDirection: 'column'
  },
  cantent: {
    width: '100%',
    height: (sp) =>
      sp.boxName === 'SEND' ? 'calc(100% - 100px)' : 'calc(100% - 50px)',
    paddingBottom: 80,
    overflow: 'auto',
    overflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))
