import React, { createContext, useReducer } from 'react'
import reducer from '../redusers/MsgScreenReducer'

const MsgScreenContext = createContext({
  state: {
    sendMsgList: [],
    receivedMsgList: [],
    boxName: '',
    sortList: 2,
    filterItem: {},
    filterStatus: '',
    openModal: false,
    trigger: false
  },
  dispatch: () => {}
})
export { MsgScreenContext }
export default function MsgScreenContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    sendMsgList: [],
    receivedMsgList: [],
    filterList: ['2', '1', '0'],
    sortList: 2,
    boxName: 'SEND',
    filterItem: {
      all: 'همه',
      accept: 'پذیرفته شده‌ها',
      reject: 'ردشده‌ها',
      waiting: 'درانتظار پاسخ'
    },
    filterStatus: 'همه',
    openModal: false,
    trigger: false
  })

  return (
    <MsgScreenContext.Provider value={{ state, dispatch }}>
      {children}
    </MsgScreenContext.Provider>
  )
}
