import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import HomeScreen from '../../screens/Home/HomeScreen'
import { getCookie } from '../CookieManger/CookieManger'


export default function PraivetRoute({ path, component, exact }) {
  let props = { path, component, exact }
  const token = getCookie('token')
 
  return token !== '' ? <Route {...props} /> : <Redirect to="/login" />
}
