import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import palette from '../../theme/palette'
import LanguageIcon from '@material-ui/icons/Language'
import InstagramIcon from '@material-ui/icons/Instagram'
import TelegramIcon from '../Icons/TelegramIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 5,
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    display: 'flex',
    color: theme.palette.socialNetworkLinks,
    textDecoration: 'none',
    fontWeight: 'bold',
    alignItems: 'center'
  }
}))

export default function SocialNetworkLinks(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <a href="#" className={classes.link}>
        <Typography variant="h5">myiloc</Typography>
        <TelegramIcon />
      </a>
      <a href="#" className={classes.link}>
        <Typography variant="h5">myiloc.com</Typography>
        <LanguageIcon />
      </a>
      <a href="#" className={classes.link}>
        <Typography variant="h5">iloc_app</Typography>
        <InstagramIcon />
      </a>
    </div>
  )
}
