
import * as React from 'react';
import {Grid, Typography} from "@material-ui/core";
import useStyles from "./DateChat.style";


export  const DateChat = (props) => {
    const classes = useStyles();
    return (
        <Grid container alignContent={"center"} justify={'center'} style={{opacity:1,}}>
            <Grid item style={{background:'transparent',borderRadius:'.75rem',}}>
                <Typography style={{padding:'3px'}}>
                    {props.date}
                </Typography>
            </Grid>
        </Grid>
    );
};