import React, { useState, useContext, useEffect } from 'react'
import { Drawer, Box } from '@material-ui/core'
import ChangeProfilPhotoDrawer from './DrawerCotents/ChangeProfilPhotoDrawer/ChangeProfilPhotoDrawer'
import MessageDrawer from './DrawerCotents/MessageDrawer'
import PayDrawer from './DrawerCotents/PayDrawer'
import ReqestDrawer from './DrawerCotents/ReqestDrawer/ReqestDrawer'
import AlertEndDrawer from './DrawerCotents/AlertEndDrawer/AlertEndDrawer'
import BuyDrawer from './DrawerCotents/BuyDrawer/BuyDrawer'
import KnowMoreDrawer from './DrawerCotents/KnowMoreDrawer/KnowMoreDrawer'
import DraverContext from '../../contexts/DraverContext'

import { inviteCode } from '../../helpers/fetch'

import {
  SET_OPEN_DRAWER,
  CHANGE_PROFILE_PHOTO_DRAWER,
  MESSAGE_DRAWER,
  PAY_DRAWER,
  REQEST_DRAWER,
  ALERT_END_DRAWER,
  BUY_DRAWER,
  KNOW_MORE_DRAWER
} from '../../constants/ActionTypes'

import useStyles from './index.style'

export default function Index() {
  const classes = useStyles()
  const { state, dispatch } = useContext(DraverContext)
  const [addUser, setAddUser] = useState(true)
  useEffect(() => {
    inviteCode().then((response) => {
      if (response.data.data) {
        setAddUser(true)
      } else {
        setAddUser(false)
      }
    })
  }, [])
  let drawerContent = ''
  switch (state.drawer) {
    case CHANGE_PROFILE_PHOTO_DRAWER:
      drawerContent = <ChangeProfilPhotoDrawer />
      break
    case MESSAGE_DRAWER:
      drawerContent = <MessageDrawer />
      break
    case BUY_DRAWER:
      drawerContent = <BuyDrawer addUser={addUser} />
      break
    case PAY_DRAWER:
      drawerContent = <PayDrawer type={state.type} addUser={addUser} />
      break
    case REQEST_DRAWER:
      drawerContent = <ReqestDrawer {...state.data} />
      break
    case ALERT_END_DRAWER:
      drawerContent = <AlertEndDrawer type={state.type} />
      break
    case KNOW_MORE_DRAWER:
      drawerContent = <KnowMoreDrawer type={state.type} />
      break

    default:
      break
  }
  const close = () => {
    dispatch({ type: SET_OPEN_DRAWER, payload: { open: false } })
  }
  return (
    <Drawer
      anchor="bottom"
      open={state.open}
      onClose={close}
      classes={{ root: classes.root, paper: classes.paper }}
    >
      {drawerContent}
    </Drawer>
  )
}
