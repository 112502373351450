import React, { useEffect, useState } from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import useStyles from './InvitationLinkContainer.style'
import { informationUser } from '../../helpers/fetch'

export default function InvitationLinkContainer(props) {
  const c = useStyles()
  const history = useHistory()
  const [ state, setState ] = useState('')
  useEffect( () => { 
    informationUser().then( response => {
      setState( response.user.referrer_token )
       console.log('response.user.referrer_token : ', response.user.referrer_token)
    })
  },[])
  const back = () => {
    history.push('home')
  }
  const copyCode = (e) => {
    console.log(e.target.innerHTML)
    let copyText = document.getElementById('InputCode')
    let alert = document.getElementById('alertBox')
    console.log('copyText : ', copyText)
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')

    alert.style.display = 'block'

    setTimeout(() => {
      alert.style.opacity = 1
    }, 0)
    setTimeout(() => {
      alert.style.opacity = 0
    }, 1000)
    setTimeout(() => {
      alert.style.display = 'none'
    }, 1500)
  }
  return (
    <div className={`${c.felxCol} ${c.root}`}>
      <Typography variant="h1" className={`${c.textTop}`}>
        برای دعوت دوستانتان لینک دعوت زیر را برای آنها ارسال نمایید. برای کپی
        لینک دعوت آن را لمس کنید.
      </Typography>

      <div className={`${c.felxCol} ${c.centerDiv}`}>
        <h1 className={c.textActive}> لینک دعوت</h1>
        <input
          className={c.inputActive}
          type="text"
          id="InputCode"
          value={`https://app.myiloc.com/rigester/phone?invitationLink=${state}`}
          readonly
          onClick={copyCode}
        />
        <div className={c.alertBox} id="alertBox">
          کد کپی شد
        </div>
      </div>
      <div className={`${c.felxCol} ${c.bottomDiv}`}>
        <Button
          variant="contained"
          color="primary"
          className={c.btnBack}
          onClick={back}
        >
          باز گشت به صفحه خانه
        </Button>
      </div>
    </div>
  )
}
