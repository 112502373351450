import React, { useRef, useContext } from 'react'
import Resizer from 'react-image-file-resizer'
import { Box, Typography } from '@material-ui/core'
import useStyles from './ChangeProfilPhotoDrawer.style'
import { SET_OPEN_DRAWER,SET_OPEN_MODAL } from '../../../../constants/ActionTypes'
import DraverContext from '../../../../contexts/DraverContext'
import ModalContext from '../../../../contexts/ModalContext'
import { deleteimg, editimg } from '../../../../helpers/fetch'

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import PhotoIcon from '@material-ui/icons/Photo'
import CancelIcon from '@material-ui/icons/Cancel'

export default function ChangeProfilPhotoDrawer(props) {
  const classes = useStyles()
  const {  dispatch } = useContext(DraverContext)
  const { dispatch:dispatchModal } = useContext(ModalContext)
  const inputRef = useRef(null)
  const inputCaptureRef = useRef(null)
  const onClickHandler = (e) => {
    switch (e.target.id) {
      case 'camera':
        inputCaptureRef.current.click()
        break
      case 'gallery':
        inputRef.current.click()
        break
      case 'delete':
        deleteimg()
        break

      default:
        break
    }
  }

  const onChange = ( e ) => {
    const fd = new FormData()
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { open: false }
    })
    dispatchModal({
      type: SET_OPEN_MODAL,
      payload: { open: true }
    })
    fd.append('image', e.target.files[0])
    editimg( fd ).then( ( res ) => {
      dispatchModal({
        type: SET_OPEN_MODAL,
        payload: { open: false }
      })
    })
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Typography variant="h5"> تغییر عکس پروفایل</Typography>
      </Box>
     
      <Box className={classes.itemBox} onClick={onClickHandler}>
        <Box className={classes.item}>
          <Box className={classes.cover} id="camera" />
          <Box className={classes.icon}>
            <PhotoCameraIcon/>
          </Box>
          <Typography variant="body1">گرفتن عکس با دوربین</Typography>
        </Box>
        <Box className={classes.item}>
          <Box className={classes.cover} id="gallery" />
          <Box className={classes.icon}>
            <PhotoIcon/>
          </Box>
          <Typography variant="body1"> انتخاب عکس از گالری</Typography>
        </Box>
        <Box className={classes.item}>
          <Box className={classes.cover} id="delete" />
          <Box className={classes.icon}>
            <CancelIcon/>
          </Box>
          <Typography variant="body1">حذف عکس</Typography>
        </Box>
      </Box>

      <Box
        component="input"
        type="file"
        ref={inputRef}
        accept="image/*"
        className={classes.inputFile}
        onChange={onChange}
      />
      <input
        className={classes.inputFile}
        type="file"
        ref={inputCaptureRef}
        capture="user"
        accept="image/*"
        onChange={onChange}
      />
    </Box>
  )
}
