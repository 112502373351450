
import * as React from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import useStyles from "./FirstBoxText.style";


export const FirstBoxText = (props) => {
    const classes = useStyles();
    const text="چت ای لوک"
    return (
        // <Grid item className={classes.main} container alignItems={'center'} justify={'center'}>
            <Paper className={classes.box}>
                <Typography className={classes.textBox} variant='body1' component='p'>
                    {props.text?props.text:text}
                </Typography>
            </Paper>
        // </Grid>
    );
};