import React, { useState } from 'react'
import { Steps } from 'intro.js-react'
import 'intro.js/introjs.css'
import 'intro.js/minified/introjs-rtl.min.css'
import useStyles from './IntroTour.style'

export default function IntroTour({ enabled, onExit }) {
  const classes = useStyles()
 
 
  const steps = [
    {
      element: '#robatIcon',
      intro:
        'با لمس آیکون ربات، شما کد فعالسازی خود را دریافت و با کپی کردن آن در ربات، ربات چت ناشناس iLoc‌ برای شما فعال می شود.'
    },
    {
      element: '#chatIcon',
      intro:
        'از طریق این گزینه میتوانید به صفحه چت بروید'
    },
    {
      element: '#home',
      intro: 'صفحه اصلی اپلیکیشن'
    },
    {
      element: '#search',
      intro: 'نام یا id کاربر مورد نظر خود را در این قسمت سرچ  کنید.'
    },
    {
      element: '#cart',
      intro: 'در این قسمت اکانت مورد نظر خود را خریداری فرمایید.'
    },
    {
      element: '#message',
      intro:
        'برای مشاهده درخواست‌های دریافتی و ارسالی خود ، همچنین وضعیت آنها لمس کنید.'
    },
    {
      element: '#profile',
      intro:
        'در این قسمت تمامی تنظیمات پروفایل خود از جمله بیو، عکس، اسم و ... و همجنین تمامی تنظیمات برنامه در دسترس می باشد.'
    }
  ]
 
  const tourOption = {
    nextLabel: 'بعدی',
    prevLabel: 'قبلی',
    skipLabel: 'بستن',
    doneLabel: 'بستن',
    tooltipClass: classes.tooltip,
    highlightClass: classes.highlight,
    showStepNumbers: false
  }
  return (
    <Steps
      enabled={enabled}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      options={tourOption}
    />
  )
}
