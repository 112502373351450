import React from 'react'
import BlackListContainer from '../../container/BlackListContainer/BlackListContainer'
import {ModalContextProvider} from '../../contexts/ModalContext'

export default function BlackListScreen(props) {
  return (
    <ModalContextProvider>
      <BlackListContainer />
    </ModalContextProvider>
  )
  
}
