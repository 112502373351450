import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import useStyles from './InputBox.style'


export default function InputBox({ icon, label, value, onChange,dis }) {
  const classes = useStyles()

  return (
    <Grid container item className={classes.root}>
      <Box mr="20px" display="flex">
        {icon ? (
          <Box mr="5px">
            {icon}
          </Box>
        ) : null}
        <Typography variant="body1">{label}</Typography>
      </Box>
      <input
      disabled={dis||false}
        className={classes.input}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={value}
        
      />
    </Grid>
  )
}
