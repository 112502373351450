import React from 'react'
import {Box} from '@material-ui/core'
import MsgScreenContextProvider from '../../contexts/MsgScreenContext'
import MessageContainer from '../../container/MessageContainer/MessageContainer'

export default function MessageScreen() {
  return (
   
      <MsgScreenContextProvider>
        <MessageContainer />
      </MsgScreenContextProvider>
    
  )
}
