import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 85,
    display: 'flex',
    borderBottom: (ps) => ps.brderBottom && `1px solid #B0B0B0`
  },
  avatar: {
    height: '100%',
    width: 85,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    height: '100%',
    flex: '1.8',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  option: {
    height: '100%',
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: 10
  },
  menu: {
    color: '#B0B0B0'
  }
}))
