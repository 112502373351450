import {  makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    height: 65,
    borderRadius: '24px 24px 0 0',
    bottom: 0,
    backgroundColor: theme.palette.background.main || '#fff',
    position: (ps) => ps.position || 'fixed',
    maxWidth: '600px',
    zIndex: 2
  }
}))
