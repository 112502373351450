import React, { useContext } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import BtnModal from '../../../BtnModal/BtnModal'
import useStyles from './AlertEndDrawer.style'
import DraverContext from '../../../../contexts/DraverContext'
import { SET_OPEN_DRAWER, BUY_DRAWER } from '../../../../constants/ActionTypes'


export default function AlertEndDrawer({ type }) {
  const c = useStyles()
  const { state, dispatch } = useContext( DraverContext )
 
  let name=state.data.name, accountId, days=state.data.days
  switch (state.data.account_id) {
    case 2:
      accountId='لاکچری'
      break;
    case 3:
      accountId='سوپر لاکچری'
      break;
    case 4:
      accountId='الماس'
      break;
  
    default:
      break;
  }
  let message = ''
  switch (type) {
    case 'account':
      message = `کاربر ${name} ${days} روز از حساب ${accountId} شما باقی مانده لطفا حساب خود را شارژ کنید `
      break
    case 'reqest':
      message = '  تعداد درخواست دوستی شما به پایان رسیده است'
      break

    default:
      break
  }
  const goToPayPage = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { open: false }
    })
    setTimeout(() => {
      dispatch({
        type: SET_OPEN_DRAWER,
        payload: { drawer: BUY_DRAWER, open: true }
      })
    }, 200)
  }
  const close = () => {
    dispatch({
      type: SET_OPEN_DRAWER,
      payload: { open: false }
    })
  }
  return (
    <Box className={c.root}>
      <Box className={c.text}>
        <Typography variant="h5">{message}</Typography>
      </Box>
      <Box className={c.boxText} onClick={goToPayPage}>
        <Typography variant="caption">رفتن به صفحه پرداخت</Typography>
      </Box>
      <BtnModal variant="contained" color="primary" onClick={close}>
        بستن
      </BtnModal>
    </Box>
  )
}
