
import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
root: {
    marginLeft: 25,
    marginTop:'2px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
},
avatar: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'relative'
},
avatarImg: {
    width: '40px',
    height: '40px'
},

border: {
    position: 'absolute',
    zIndex: 1,
    width:'inherit',
    height:'inherit'
},
blueTik: {
    width: 10,
    position: 'absolute',
    zIndex: 3,
    bottom: -2
}
}))
