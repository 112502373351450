import { withStyles, Button } from '@material-ui/core'


export default withStyles((theme) => ({
  root: {
    width: '100%',
    height: 50,
    borderRadius: ' 0 0 20px 20px',
    color: theme.palette.text.secondary
  }
}))(Button)
