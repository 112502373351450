import React from 'react'
import { Box } from '@material-ui/core'
import useStyles from './HomeContent.style'

export default function HomeContent() {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <iframe className={classes.iframe} src="https://myiloc.com/iframe/" title="W3Schools Free Online Web Tutorials"></iframe>
    </Box>
  )
}
