import React from 'react'
import uniqid from 'uniqid'
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@material-ui/core'

import useStyles from './CheckBoxList.style'

export default function CheckBoxList({ title, checked, checkList, onChange }) {
  const classes = useStyles()

  const handleChange = (event, name) => {
    onChange({ [name]: event.target.checked })
  }
  return (
    <Box my="10px !important" clone>
      <Grid container item direction="column">
        <Typography variant="caption">{title}</Typography>
        <Box pl="5px" className={classes.checkList} clone>
          <FormGroup>
            {checkList.map((item, index) => {
              let hide =
                +localStorage.getItem('status_user') === 0
                  ? +localStorage.getItem('status_user') < index
                  : +localStorage.getItem('status_user') - 1 < index

              return (
                !hide && (
                  <FormControlLabel
                    key={uniqid()}
                    control={
                      <Checkbox
                        classes={{ root: classes.checkBoxRoot }}
                        checked={checked[item.name]}
                        onChange={(e) => {
                          handleChange(e, item.name)
                        }}
                        name={item.name}
                      />
                    }
                    label={
                      <Typography variant="body1">{item.label}</Typography>
                    }
                  />
                )
              )
            })}
          </FormGroup>
        </Box>
      </Grid>
    </Box>
  )
}
