import * as React from 'react'
import {Box, Grid, Modal, Typography} from '@material-ui/core'
import AvatarChat from './AvatarChat'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import useStyles from './HeaderChat.style';
import {REQEST_DRAWER, SET_OPEN_DRAWER} from '../../../constants/ActionTypes'
import {useContext, useEffect, useState} from 'react'
import DraverContext from '../../../contexts/DraverContext'
import {useHistory} from 'react-router-dom'
import {SocketContext} from "../../../contexts/Socket";
import {CharCounterChat} from "../CharCounterChat/CharCounterChat";
export const HeaderChat = (props) => {
    const socket = useContext(SocketContext);
    const classes = useStyles();
    const {state, dispatch} = useContext(DraverContext);
    const history = useHistory();
    let key = props.userInfo.statusUser;
    let border = ''
    const showProfileHandler = () => {
        dispatch({
            type: SET_OPEN_DRAWER,
            payload: {
                drawer: REQEST_DRAWER,
                open: true,
                showBtnRequestFriend: true,
                data: {
                    userInfo:props.userInfo,
                    showBtn: true,
                    friend: props.userInfo.tel_id || props.userInfo.insta_id ? true : false
                }
            }
        })
    }

    const backHome = () => {
        props.socket.current.disconnect();
        // socket.disconnect();
        history.push('/chatlist');
    }

    let statusText = ''
    switch (key) {
        case 0:
            statusText = ''
            break
        case 1:
            statusText = 'پرمیوم'
            break
        case 2:
            statusText = 'لاکچری'
            break
        case 3:
            statusText = 'سوپرلاکچری'
            break
        case 4:
            statusText = 'الماس'
            break
        default:
            break
    }

    return (
        <React.Fragment>
            <Grid container id='header-chat' className={classes.header} alignItems={"center"} justify="space-between">
                <Grid item onClick={showProfileHandler} style={{height:60}}>
                    <AvatarChat userInfo={props.userInfo} blueTik={props.userInfo.blueTik}/>
                </Grid>

                <Grid item style={{flexGrow: 2, paddingRight: '15px'}} onClick={showProfileHandler}>
                    <Typography variant={'body1'} className={classes.username}>
                        {props.userInfo.username}
                    </Typography>
                    <Typography variant={'body1'} className={classes.status}>
                        {statusText}
                    </Typography>
                </Grid>

                <Grid item>
                    <ArrowBackIosIcon className={classes.ArrowBackIosIcon} onClick={backHome}/>
                </Grid>
                {/*charCounter*/}
                <CharCounterChat totalChar={props.totalChar} />
            </Grid>

        </React.Fragment>
    )
}