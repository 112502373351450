import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    width: '75px',

    marginLeft: 10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatar: {
    width: '75px',
    height: '75px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'relative'
  },
  avatarImg: {
    width: '60px',
    height: '60px'
  },
  textDiv: {
    maxWidth: 70,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    lineHeight: '120%',
    marginTop: '5px !important',
    overflow: 'hidden'
  },
  avatUserName: {
    fontSize: '1rem',
    fontWeight: '600',
    marginBottom: -7,
    width:'inherit',
    textAlign:'center',
    overflow:'hidden',
    textOverflow: 'ellipsis',
    display:'block',
    whiteSpace: 'nowrap',
  },
  border: {
    position: 'absolute',
    zIndex: 1
  },
  blueTik: {
    width: 15,
    position: 'absolute',
    zIndex: 3,
    bottom: -2
  }
}))
