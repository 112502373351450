import { withStyles, Checkbox } from '@material-ui/core'



export default withStyles((theme) => ({
  root: {
    padding: (props) => props.p,
    '& svg': {
      color: (props) =>
        props.checked
          ? props.color || theme.palette.text.primary
          : theme.palette.background.lightDark,
      width: (props) => props.size,
      height: (props) => props.size
    }
    // "& svg.": {
    //   color: (props) => props.color,
    //   width: (props) => props.size,
    //   height: (props) => props.size
    // }
  }
}))(Checkbox)
