import React, { useState, useContext } from 'react'
import { Box, Drawer, Grid, Typography } from '@material-ui/core'
import BtnModal from '../../../BtnModal/BtnModal'
import DiscriptionBox from './DiscriptionBox/DiscriptionBox'
import goldLine from '../../../../assets/icons/goldLine.svg'
import InfinitIcon from '../../../Icons/InfinitIcon'
import useStyles from './BuyDrawer.style'
import { SET_OPEN_DRAWER, PAY_DRAWER } from '../../../../constants/ActionTypes'
import DraverContext from '../../../../contexts/DraverContext'

export default function BuyDrawer(props) {
  const initialSelecte = {
    superLuxury: false,
    luxury: false,
    normal: false
  }
  const [type, setType] = useState('')
  const [selected, setSelected] = useState(initialSelecte)
  const [disable, setDisable] = useState(true)
  const { dispatch } = useContext(DraverContext)

  let perpStyle = { selected }
  const classes = useStyles(perpStyle)

  const onClickHandler = (e) => {
    let key = e.target.getAttribute('id')
    setType(key)
    switch (key) {
      case 'superLuxury':
        setSelected({ ...initialSelecte, superLuxury: true })
        break
      case 'luxury':
        setSelected({ ...initialSelecte, luxury: true })
        break
      case 'normal':
        setSelected({ ...initialSelecte, normal: true })
        break
    }
    setDisable(false)
  }
  const close = () => {
    props.onClose()
    setSelected(initialSelecte)
    setType('')
  }
  const onClickBtn = () => {
    var typeforpayment = 0
    if (type === 'normal') {
      typeforpayment = 1
    } else if (type === 'luxury') {
      typeforpayment = 2
    } else if (type === 'superLuxury') {
      typeforpayment = 3
    }
    dispatch({ type: SET_OPEN_DRAWER, payload: { open: false } })
    setTimeout(() => {
      dispatch({
        type: SET_OPEN_DRAWER,
        payload: {
          drawer: PAY_DRAWER,
          type: type === 'normal' ? '' : 'vip',
          open: true,
          data: { buyStatus: typeforpayment }
        }
      })
    }, 200)
  }
  return (
    <React.Fragment>
      <Grid container item className={classes.discriptionContainer}>
        <DiscriptionBox type={type} />
      </Grid>
      <Grid
        container
        item
        className={classes.itemContainer}
        onClick={(e) => {
          onClickHandler(e)
        }}
      >
        <Grid
          container
          item
          className={`${classes.itemBuy} ${
            selected.superLuxury ? classes.select : ''
          }`}
        >
          <Box className={classes.cover} id="superLuxury" />
          <Box className={classes.itemBuyTop}>
            <InfinitIcon size={30} />
            <Typography color="secondary" variant="subtitle2">
              درخواست دوستی
            </Typography>
            <InfinitIcon size={30} />
            <Typography color="secondary" variant="subtitle2">
              چت نامحدود
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              در روز
            </Typography>
          </Box>
          <Box className={classes.itemBuyBottom}>
            <Box
              className={`${classes.itemBuyLogo} ${classes.superLuxuryLogo}`}
            >
              <Typography variant="caption">سوپرلاکچری</Typography>
            </Box>
            <Typography color="secondary" variant="subtitle1">
              ماهانه
            </Typography>
            <Typography color="secondary" variant="h1" className={classes.cost}>
              ۹۹۹
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              هزار تومان
            </Typography>
          </Box>
        </Grid>

        <Grid
          container
          item
          className={`${classes.itemBuy} ${
            selected.luxury ? classes.select : ''
          }`}
        >
          <Box className={classes.cover} id="luxury" />
          <Box className={classes.itemBuyTop}>
            <Typography color="secondary" variant="subtitle3">
              ۲۰۰
            </Typography>
            <Typography color="secondary" variant="subtitle2">
              درخواست دوستی
            </Typography>
            <InfinitIcon size={30} />
            <Typography color="secondary" variant="subtitle2">
              چت نامحدود
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              در روز
            </Typography>
          </Box>
          <Box className={classes.itemBuyBottom}>
            <Box className={`${classes.itemBuyLogo} ${classes.luxuryLogo}`}>
              <Typography variant="caption">لاکچری</Typography>
            </Box>
            <Typography color="secondary" variant="subtitle1">
              ماهانه
            </Typography>
            <Typography color="secondary" variant="h1" className={classes.cost}>
              ۱۹۹
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              هزار تومان
            </Typography>
          </Box>
        </Grid>

        <Grid
          container
          item
          className={`${classes.itemBuy} ${
            selected.normal ? classes.select : ''
          }`}
        >
          <Box className={classes.cover} id="normal" />
          <Box className={classes.itemBuyTop}>
            <Typography color="secondary" variant="subtitle3">
              ۱۰
            </Typography>
            <Typography color="secondary" variant="subtitle2">
              درخواست دوستی
            </Typography>
            <Typography color="secondary" variant="subtitle3">
              ۲۰
            </Typography>
            <Typography color="secondary" variant="subtitle2">
              درخواست چت
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              در روز
            </Typography>
          </Box>
          <Box className={classes.itemBuyBottom}>
            {/* <Box className={`${classes.itemBuyLogo} ${classes.NormalLogo}`} /> */}
            <Box className={`${classes.itemBuyLogo} ${classes.NormalLogo}`}>
              <Typography variant="caption">پریمیوم</Typography>
            </Box>
            {/* <Box className={classes.goldLineBox}>
              <Box
                component="img"
                src={goldLine}
                className={classes.goldLine}
              />
            </Box> */}
            {/* <Box className={classes.empty} /> */}
            {props.addUser ? (
              <div
                // className={classes.perimumPayment}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  flexGrow: 1
                  // paddingTop: '0.3rem'
                }}
              >
                <Typography
                  style={{ fontSize: '0.77rem' }}
                  color="secondary"
                  variant="subtitle2"
                >
                  اضافه کردن ۳ کاربر
                </Typography>
                <Typography color="secondary" variant="subtitle1">
                  یا
                </Typography>
                <Typography
                  style={{ fontSize: '0.77rem' }}
                  color="secondary"
                  variant="subtitle2"
                >
                  ماهانه ۱۰ هزار تومان
                </Typography>
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Typography color="secondary" variant="subtitle1">
                  ماهانه
                </Typography>
                <Typography
                  color="secondary"
                  variant="h1"
                  className={classes.cost}
                >
                  ۱۰
                </Typography>
                <Typography color="secondary" variant="subtitle1">
                  هزار تومان
                </Typography>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <BtnModal
        color="primary"
        variant="contained"
        disabled={disable}
        onClick={onClickBtn}
      >
        ادامه
      </BtnModal>
    </React.Fragment>
  )
}
