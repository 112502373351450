const { makeStyles } = require("@material-ui/core");

const useStyle = makeStyles(theme => ({
    root: {
        background:'#fff',
        minHeight:'100vh'
        ,margin:'0 auto',
       padding:'45px 35px 80px',
       display:'flex',
       flexDirection:'column',
       justifyContent:'space-between',
       position:'relative'
    }
}))
export default useStyle