import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    position: 'relative',
    paddingBottom: 100,

  },
  Semicircular: {
    position: 'absolute',
    width: '100%',
    height: 210
  },
  userName: {
    fontSize: 27,
    fontWeight: 'bold',
    color: '#FFFFE0',
    zIndex: 1,
    direction:'rtl'
  },
  contentDiv: {
    marginTop: 80,
    flexDirection: 'column',
    alignItems: 'center'
  },
  profileImg: {
    width: 200,
    height: 200,
    borderRadius: '50%'
  },
  textDiv: {
    marginTop: 20,
    marginBottom: 20,
    height: 85,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& *': {
      fontWeight: 'bold'
    }
  },
  userInventDiv: {
    height: 45,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  items: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15
  },
  iconBox: {
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))
