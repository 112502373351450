import { makeStyles } from '@material-ui/core'
 console.log('window.width  : ', window.screen.width )
export default makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    padding: window.screen.width > 330 ? 34 : 10,
    flexDirection: 'column'
  },

  header: {
    position: 'relative'
  },
  headerText: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 50,
    fontWeight: 'bold',
    marginTop: 50
  },
  avatarBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  avatar: {
    width: 217,
    height: 217,
    display: 'flex',

    marginTop: 20,
    marginBottom: 5
  },
  backdroup: {
    marginTop: 10,
    marginBottom: 10,
    padding: '10px 15px',
    background: '#75757533',
    borderRadius: 10,
    marginBottom: 50,
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column'
  },
  btnBox: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 50,
    height: 130
  },
  btnRecord: {
    color: theme.palette.text.primary,
    height: 50,
    borderRadius: 30
  },
  btnExit: {
    color: theme.palette.text.secondary,
    height: 50,
    borderRadius: 30
    // marginTop: 30
  },
  modalContent: {
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h5': {
      color: '#fff',
      marginTop: 10
    }
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
