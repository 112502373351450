import React, { useState, useContext } from 'react'
import { Box, Grid } from '@material-ui/core'

import Menu from '../../components/Menu/Menu'
import HeaderHome from '../../components/HeaderHome/HeaderHome'
import HomeContent from '../../components/HomeContent/HomeContent'
import AvatarList from '../../components/AvatarList/AvatarList'
import IntroTour from '../../components/IntroTour/IntroTour'
import useStyles from './TourScreen.style'
import { location, locationsave } from '../../helpers/fetch'
import { NotificationManager } from 'react-notifications'

export default function HomeScreen(props) {
  const classes = useStyles()

  const [stepsEnabled, setStepsEnabled] = useState(true)
  const [state, setState] = useState([])
  const [link, setLink] = useState({})

  const onExit = () => {
    setStepsEnabled( false )
    props.history.push('/home')
  }
  return (
    <Grid container direction="column" className={classes.root}>
      <HeaderHome />
      <AvatarList state={state} />

      <IntroTour
        enabled={stepsEnabled}
        onExit={onExit}
      />

      <Menu activeIcon="home" position="absolute" />
    </Grid>
  )
}
