import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  rootBtn: {
    display: 'flex',
    width: 'inherit',
    height:45,
    overflow:'hidden'
  },
  btn: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffe0',
    background:'#FED33C',
  },
  ml: {
    marginRight: 5
  },
  add:{
    background:'white',
    textAlign:'center'
  },
  addTxt:{
    color: 'black',
    fontSize: '11px',
    cursor:'pointer'
  },
  store:{
    background:'#FED33C',
  },
  storeContainer:{
    wordBreak: 'break-word',
    alignSelf: 'center',
    overflow:'hidden',
  },
  storeTxt:{
    color: 'black',
    fontSize: '11px',
    marginRight: '20px',
    marginTop: '3px',
    cursor:'pointer'
  },
  LocalMallIcon:{
    color: 'black',
    marginTop: '8px'
  },
}));