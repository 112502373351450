import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import Password from './Password'

export default function indexPass(props) {
    return (
        <RigesterContextProvider>
            <Password history={props.history}/>
        </RigesterContextProvider>
    )
}
