const { makeStyles } = require('@material-ui/core')

const useStyle = makeStyles((theme) => ({
  h1: {
    margin: '18px 10px',
    fontSize: 28,
    fontWeight: 'lighter'
  },
  input: {
    width: '100%',
    '& label': {
      color: 'black'
    }
  },
  inputResetPass: {
    width: '100%',
    '& label': {
      color: theme.palette.background.lightDark
    }
  },
  inputBox: {
    display: 'flex',
    textAlign: 'center',

    flexDirection: 'column'
  },
  divinput: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    flexDirection: 'row',
    '& .-top': {
      top: -200
    }
  },
  fonts: {
    textAlign: 'left',
    color: 'green'
  },
  h5: {
    fontSize: 16,
    color: 'green'
  },
  textHelper: {
    color: 'green',
    textAlign: 'initial'
  },
  btncontinue: {
    width: '100%',
    textAlign: 'center'
  },
  buttons: {
    borderRadius: '50px',
    width: '70%',
    marginTop:15

  },
  btnSkip: {
    marginTop: 5
  },
  ulBox: {
    display: 'flex',
    overflow: 'auto',
    height: 'calc(100vh - 360px)',

    '& ul': {
      padding: 0,
      margin: '0 0 0 20px;'
    }
  },
  ruleBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  checkBoxDiv: {
    display: 'flex',
    marginTop: 10
  },
  checkBox: {
    padding: 0,

    '& svg': {
      color: '#FED33C',
      width: 30,
      height: 30,
      position: 'relative',
      left: '-7px',
      top: '-6px'
    }
  },
  inputCode: {
    direction: 'rtl',
    '&>input': {
      '&:focus': {
        outline: 'none !important'
      }
    }
  },
  modalContent: {
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h5': {
      color: '#fff',
      marginTop: 10
    }
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
export default useStyle
