import React from 'react'
import { CssBaseline } from '@material-ui/core'
import RTL from './components/RTL/index'
import ThemeProvaider from './components/ThemeProvaider/index'
import IndexScreen from './screens/index'
import { SocketContext, socket } from './contexts/Socket'
function App() {
  return (
    <SocketContext.Provider value={socket}>
      <ThemeProvaider>
        <CssBaseline />
        <RTL>
          <IndexScreen />
        </RTL>
      </ThemeProvaider>
    </SocketContext.Provider>
  )
}

export default App
