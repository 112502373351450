import React, { Children } from 'react'
import { Box } from '@material-ui/core'
import useStyles from './TitleBox.style'

export default function TitleBox({
  width,
  height,
  bgcolor,
  borderRadius,
  fontSize,
  color,
  children
}) {
let ps = { width, height, bgcolor, borderRadius, fontSize, color }
const classes=useStyles(ps)

  return <Box className={classes.root}>{children}</Box>
}
