import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {},
  tooltip: {
    borderRadius: 20,
    marginRight: 15,
    marginLeft: 15,
    fontSize: 15,
    background: '#ffffff00',
    boxShadow: 'none',
    color: theme.palette.primary.main,
    '& a': {
      background: '#ffffff00',
      color: theme.palette.primary.main,
      border: 'none',
      textShadow: 'none',
      backgroundImage: 'none'
    },
    '& a:focus': {
      backgroundImage: 'none'
    },
    '& a:hover': {
     boxShadow: 'none'
    },
    '& li>a.active': {
      background: `${theme.palette.primary.main}!important`
    },
    '& div.introjs-arrow': {
      display: 'none!important'
    },
    '& *': {
      fontFamily: 'iranyekan',
      borderRadius: 20
    }
  },
  highlight: {
    borderRadius: 20,
   
  }
}))
