import React, { useEffect, useState } from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import useStyles from './RobotCodeContainer.style'
import { informationUser } from '../../helpers/fetch'

export default function RobotCodeContainer(props) {
  const c = useStyles()
  const history = useHistory()
  const [state,setState]=useState('')
  useEffect(()=>{
    informationUser().then(response=>{
      setState(response.user.bot_app)
    })
  },[])
  const back = () => {
    history.push('home')
  }
  const copyCode = (e) => {
    console.log(e.target.innerHTML)
    let copyText = document.getElementById('InputCode')
    let alert = document.getElementById('alertBox')
    console.log('copyText : ', copyText)
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')

    alert.style.display = 'block'

    setTimeout(() => {
      alert.style.opacity = 1
    }, 0)
    setTimeout(() => {
      alert.style.opacity = 0
    }, 1000)
    setTimeout(() => {
      alert.style.display = 'none'
    }, 1500)
  }
  return (
    <div className={`${c.felxCol} ${c.root}`}>
      <Typography variant="h1" className={`${c.textTop}`}>
        برای پیدا کردن کاربر تصادفی وارد رابات تلگرام آی لوک شوید. برا ورود کد
        فعالسازی زیر را لمس کنید.
      </Typography>
      {/* <Typography variant="h1" className={`${c.lastLineTExt}`}>
        را لمس کنید.
      </Typography> */}

      <div className={`${c.felxCol} ${c.centerDiv}`}>
        <h1 className={c.textActive}> کد فعالسازی ربات</h1>
        <input
          className={c.inputActive}
          type="text"
          id="InputCode"
          value={state}
          readonly
          onClick={copyCode}
        />
        <div className={c.alertBox} id="alertBox">
          کد کپی شد
        </div>
      </div>
      <div className={`${c.felxCol} ${c.bottomDiv}`}>
        <div className={`${c.felxCol} ${c.textBottomDiv}`}>
          <Typography variant="h5">
            برای ورد به ربات لینک زیر را لمس کنید.
          </Typography>
          <a href="https://t.me/iloc_bot" className={c.telegramLink}>
            <Typography variant="h1">T.me/iLoc_Bot</Typography>
          </a>
          <Typography variant="h5">
            این ربات کاملاً و به طور دائمی رایگان است
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          className={c.btnBack}
          onClick={back}
        >
          باز گشت به صفحه خانه
        </Button>
      </div>
    </div>
  )
}
