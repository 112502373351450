import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import Avatar from '../Avatar/Avatar'
import BtnsOfCart from './BtnsOfCart/BtnsOfCart'
import StatusOfCart from './StatusOfCart/StatusOfCart'
import TextOfCart from './TextOfCart/TextOfCart'
import useStyles from './Cart.style'
import {
  SEND,
  RECEIVED,
  SET_OPEN_DRAWER,
  SET_TRIGGER,
  REQEST_DRAWER
} from '../../constants/ActionTypes'
import { accept, reject, readMessage } from '../../helpers/fetch'
import DraverContext from '../../contexts/DraverContext'
import { MsgScreenContext } from '../../contexts/MsgScreenContext'

export default function Cart({ type, info }) {
  const { dispatch: dispatchModal } = useContext(DraverContext)
  const { state, dispatch } = useContext(MsgScreenContext)
  let read = true
  const classes = useStyles({ type })
  const acceptReq = () => {
    accept(info.message_id)
    readMessage(info.message_id)
    dispatch({
      type: SET_TRIGGER,
      payload: !state.trigger
    })
  }
  const rejectReq = () => {
    reject(info.message_id)
    readMessage(info.message_id)
    dispatch({
      type: SET_TRIGGER,
      payload: !state.trigger
    })
  }

  let option
  switch (type) {
    case SEND:
      option = (
        <Box className={classes.option}>
          <StatusOfCart status={info.status_request} />
        </Box>
      )
      break
    case RECEIVED:
      read = info.read
      switch (info.status_request) {
        //2==accept , 1==waiting , 0 ==reject
        case 2:
          option = (
            <Box className={classes.option}>
              <StatusOfCart status={info.status_request} />
            </Box>
          )
          break
        case 1:
          option = (
            <Box className={classes.option}>
              <BtnsOfCart
                acceptReq={acceptReq}
                rejectReq={rejectReq}
                userId={info.id}
              />
            </Box>
          )
          break
        case 0:
          option = (
            <Box className={classes.option}>
              <StatusOfCart status={info.status_request} />
            </Box>
          )
          break

        default:
          option = null
          break
      }

      break
    default:
      option = ''
      break
  }
  const showmodal = () => {
    dispatchModal({
      type: SET_OPEN_DRAWER,
      payload: {
        open: true,
        drawer: REQEST_DRAWER,
        data: {
          friend: info.tel_id || info.insta_id ? true : false,
          userInfo: info,
          blueTik: info.score
        }
      }
    })
    dispatch({
      type: SET_TRIGGER,
      payload: !state.trigger
    })
    // console.log(info.message_id)
    readMessage(info.message_id)
  }

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.avatarAndText} onClick={showmodal}>
          <Box className={classes.avatar}>
            <Avatar
              text={false}
              userInfo={info}
              blueTik={info.score}
              src={info.image ? 'https://iloc.myiloc.com' + info.image : ''}
            />
          </Box>
          <Box className={classes.text}>
            <TextOfCart
              title={info.username}
              subtitle={info.message}
              read={read}
              // userStatus={info.status_request}
            />
          </Box>
        </div>

        {option}
      </Box>
    </>
  )
}

//  ;<TextOfCart
//    title={
//      type === 'SEND' || !type
//        ? info.username
//        : info.name + ' ' + info.lastname
//    }
//    subtitle={
//      type === 'SEND' || !type ? info.name + ' ' + info.lastname : info.message
//    }
//  />
