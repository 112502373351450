import React, { useContext } from 'react'
import Back from '../../../components/Back/Back'
import Desktopdiv from '../../../components/DesktopDiv/Desktopdiv'
import Typographys from '../../../components/Typography/Typography'
import { RigesterContext } from '../../../contexts/RigesterContext'
import useStyle from '../rigester.style'
import GoldButton from '../../../components/Button/GoldButton'
import { SETINPUTVALUE } from '../../../constants/ActionTypes'
import UserAvatar from '../../../components/ImageAvatar.js/userAvatar'
import IlocIcon from '../../../assets/images/Group 572.png'
import { Grid, Typography } from '@material-ui/core'
import Stepper from '../../../components/Stepper/Stepper'
export default function Image(props) {
  const [img, setimg] = React.useState('')
  const [file, setfile] = React.useState(null)
  const { state, dispatch } = useContext(RigesterContext)
  const classes = useStyle()
  const Backs = () => {
    props.history.push({
      pathname: '/rigester/Password',
      state: {
        phone: props.history.location.state.phone,
        code: props.history.location.state.code,
        lastname: props.history.location.state.lastname,
        name: props.history.location.state.name,
        username: props.history.location.state.username,
        birthday: props.history.location.state.birthday,
        gender: props.history.location.state.gender,
        tel_id: props.history.location.state.tel_id,
        insta_id: props.history.location.state.insta_id,
        password: props.history.location.state.password
      }
    })
  }
  const continues = () => {
    props.history.push({
      pathname: '/rigester/Loc',
      state: {
        phone: props.history.location.state.phone,
        code: props.history.location.state.code,
        lastname: props.history.location.state.lastname,
        name: props.history.location.state.name,
        username: props.history.location.state.username,
        birthday: props.history.location.state.birthday,
        gender: props.history.location.state.gender,
        tel_id: props.history.location.state.tel_id,
        insta_id: props.history.location.state.insta_id,
        password: props.history.location.state.password,
        password_confirmation:props.history.location.state.password_confirmation,
        image: img,
      }
    })
  }
  const continues1=()=>{
    props.history.push({
      pathname: '/rigester/Loc',
      state: {
        phone: props.history.location.state.phone,
        code: props.history.location.state.code,
        lastname: props.history.location.state.lastname,
        name: props.history.location.state.name,
        username: props.history.location.state.username,
        birthday: props.history.location.state.birthday,
        gender: props.history.location.state.gender,
        tel_id: props.history.location.state.tel_id,
        insta_id: props.history.location.state.insta_id,
        password: props.history.location.state.password,
        password_confirmation:props.history.location.state.password_confirmation,
      }
    })
  }
  const onChangeImg = (e) => {
    setimg(e.target.files[0])
    setfile(URL.createObjectURL(e.target.files[0]))
  }
  React.useEffect(() => {
    if (props.history.location.state === undefined) {
      props.history.push('/')
    }
  }, [])
 
  return (
    <Desktopdiv>
      <Stepper complate={'90'} />

      <div>
        <Back onClick={() => Backs()} color="#FED33C" />
        <Typographys
          component="h1"
          text="عکس خود را وارد کنید"
          className={classes.h1}
        />
      </div>
      <div className={classes.inputBox}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <UserAvatar src={file} onChange={(e) => onChangeImg(e)} />
        </div>
      </div>
      <div className={classes.btncontinue}>
        <GoldButton
          onClick={() => continues()}
          disabled={img === '' ? true : false}
          variant={img === '' ? 'outlined' : 'contained'}
          className={classes.buttons}
          text="ادامه دهید"
        />

        <Typography
          className={classes.btnSkip}
          onClick={continues1}
          variant="subtitle1"
        >
          رد شدن از این مرحله
        </Typography>
      </div>
    </Desktopdiv>
  )
}

