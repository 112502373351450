import React from 'react'
import { Box } from '@material-ui/core'
import FavoriteListContainer from '../../container/FavoriteListContainer/FavoriteListContainer'
import {ModalContextProvider} from '../../contexts/ModalContext'

export default function FavoriteListScreen(props) {
  return (
    <ModalContextProvider>
      <FavoriteListContainer />
    </ModalContextProvider>
  )
}
