import React, {useContext, useEffect, useRef, useState} from 'react'
import { Grid, Box } from '@material-ui/core'
import SearchBox from '../../components/SearchBox/SearchBox'
import SearchList from '../../components/SearchList/SearchList'
import Menu from '../../components/Menu/Menu'
import { search } from '../../helpers/fetch'
import {SocketContext} from "../../contexts/Socket";

export default function SearchScreen() {
  const [sreachList, setSreachList] = useState([])
  const socket = useRef(useContext(SocketContext));
  const [txt, settxt] = useState('')
  const [nextpage, setnextpage] = useState(2)
  useEffect(() => {
    if (socket.current.connected){
      socket.current.disconnect();
    }
  })
  const changeList = (list) => {
    if (list !== '') {
      settxt(list)
      search(list).then((response) => {
        if (response.$data !== 'نام کاربری موردنظر یافت نشد') {
          if (response.data) {
            setSreachList(response.data)
          } else {
            setSreachList([
              { image: '', username: response.data[0].username, city: null }
            ])
          }
        } else {
          setSreachList([])
        }
      })
    }
  }
  const fetchMoreData = () => {
    search(txt, nextpage).then((response) => {
      setSreachList(sreachList.concat(response.data))
      if (response.links.next !== null) {
        setnextpage(parseInt(response.links.next.split('=')[1]))
      } else {
        setnextpage(null)
      }
    })
  }

  return (
    <Box height="100vh" clone>
      <Grid container item direction="column">
        <Box bgcolor="background.main" style={{ zIndex: 1 }} width="100%">
          <SearchBox value="" onChange={changeList} />
        </Box>

        <SearchList
          list={sreachList}
          nextpage={nextpage}
          fetchMoreData={fetchMoreData}
        />
        <Menu activeIcon="search" />
      </Grid>
    </Box>
  )
}
