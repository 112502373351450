import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100% - 168px)',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    paddingBottom: '60px',
    overflow: 'auto',
    overflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
}))
