import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  felxCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  root: {
    width: '100%',
    minHeight: '100vh',
    padding: 40,
    paddingBottom: 70
  },
  textTop: {
    textAlign: 'center',
    lineHeight: '35px'
  },
  centerDiv: {
    position: 'relative',
    height: 90
  },
  textActive: {
    fontSize: 26
  },
  inputActive: {
    width: 200,
    border: 'none',
    display: 'flex',
    padding: '10px 20px',
    fontSize: 22,
    background: theme.palette.primary.main,
    textAlign: 'center',
    letterSpacing: 5,
    borderRadius: 5,
    outline: 'none',
    color: 'transparent',
    textShadow: `0 0 0 ${theme.palette.codeInput}`
  },
  alertBox: {
    color: '#fff',
    bottom: '-80px',
    padding: '10px 15px',
    position: 'absolute',
    background: 'gray',
    transition: '.5s',
    borderRadius: 5,
    fontSize: 15,
    display: 'none',
    opacity: 0
  },

  bottomDiv: {
    height: 180
  },
  textBottomDiv: {
    height: 100
  },
  telegramLink: {
    textDecoration: 'none'
  },

  btnBack: {
    color: '#ffffe0',
    height: 50,
    borderRadius: 30
  },
  inputCode: {
    display: 'none'
  }
}))
