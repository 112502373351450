import React from 'react'
import RigesterContextProvider from '../../../contexts/RigesterContext'
import Phone from './Phone';

export default function index(props) {

    return (
        <RigesterContextProvider>
            <Phone history={props.history}/>
        </RigesterContextProvider>
    )
}
