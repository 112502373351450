import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    [theme.breakpoints.up('sm')]: {
      '&> div': {
        left: 'calc(50vw - 300px)'
      }
    },

    '& *': {
      maxWidth: 600
    }
  },
  paper: {
    borderRadius: 20,
    background: theme.palette.background.main
  }
}))
