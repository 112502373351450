import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import uniqid from 'uniqid'
import Avatar from '../Avatar/Avatar'
import useStyles from './avatarList.style'
import plusIcon from '../../assets/icons/plus.svg'
import { informationUser } from '../../helpers/fetch'

export default function
    AvatarList(props) {
  const classes = useStyles()
  React.useEffect(() => {
    informationUser().then((response) => {
      setState(response.user)
      localStorage.setItem('phone', response.user.phone)
      localStorage.setItem('username', response.user.username)
      localStorage.setItem('image', response.user.image)
      localStorage.setItem('name', response.user.name)
      localStorage.setItem('lastname', response.user.lastname)
      localStorage.setItem('count', response.count)
      localStorage.setItem('status_user', response.user.status_user)
      localStorage.setItem('show', response.user.show)
      localStorage.setItem('see', response.user.see)
      localStorage.setItem('id', response.user.id)
      localStorage.setItem('sex', response.user.sex)

    })
  }, [])

  const [state, setState] = useState({})
  return (
    <Box className={classes.container}>
      <Avatar
        userInfo={{
          statusUser: parseInt(localStorage.getItem('status_user')),
          username: state.username,
          image: state.image,
          user: 1,
          bio: state.bio,
            sex:state.sex
        }}
        blueTik={state.score}
      />

      <Box className={classes.root}>
        {props.state.map((item) => {
          return (
            <Avatar
              key={uniqid(item.username)}
              userInfo={{
                statusUser: parseInt(item.status_user),
                username: item.username,
                image: item.image,
                km: item.distance,
                id: item.id,
                bio: item.bio,
                fav: item.fav,
                insta_id: item.insta_id,
                tel_id: item.tel_id,
                sex: item.sex,
                blueTik:parseInt(item.score),
              }}
              blueTik={parseInt(item.score)}
            />
          )
        })}

        <img src={plusIcon} onClick={props.next} className={classes.addIcon} />
      </Box>
    </Box>
  )
}

// import React, { useState } from 'react'
// import { Box } from '@material-ui/core'
// import uniqid from 'uniqid'
// import ScrollMenu from 'react-horizontal-scrolling-menu'
// import Avatar from '../Avatar/Avatar'
// import useStyles from './avatarList.style'
// import { Add } from '@material-ui/icons'
// import { informationUser } from '../../helpers/fetch'

// export default function AvatarList(props) {
//   const classes = useStyles()
//   React.useEffect(() => {
//     informationUser().then((response) => {
//       setState(response.user)
//     })
//   }, [])

//   const [ state, setState ] = useState( {} )

//   return (
//     <Box className={classes.container}>
//       <Avatar
//         userInfo={{
//           statusUser: parseInt(localStorage.getItem('status_user')),
//           username: state.username,
//           image: state.image,
//           user: 1,
//           bio: state.bio
//         }}
//       />
//       <div className={classes.root}>
//         <ScrollMenu
//           data= {props.state.map((item) => {
//           return (
//             <Avatar
//               userInfo={{
//                 statusUser: parseInt(item.status_user),
//                 username: item.username,
//                 image: item.image,
//                 km: parseInt(item.distance),
//                 id: item.id,
//                 bio: item.bio
//               }}
//             />
//           )
//         })}
//           innerWrapperClass={classes.scrollWrapper}
//          translate='0px'
//         />
//       </div>
//     </Box>
//   )
// }
