export const SETINPUTVALUE = 'SETINPUTVALUE'
export const SETINPUTERROR = 'SETINPUTERROR'
export const SET_SEND_MSGLIST = 'SET_SEND_MSGLIST'
export const SET_RECEIVED_MSGLIST = 'SET_RECEIVED_MSGLIST'
export const SET_BOXNAME = 'SET_BOXNAME'
export const SET_OPENMODAL = 'SET_OPENMODAL'
export const SET_FILTERSTATUS = 'SET_FILTERSTATUS'
export const SET_FILTERLIST = 'SET_FILTERLIST'
export const SET_SORTLIST = 'SET_SORTLIST'
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'
export const SET_OPEN_DRAWER = 'SET_OPEN_DRAWER'
export const SET_TRIGGER = 'SET_TRIGGER'
export const SEND = 'SEND'
export const RECEIVED = 'RECEIVED'
export const SEARCH = 'SEARCH'
export const CHANGE_PROFILE_PHOTO_DRAWER = 'CHANGE_PROFILE_PHOTO_DRAWER'
export const MESSAGE_DRAWER = 'MESSAGE_DRAWER'
export const PAY_DRAWER = 'PAY_DRAWER'
export const REQEST_DRAWER = 'REQEST_DRAWER'
export const BUY_DRAWER = 'BUY_DRAWER'
export const ALERT_END_DRAWER = 'ALERT_END_DRAWER'
export const KNOW_MORE_DRAWER = 'KNOW_MORE_DRAWER'
