import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import Avatar from '../Avatar/Avatar'
import useStyles from './ChatCart.style'
import TextOfCart from '../Cart/TextOfCart/TextOfCart'

const ChatCart = (userInfo) => {
  const classes = useStyles()
  // const [isShowUnSeenNumbers, setIsShowUnSeenNumbers] = useState(false)
  const history = useHistory()
  const { id, username, unseennumbers, image, lastmessage, blueTik } = userInfo
  // console.log(userInfo)
  const goToChatPage = () => {
    history.push({ pathname: '/chat', state: { userInfo } })
  }

  // if (unseennumbers) {
  //   setIsShowUnSeenNumbers(true)
  // }

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.avatarAndText}>
          <Box className={classes.avatar}>
            <Box width="75px" height="75px" clone>
              <Avatar
                userInfo={{ ...userInfo }}
                text={false}
                blueTik={blueTik}
              />
            </Box>
          </Box>
          <Box className={classes.text} onClick={goToChatPage}>
            <TextOfCart
              title={username}
              subtitle={lastmessage}
              unseennumbers={unseennumbers}
            />
          </Box>
        </div>

        {unseennumbers ? (
          <span className={classes.unseenNumNotif}>{unseennumbers}</span>
        ) : (
          ''
        )}
      </Box>
    </>
  )
}

export default ChatCart
