import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import GoldButton from '../Button/GoldButton'
const Back = ({ onClick, color }) => (
  <GoldButton
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      color: color
    }}
    variant="text"
    onClick={onClick}
    text={<ArrowForwardIosIcon />}
  />
)
export default Back
