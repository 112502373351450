import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Box, makeStyles } from '@material-ui/core'
import ForumIcon from '@material-ui/icons/Forum'
import UnSeenNumberContext from '../../contexts/UnSeenNumberContext'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 43
  },
  logoBox: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  messageNotif: {
    fontSize: 45,
    color: 'gray',
    marginTop: 8,
    marginBottom: 5,
    display: 'inline-block',
    filter: 'drop-shadow(0 3px 2.7rem black)'
  },
  message__box: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  notif__number: {
    position: 'absolute',
    top: 0,
    left: '55%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
    borderRadius: 20,
    backgroundColor: 'lightgray',
    fontSize: 14,
    fontWeight: 'bolder',
    color: 'red',
    border: '3px solid white'
  },
  logoText: {
    marginTop: 10,
    color:
      localStorage.getItem('status_user') === '3' ||
      localStorage.getItem('status_user') === '4'
        ? '#ffffe0'
        : '000',
    fontSize: 31,
    fontFamily: 'fourHand',
    fontWeight: 'bold',
    marginTop: 4
  },
  robotIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit'
  }
}))

export default function HeaderHome({ robatIcon_id }) {
  const c = useStyles()
  const history = useHistory()
  const { unSeenNum } = useContext(UnSeenNumberContext)
  const goToRobotCodePage = () => {
    history.push('/robotCode')
  }
  const goToChatList = () => {
    history.push('/chatList')
  }
  return (
    <Grid container item sx={12} className={c.root}>
      <Grid id="chatIcon" container item xs={2} className={c.message__box}>
        <ForumIcon className={c.messageNotif} onClick={goToChatList} />
        <span className={c.notif__number}>{unSeenNum}</span>
      </Grid>

      <Grid container item xs={8} className={c.logoBox}>
        <span className={c.logoText}>iLoc</span>
      </Grid>

      <Grid
        id="robatIcon"
        className={c.robotIconContainer}
        container
        item
        xs={2}
      >
        <span
          className="material-icons"
          style={{
            fontSize: 45,
            marginTop: 5,
            marginBottom: 5,
            // color: '#00241B',
            color: 'gray',
            display: 'inline-block'
          }}
          onClick={goToRobotCodePage}
        >
          smart_toy
        </span>
      </Grid>
    </Grid>
  )
}
