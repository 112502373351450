import React, { createContext, useReducer } from 'react'
import { SET_OPEN_DRAWER,SET_TRIGGER } from '../constants/ActionTypes'

//**********  createContext  ************
const DrawerContext = createContext({
  state: {},
  dispatch: () => {}
})
export default DrawerContext

//**********  ContextProvider  ************
function DrawerContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    drawer: null,
    type: '',
    data: {},
    trigger:false,
    showBtnRequestFriend:false,
  })

  return (
    <DrawerContext.Provider value={{ state, dispatch }}>
      {children}
    </DrawerContext.Provider>
  )
}
export { DrawerContextProvider }

//**********  reducer  ************
function reducer(state, action) {
  switch (action.type) {
    case SET_OPEN_DRAWER:
      return Object.assign({}, state, { ...action.payload })
    case SET_TRIGGER:
      return Object.assign({}, state, { trigger:action.payload })

    default:
      return Object.assign({}, state)
  }
}