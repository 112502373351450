// @flow
import * as React from 'react';
import {Box, Typography,Modal} from "@material-ui/core";
import useStyles from "./NewModal.style";


export const NewModal = (props) => {
    const classes = useStyles();

    return (
        <Modal open={props.open} onClose={props.closeModalAds} className={classes.modal}>
            <div className={classes.modalContent}>
                <div className={classes.textContent}>
                    <Typography variant="caption">
                        {props.text}
                    </Typography>
                </div>
                <Box className={classes.btnContainer}>
                    <Box onClick={props.closeModal} className={classes.btnModal} >
                        <Typography variant="h5"> {props.btnText}</Typography>
                    </Box>
                </Box>
            </div>
        </Modal>
    );
};