import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PraivetRoute from '../components/PraivetRoute/PraivetRoute'
import Login from '../screens/login/Login'
import CodeSmsContextProvider from '../screens/Rigester/CodeSms/index'
import Phone from '../screens/Rigester/Phone/PhoneContextProvider'
import Start from '../screens/Start/Start'
import userindex from '../screens/Rigester/Username/index'
import birthdayindex from '../screens/Rigester/Birthdaydate'
import userindexgender from '../screens/Rigester/gender'
import indexId from '../screens/Rigester/Id'
import indexPass from '../screens/Rigester/Password'
import indexImg from '../screens/Rigester/Image'
import Loc from '../screens/Rigester/Loc/Loc'
import ChangePasswordScreen from '../screens/ChangePasswordScreen/ChangePasswordScreen'
import ProfileRoute from '../screens/Profile/ProfileScreen'
import ProfileSettingRoute from '../screens/ProfileSetting/ProfileSettingScreen'
import MessageRoute from '../screens/Message/MessageScreen'
import SreachRoute from '../screens/Search/SearchScreen'
import HomeUserRoute from '../screens/Home/HomeScreen'
import TourRoute from '../screens/Tour/TourScreen'
import BlackListRoute from '../screens/BlackListScreen/BlackListScreen'
import FavoriteListRoute from '../screens/FavoriteListScreen/FavoriteListScreen'
import AgentMessagesRoute from '../screens/AgentMessagesScreen/AgentMessagesScreen'
import RobotCodeRoute from '../screens/RobotCodeScreen/RobotCodeScreen'
import InvitationLinkRoute from '../screens/InvitationLinkScreen/InvitationLinkScreen'
import AfterPayScreenRoute from '../screens/AfterPayScreen/AfterPayScreen'

import Name from '../screens/Rigester/name/index'
import ChangePassword from '../screens/ChangePassword/ChangePassword'
import phonereset from '../screens/ChangePassword/phonereset'
import confirmation from '../screens/ChangePassword/confirmation'
// import PvChatRoute from './PvChatRoute'
import { PvChat } from '../screens/PvChat/PvChat'

import ChatList from '../screens/ChatList/ChatList'

export default function index() {
  return (
    <Switch>
      <Route exact path="/" component={Start} />
      <Route path="/reset/phone" component={ChangePassword} />
      <Route path="/reset/confirmation" component={confirmation} />
      <Route path="/rigester/phone" component={Phone} />
      <Route path="/login" component={Login} />
      <Route path="/rigester/CodeSms" component={CodeSmsContextProvider} />
      <Route path="/rigester/username" component={userindex} />
      <Route path="/rigester/Birthdaydate" component={birthdayindex} />
      <Route path="/rigester/gender" component={userindexgender} />
      <Route path="/rigester/Id" component={indexId} />
      <Route path="/rigester/Password" component={indexPass} />
      <Route path="/rigester/Image" component={indexImg} />
      <Route path="/rigester/Loc" component={Loc} />
      <Route path="/rigester/Name" component={Name} />
      <Route path="/reset/Code" component={phonereset} />
      <PraivetRoute path="/profile" exact component={ProfileRoute} />
      <PraivetRoute path="/profile/setting" component={ProfileSettingRoute} />
      <PraivetRoute path="/changePassword" component={ChangePasswordScreen} />
      <PraivetRoute path="/message" component={MessageRoute} />
      <PraivetRoute path="/search" component={SreachRoute} />
      <PraivetRoute path="/search" component={SreachRoute} />
      <PraivetRoute path="/home" component={HomeUserRoute} />
      <PraivetRoute path="/tour" component={TourRoute} />
      <PraivetRoute path="/blackList" component={BlackListRoute} />
      <PraivetRoute path="/favoriteList" component={FavoriteListRoute} />
      <PraivetRoute path="/agentMessages" component={AgentMessagesRoute} />
      <PraivetRoute path="/robotCode" component={RobotCodeRoute} />
      <PraivetRoute path="/invitationLink" component={InvitationLinkRoute} />
      <PraivetRoute path="/afterPay" component={AfterPayScreenRoute} />
      <PraivetRoute path="/chat" component={PvChat} />

      <PraivetRoute path="/chatlist" component={ChatList} />
    </Switch>
  )
}
