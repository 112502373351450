import React, { useState } from 'react'
import {useHistory} from 'react-router-dom'
import { Box, Grid, Typography, Button, TextField } from '@material-ui/core'
import Back from '../../components/Back/Back'
import useStyles from './ChangePasswordContainer.style'
import { changePassword } from '../../helpers/fetch'
import { NotificationManager } from 'react-notifications'

export default function ChangePasswordContainer(props) {
  const classes = useStyles()
    const history = useHistory()
    const [state,setState]=useState({oldpass:'',newpass:'',newpass_confirmation:''})
    const back = () => {
    history.push('/profile/setting')
    }
    const submit=()=>{
      var fd=new FormData()
      fd.append('old_password',state.oldpass)
      fd.append('password',state.newpass)
      fd.append('password_confirmation',state.newpass_confirmation)
      changePassword(fd).then(response=>{
        NotificationManager.success(response.data)
      })
    }
    const onChange=(e)=>{
      setState({...state,[e.target.name]:e.target.value})
    }
  return (
    <Grid className={classes.root}>
      <Box>
        <Back onClick={back} />
        <Typography variant="h1" className={classes.title}>
          تغییر رمز ورودی
        </Typography>
      </Box>

      <Grid className={classes.inputBox}>
        <TextField
          className={classes.input}
          color="primary"
          placeholder="رمز فعلی"
          type="password"
          name="oldpass"
          onChange={onChange}
        />
        <TextField
          className={classes.input}
          color="primary"
          placeholder="رمز جدید"
          type="password"
          onChange={onChange}
          name="newpass"
        />
        <TextField
        name="newpass_confirmation"
          className={classes.input}
          color="primary"
          placeholder="تکرار رمز جدید"
          type="password"
          onChange={onChange}
        />
      </Grid>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        className={classes.btn}
        onClick={submit}
      >
        ثبت
      </Button>
    </Grid>
  )
}
