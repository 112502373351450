import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  root: {
    display: (ps) => (ps.type ? 'flex' : 'none'),
    padding: 20,
    paddingBottom: 0,
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh'
  },
  list: {
    width: '100%',
    height: 'calc(100% - 160px)',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 10,
    overflow: 'auto',
    overflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  btn: {
    maxWidth: 200,
    height: 50,
    borderRadius: 15,
    color: theme.palette.text.button
  },
  textBold: {
    textAlign: 'center',
    marginTop: 20,
  }
}))
